import $ from "jquery";
import React, { Component } from "react";
import {
  Title,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  BooleanInput,
  BooleanField,
  ListContextProvider,
} from "react-admin";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios, { AxiosResponse } from "axios";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ClipLoader from "react-spinners/ClipLoader";
import { makeStyles } from "@material-ui/styles";
import { stringify } from "query-string";
import Select from "react-select";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GroupIcon from "@material-ui/icons/Group";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import {
  VictoryPie,
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryStack,
  VictoryTooltip,
} from "victory";
import {
  DataGrid,
  ColDef,
  ValueFormatterParams,
  RowData,
} from "@material-ui/data-grid";
import { plPL } from "@material-ui/core/locale";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { GroupList } from "../groups/groups";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

const drawerWidth = 240;
const staticStyles = {
  actionIcons: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw",
    cursor: "pointer",
  },
  groupImage: {
    marginTop: "3rem",
    width: "8rem",
    height: "8rem",
    borderRadius: "50%",
  },
  rowGroupImage: {
    width: "4rem",
    height: "4rem",
    borderRadius: "50%",
  },
  groupTitle: {
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal" as "normal",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    alignSelf: "center",
    marginLeft: "2em",
  },
  paper: {
    marginTop: "3vh",
    width: "11rem",
    height: "11rem",
  },
  paperLeftUpper: {
    marginTop: "3vh",
    width: "11rem",
    height: "11rem",
    marginLeft: "5.2rem",
  },
  paperTags: {
    width: "11rem",
    height: "11rem",
  },
  paperLegend: {
    width: "11rem",
    height: "11rem",
    backgroundColor: "red",
  },
  iconUsers: {
    width: "3rem",
    height: "3rem",
    marginTop: "2rem",
    color: "#01C09E",
  },
  item: {
    padding: "10",
    align: "center",
    marginTop: "4rem",
  },
  itemGroups: {
    marginTop: "4.5rem",
    marginBottom: "1rem",
    fontWeight: "bold" as "bold",
    fontSize: "1.2em",
    marginLeft: "1em",
  },
  itemTags: {
    padding: "10",
    align: "center",
    marginTop: "4rem",
    width: "100%",
    marginLeft: "12rem",
  },
  infoBarUser: {
    marginTop: "1.5em",
    marginBottom: "1em",
    fontSize: "1.4em",
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
  },
  secondItem: {
    marginLeft: "1em",
  },
  fullScreen: {
    width: "100vw",
    height: "100vh",
    spacing: "0",
    flex: "1",
    marginTop: "240",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  buttonPrimary: {
    width: "12rem",
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
  },
  buttonPrimaryVerify: {
    width: "12rem",
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    marginLeft: "1em",
  },
  buttonSecondary: {
    width: "12rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
  },
  buttonSecondaryVerify: {
    width: "12rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    marginLeft: "1em",
  },
  interestedQty: {
    color: "#9D9D9D",
    fontWeight: "normal" as "normal",
    fontSize: "xx-large",
    marginTop: "-6px",
  },
  interestedQtyInfo: {
    marginTop: "6px",
    color: "#505050",
  },
  BigNumberQty: {
    marginTop: "3rem",
    marginBottom: "0.5rem",
    fontWeight: "bold" as "bold",
  },
  titleChart: {
    fontWeight: "bold" as "bold",
  },
  titleChartLeftUpper: {
    fontWeight: "bold" as "bold",
    marginLeft: "4rem",
  },
  titleChartLeftLower: {
    fontWeight: "bold" as "bold",
    marginLeft: "7.5rem",
  },
  titleChartLeftLowerTypography: {
    fontWeight: "bold" as "bold",
    marginLeft: "7.5rem",
    marginBottom: "2rem",
  },
  titleChartDonutLegend: {
    marginLeft: "1rem",
    color: "#646464",
    fontWeight: "normal" as "normal",
  },
  menuOptions: {
    color: "#646464",
    inkBarStyle: {
      backgroundColor: "#01C09E",
    },
  },
  DonutChartTextStyle: {
    fill: "#01C09E",
    fontWeight: "bold" as "bold",
    fontSize: "3rem",
  },
  lineChartStyleX: {
    axis: { stroke: "#787878", strokeWidth: "0.5" },
    axisLabel: { fontSize: "10", fill: "#787878" },
    tick: { stroke: "#787878", strokeWidth: "0.5" },
    tickLabels: { fontSize: "10", fill: "#787878" },
  },
  lineChartStyleY: {
    grid: { stroke: "#787878", strokeWidth: "0.25" },
    axis: { stroke: "#787878", strokeWidth: "0.5" },
    axisLabel: { fontSize: "10", fill: "#787878" },
    tick: { stroke: "#787878", strokeWidth: "0.5" },
    tickLabels: { fontSize: "10", fill: "#787878" },
    data: { fill: "#009688", strokeWidth: "5" },
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
  infoOffersNotFound: {
    width: "100%",
    textAlign: "center" as "center",
    marginTop: "2em",
    marginBottom: "2em",
    fontSize: "1.8em",
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
    fontStyle: "italic" as "italic",
    color: "#dedede",
  },
  infoGroupsNotFound: {
    width: "100%",
    textAlign: "center" as "center",
    marginTop: "4em",
    marginBottom: "2em",
    fontSize: "1.8em",
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
    fontStyle: "italic" as "italic",
    color: "#dedede",
  },
  paperOffersNotFound: {
    width: "100%",
    marginTop: "1em",
    paddingLeft: "3em",
    paddingRight: "3em",
    height: "auto",
  },
};

const styles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    marginLeft: 12,
  },
  toolbar: {
    paddingRight: 1,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
  },

  menuButton: {
    marginRight: "36",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "7",
    [theme.breakpoints.up("sm")]: {
      width: "9",
    },
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: "4",
    paddingBottom: "4",
  },
  paper: {
    padding: "2",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  spinner: {
    flex: "1",
    alignSelf: "center",
  },
  paginationDataGridFooter: {
    justifyContent: "flex-end",
  },
}));
interface UserViewProps {
  isLoading: boolean;
  isLoadingData: boolean;
  optionMenu: number;
  styles: any;
  userToken: string;
  privToken: string;
  dashboardData: any;
  groupName: string;
  group_id: string;
  groups: any;
  offers: any;
  offerlist: any;
  userData: any;
  firstUser: any;
  grouplist: any;
  isSmall: any;
}
interface PropsNotify {
  match: any;
}
interface DataLabel {
  value: string;
  label: string;
}
const arrayDictToken = {
  0: "68530c1b1ea942a0b8178f1c2f92c0fe",
  1: "3b228a2d119443f29e11f60e183a199c",
};

export class UserView extends Component<UserViewProps & PropsNotify> {
  state: UserViewProps;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingData: true,
      styles: styles,
      optionMenu: 0,
      userToken: props.match.params.id,
      privToken: props.match.params.token,
      firstUser: localStorage.getItem("id"),
      dashboardData: null,
      groupName: "",
      group_id: "",
      groups: [],
      offers: [],
      offerlist: [],
      userData: null,
      grouplist: [],
      isSmall: null,
    };
    this.handleChangeMenu = this.handleChangeMenu.bind(this);
  }

  PreviewImage = () => {
    return (
      <img src={this.state.userData.avatar} style={staticStyles.groupImage} />
    );
  };

  TitleBarInfo() {
    return (
      <React.Fragment>
        <Grid xs={2} item>
          {this.PreviewImage()}
        </Grid>

        <Grid xs={4} item>
          <Grid style={staticStyles.itemGroups}>
            {`${this.state.userData.name} ${this.state.userData.surname}`}
          </Grid>
          <Grid style={staticStyles.secondItem}>
            {arrayDictToken[0] === this.state.privToken
              ? "Użytkownik_1"
              : "Użytkownik_2"}
          </Grid>
        </Grid>
        {/* <Grid xs={4} item>
                    <Grid style={staticStyles.item}>
                        {this.CheckPermission()}
                    </Grid>
                </Grid> */}
      </React.Fragment>
    );
  }

  GetRightUpperSide() {
    return (
      <>
        <Grid container style={staticStyles.itemTags} direction="row">
          <Typography style={staticStyles.titleChart}>
            Liczba ofert z danym typem tagu
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={6}>
          <Grid item>
            <Paper style={staticStyles.paperTags} variant="outlined">
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography
                  style={{
                    marginTop: "3rem",
                    marginBottom: "0.5rem",
                    fontWeight: "bold" as "bold",
                    color:
                      this.state.dashboardData.colors.offer_by_tag.first_tag,
                  }}
                  variant="h3"
                >
                  {this.state.dashboardData.tags.all}
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography style={staticStyles.interestedQtyInfo}>
                  Złożone
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <Paper style={staticStyles.paperTags} variant="outlined">
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography
                  style={{
                    marginTop: "3rem",
                    marginBottom: "0.5rem",
                    fontWeight: "bold" as "bold",
                    color:
                      this.state.dashboardData.colors.offer_by_tag.second_tag,
                  }}
                  variant="h3"
                >
                  {this.state.dashboardData.tags.verify}
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography style={staticStyles.interestedQtyInfo}>
                  Rozpatrywane
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <Paper style={staticStyles.paperTags} variant="outlined">
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography
                  style={{
                    marginTop: "3rem",
                    marginBottom: "0.5rem",
                    fontWeight: "bold" as "bold",
                    color:
                      this.state.dashboardData.colors.offer_by_tag.third_tag,
                  }}
                  variant="h3"
                >
                  {this.state.dashboardData.tags.accepted}
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography style={staticStyles.interestedQtyInfo}>
                  Zaakceptowane
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
  GetLeftLowerSide() {
    return (
      <>
        <Grid xs={12} item style={staticStyles.item}>
          <Typography style={staticStyles.titleChartLeftLower}>
            Statusy ofert
          </Typography>
          <Grid xs={12} item>
            {" "}
            {/*style={staticStyles.item}>*/}
            <svg width={350} height={350}>
              <circle cx={175} cy={175} r={80} fill="#fafafa" />
              {/* <Typography style={{marginTop: "175", marginLeft: "175"}} name="interested_title" component="h7"  label="">0%</Typography> */}
              <text
                style={staticStyles.DonutChartTextStyle}
                textAnchor="middle"
                x="175"
                y="190"
              >
                {this.state.dashboardData.pie_chart[3].label}
              </text>
              <VictoryPie
                labelComponent={<VictoryTooltip />}
                standalone={false}
                width={350}
                height={350}
                innerRadius={85}
                data={this.state.dashboardData.pie_chart}
                colorScale={[
                  this.state.dashboardData.colors.donut_chart.fourth_val,
                  this.state.dashboardData.colors.donut_chart.third_val,
                  this.state.dashboardData.colors.donut_chart.second_val,
                  this.state.dashboardData.colors.donut_chart.first_val,
                ]}
                events={[
                  {
                    target: "data",
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: "data",
                            mutation: () => ({ style: { width: 20 } }),
                          },
                          {
                            target: "labels",
                            mutation: () => ({ active: true }),
                          },
                        ];
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: "data",
                            mutation: () => {},
                          },
                          {
                            target: "labels",
                            mutation: () => ({ active: false }),
                          },
                        ];
                      },
                    },
                  },
                ]}
              />
            </svg>
          </Grid>
          <Grid style={{ marginLeft: "6rem" }} container>
            <Grid xs={12} item>
              <Grid container>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.donut_chart.first_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Zaakceptowane
                </Typography>
              </Grid>
              <Typography
                style={staticStyles.titleChartLeftLowerTypography}
              ></Typography>
            </Grid>
            <Grid xs={12} item>
              <Grid container>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.donut_chart.second_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Rozpatrywane
                </Typography>
              </Grid>
              <Typography
                style={staticStyles.titleChartLeftLowerTypography}
              ></Typography>
            </Grid>
            <Grid xs={12} item>
              <Grid container>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.donut_chart.third_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Odrzucone
                </Typography>
              </Grid>
              <Typography
                style={staticStyles.titleChartLeftLowerTypography}
              ></Typography>
            </Grid>
            <Grid xs={12} item>
              <Grid container>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.donut_chart.fourth_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Anulowane
                </Typography>
              </Grid>
              <Typography
                style={staticStyles.titleChartLeftLowerTypography}
              ></Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  */}
      </>
    );
  }
  ReturnBarChartData() {
    var data = this.state.dashboardData.line_chart;
    const items: any[] = [];
    const keys: any[] = [];
    for (var key in data) {
      items.push(
        <VictoryBar
          data={data[key]}
          x="x"
          y="y"
          labelComponent={<VictoryTooltip style={{ fontSize: "10px" }} />}
          events={[
            {
              target: "data",
              eventHandlers: {
                onMouseOver: (props) => {
                  return [
                    {
                      target: "data",
                      mutation: () => ({ style: { width: 20 } }),
                    },
                    { target: "labels", mutation: () => ({ active: true }) },
                  ];
                },
                onMouseOut: () => {
                  return [
                    {
                      target: "data",
                      mutation: () => {},
                    },
                    { target: "labels", mutation: () => ({ active: false }) },
                  ];
                },
              },
            },
          ]}
        />
      );
      if (keys.length == 0) {
        for (var val in data[key]) {
          keys.push(data[key][val]["x"]);
        }
      }
    }
    return (
      <>
        <VictoryChart domainPadding={20}>
          <VictoryAxis dependentAxis style={staticStyles.lineChartStyleY} />
          <VictoryAxis
            tickValues={keys}
            tickFormat={keys}
            style={staticStyles.lineChartStyleX}
          />
          <VictoryStack
            colorScale={[
              this.state.dashboardData.colors.donut_chart.first_val,
              this.state.dashboardData.colors.donut_chart.second_val,
              this.state.dashboardData.colors.donut_chart.third_val,
              this.state.dashboardData.colors.donut_chart.fourth_val,
            ]}
          >
            {items}
          </VictoryStack>
        </VictoryChart>
        <Grid style={{ marginLeft: "6rem", marginTop: "-3rem" }} container>
          <Grid container style={{ flexWrap: "initial" }}>
            <Grid container style={{ flexWrap: "initial" }}>
              <Paper
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  backgroundColor:
                    this.state.dashboardData.colors.donut_chart.first_val,
                }}
                elevation={0}
                square
              />
              <Typography style={staticStyles.titleChartDonutLegend}>
                Zaakceptowane
              </Typography>
            </Grid>
            <Grid container style={{ flexWrap: "initial" }}>
              <Paper
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  backgroundColor:
                    this.state.dashboardData.colors.donut_chart.second_val,
                }}
                elevation={0}
                square
              />
              <Typography style={staticStyles.titleChartDonutLegend}>
                Rozpatrywane
              </Typography>
            </Grid>
            <Grid container style={{ flexWrap: "initial" }}>
              <Paper
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  backgroundColor:
                    this.state.dashboardData.colors.donut_chart.third_val,
                }}
                elevation={0}
                square
              />
              <Typography style={staticStyles.titleChartDonutLegend}>
                Odrzucone
              </Typography>
            </Grid>
            <Grid container style={{ flexWrap: "initial" }}>
              <Paper
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  backgroundColor:
                    this.state.dashboardData.colors.donut_chart.fourth_val,
                }}
                elevation={0}
                square
              />
              <Typography style={staticStyles.titleChartDonutLegend}>
                Anulowane
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  GetRightLowerSide() {
    return (
      <>
        <Grid container style={staticStyles.itemTags} direction="row">
          <Typography style={staticStyles.titleChart}>
            Liczba ofert z danym typem tagu
          </Typography>
        </Grid>
        <Grid container direction="row" style={{ marginTop: "-7rem" }}>
          {this.ReturnBarChartData()}
        </Grid>
      </>
    );
  }
  GetLeftUpperSide() {
    return (
      <>
        <Grid xs={12} item style={staticStyles.item}>
          <Typography style={staticStyles.titleChartLeftUpper}>
            Ilość zainteresowanych grupą
          </Typography>
          <Paper style={staticStyles.paperLeftUpper} variant="outlined">
            <Grid
              xs={12}
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <GroupIcon style={staticStyles.iconUsers} />
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Typography style={staticStyles.interestedQty}>
                {this.state.dashboardData.interested_qty}
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Typography style={staticStyles.interestedQtyInfo}>
                Zainteresowani
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </>
    );
  }
  handleChangeGroup = (event: any, newValue: number) => {
    this.setState({ group_id: event.value });
    this.GetDataFromApiDashBoard(event.value, null);
  };
  GetDataFromApiDashBoard(group_id: any, second_user_id: any) {
    var payload = {
      user_id: this.state.userToken,
      group_id: group_id,
      second_user_id: second_user_id,
    };

    axios
      .get(API_URL + `/dashboard?${stringify(payload)}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `dashboard${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        var responses = response;
        if (responses.data.code === 121) {
          this.setState({ dashboardData: responses.data.data });
          this.setState({ code: responses.data.code });
          this.setState({
            groups:
              this.state.dashboardData == null
                ? []
                : this.state.dashboardData.groups,
          });
          this.setState({
            groupName:
              this.state.dashboardData == null
                ? ""
                : this.state.dashboardData.group_name,
          });
        }
        this.setState({ isLoadingData: false });
      })
      .catch(function (err) {});
  }
  GetDataFromApiGroups(group_id: any, second_user_id: any) {
    var payload = {
      user_id: this.state.userToken,
    };

    axios
      .get(API_URL + `/getgrouplist?${stringify(payload)}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `getgrouplist${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        var responses = response;
        if (responses.data.code === 105) {
          this.setState({ grouplist: responses.data.data });
        }
        this.setState({ isLoadingData: false });
      })
      .catch(function (err) {});
  }
  GetDataFromApiOffers() {
    var payload = {
      user_id: this.state.userToken,
    };

    axios
      .get(API_URL + `/getofferlist?${stringify(payload)}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `getofferlist${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        var responses = response;
        if (responses.data.code === 127) {
          this.setState({ offerlist: responses.data.data });
        }
        this.setState({ isLoadingData: false });
      })
      .catch(function (err) {});
  }
  GetDataFromApiUser() {
    axios
      .get(API_URL + `/getuser/${this.state.userToken}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `getuser${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        var responses = response;
        if (responses.data.code === 105) {
          this.setState({ userData: responses.data.data });
        }
        this.setState({ isLoading: false });
      })
      .catch(function (err) {});
  }
  GetGroupList() {
    var arr: DataLabel[] = [];
    var defaultVal = {};
    if (this.state.groups.length == 1) {
      defaultVal = {
        value: this.state.groups[0].group_token,
        label: this.state.groups[0].group_name,
      };
    }
    this.state.groups.map((item) => {
      if (item.group_name == this.state.groupName) {
        defaultVal = { value: item.group_token, label: item.group_name };
      }
      arr.push({ value: item.group_token, label: item.group_name });
    });
    if (arr.length == 0) {
      return <div style={{ width: "20em", marginTop: "2em" }}></div>;
    }
    return (
      <div style={{ width: "20em", marginTop: "2em" }}>
        <Select
          style={{ width: "20em", marginTop: "2em" }}
          options={arr}
          defaultValue={defaultVal}
          onChange={this.handleChangeGroup}
        />
      </div>
    );
  }
  StatsPanel() {
    const classes = this.state.styles;
    if (this.state.isLoadingData) {
      this.GetDataFromApiDashBoard(
        this.state.group_id === "" ? null : this.state.group_id,
        null
      );
    }
    while (this.state.isLoadingData) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }
    if (this.state.dashboardData != null) {
      return (
        <React.Fragment>
          <Grid xs={4} justify="center" alignItems="center" container>
            {this.GetLeftUpperSide()}
          </Grid>
          <Grid xs={8} justify="center" alignItems="center" container>
            {this.GetRightUpperSide()}
          </Grid>
          <Grid xs={11} container>
            <Paper></Paper>
          </Grid>
          <Grid xs={4} container>
            {this.GetLeftLowerSide()}
          </Grid>
          <Grid xs={8} container>
            {this.GetRightLowerSide()}
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Paper
            elevation={0}
            variant="outlined"
            style={staticStyles.paperOffersNotFound}
          >
            <Typography style={staticStyles.infoOffersNotFound}>
              Nie znaleziono ofert użytkownika
            </Typography>
          </Paper>
        </Grid>
      );
    }
  }
  OfertsPanel() {
    const classes = this.state.styles;
    if (this.state.isLoadingData) {
      this.GetDataFromApiOffers();
    }
    while (this.state.isLoadingData || this.state.offerlist === null) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }
    if (this.state.offerlist.length >= 0) {
      const columns: ColDef[] = [
        {
          field: "offer_name",
          headerName: "Nazwa oferty",
          width: 350,
        },
        { field: "status", headerName: "Status", width: 130 },
        { field: "group_name", headerName: "Grupa", width: 200 },
        { field: "modified_at", headerName: "Data aktualizacji", width: 200 },
        { field: "created_at", headerName: "Data utworzenia", width: 200 },
      ];
      const rows = this.state.offerlist;
      return (
        <div style={{ minHeight: 400, width: "100%", marginTop: "50px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick={true}
            hideFooterRowCount={true}
            hideFooterSelectedRowCount={true}
          />{" "}
        </div>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Paper
            elevation={0}
            variant="outlined"
            style={staticStyles.paperOffersNotFound}
          >
            <Typography style={staticStyles.infoOffersNotFound}>
              Nie znaleziono ofert użytkownika
            </Typography>
          </Paper>
        </Grid>
      );
    }
  }
  GroupsPanel() {
    const classes = this.state.styles;
    if (this.state.isLoadingData) {
      this.GetDataFromApiGroups(null, null);
    }
    while (this.state.isLoadingData || this.state.grouplist === null) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }
    if (this.state.grouplist.length > 0) {
      const columns: ColDef[] = [
        {
          field: "group_name",
          headerName: "Nazwa grupy",
          width: 350,
          renderCell: (params: ValueFormatterParams) => (
            <Grid xs={12} container direction="row">
              <img
                src={params.data.image_link}
                style={staticStyles.rowGroupImage}
              />
              <Typography style={staticStyles.groupTitle}>
                {params.data.group_name}
              </Typography>
            </Grid>
          ),
        },
        {
          field: "status",
          headerName: "Zweryfikowano",
          width: 130,
          renderCell: (params: ValueFormatterParams) => {
            if (params.data.is_verified) {
              return (
                <Tooltip title="Grupa jest zweryfikowana">
                  <DoneIcon />
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title="Grupa nie jest zweryfikowana">
                  <ClearIcon />
                </Tooltip>
              );
            }
          },
        },
        { field: "group_author", headerName: "Autor", width: 200 },
        { field: "users_qty", headerName: "Ilość członków", width: 200 },
        { field: "interested_qty", headerName: "Zainteresowani", width: 180 },
        {
          field: "",
          headerName: "",
          width: 50,
          renderCell: (params: ValueFormatterParams) => {
            return (
              <Tooltip title="Przejdź">
                <ArrowForwardIcon
                  style={staticStyles.actionIcons}
                  onClick={() => {
                    window.location.href = `/getdashboards/${params.data.token}`;
                  }}
                />
              </Tooltip>
            );
          },
        },
      ];
      if (this.state.privToken === arrayDictToken[1]) {
        let groupListTemp: any[] = [];
        this.state.grouplist.map((elem, index) => {
          if (elem.is_interested === true) {
            groupListTemp.push(elem);
          }
        });
        var rows: any[] = groupListTemp;
      } else {
        var rows: any[] = this.state.grouplist;
      }
      return (
        <div style={{ minHeight: 400, width: "100%", marginTop: "50px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick={true}
            hideFooterRowCount={true}
            hideFooterSelectedRowCount={true}
          />
        </div>
      );
    } else {
      <Grid container spacing={3}>
        <Paper
          elevation={0}
          variant="outlined"
          style={staticStyles.paperOffersNotFound}
        >
          <Typography style={staticStyles.infoOffersNotFound}>
            Nie znaleziono grup użytkownika
          </Typography>
        </Paper>
      </Grid>;
    }
  }

  handleChangeMenu = (event: any, newValue: number) => {
    this.setState({ isLoadingData: true });
    this.setState({ optionMenu: newValue });
  };
  StatsOptionsMenu() {
    return (
      <div>
        <Tabs
          value={this.state.optionMenu}
          onChange={this.handleChangeMenu}
          TabIndicatorProps={{ style: { background: "#01C09E" } }}
        >
          <Tab label="Statystyki" />
          <Tab label="Oferty" />
          <Tab label="Grupy" />
        </Tabs>
      </div>
    );
  }
  StatsGlobalPanel() {
    if (this.state.optionMenu === 0) {
      return (
        <>
          <Grid container xs={11}>
            {this.StatsOptionsMenu()}
          </Grid>
          <Grid xs={12} container>
            {this.GetGroupList()}
          </Grid>
          <Grid container xs={12}>
            {this.StatsPanel()}
          </Grid>
        </>
      );
    } else if (this.state.optionMenu === 1) {
      return (
        <>
          <Grid container xs={12}>
            {this.StatsOptionsMenu()}
          </Grid>
          <Grid container xs={12}>
            <React.Fragment>{this.OfertsPanel()}</React.Fragment>
          </Grid>
        </>
      );
    } else if (this.state.optionMenu === 2) {
      return (
        <>
          <Grid container xs={12}>
            {this.StatsOptionsMenu()}
          </Grid>
          <Grid container xs={12}>
            <React.Fragment>{this.GroupsPanel()}</React.Fragment>
          </Grid>
        </>
      );
    }
  }

  render() {
    const { styles, userToken } = this.state;
    const classes = styles;
    if (this.state.privToken === arrayDictToken[0]) {
      if (this.state.isLoading) {
        this.GetDataFromApiUser();
      }
      while (this.state.isLoading) {
        return (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "70vh" }}
          >
            <Grid xs={12} item>
              <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
            </Grid>
          </Grid>
        );
      }
      return (
        <>
          <Container className={classes.container}>
            <Typography style={staticStyles.infoBarUser}>
              Użytkownik_1: {this.state.userData.name}
            </Typography>
            <Grid container spacing={3}>
              <Grid
                style={{ marginBottom: "3em" }}
                container
                xs={6}
                md={4}
                lg={8}
              >
                {this.TitleBarInfo()}
              </Grid>
              {this.StatsGlobalPanel()}
            </Grid>
          </Container>
        </>
      );
    } else if (this.state.privToken === arrayDictToken[1]) {
      if (this.state.isLoading) {
        this.GetDataFromApiUser();
      }
      while (this.state.isLoading) {
        return (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "70vh" }}
          >
            <Grid xs={12} item>
              <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
            </Grid>
          </Grid>
        );
      }
      return (
        <>
          <Container className={classes.container}>
            <Typography style={staticStyles.infoBarUser}>
              Użytkownik_2: {this.state.userData.name}
            </Typography>
            <Grid container spacing={3}>
              <Grid
                style={{ marginBottom: "3em" }}
                container
                xs={6}
                md={4}
                lg={8}
              >
                {this.TitleBarInfo()}
              </Grid>
              {this.GroupsPanel()}
            </Grid>
          </Container>
        </>
      );
    } else {
      return (
        <Card>
          <Title title="SELECTRIN" />
          <CardContent></CardContent>
        </Card>
      );
    }
  }
}
