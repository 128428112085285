import React, { Component, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { makeStyles } from "@material-ui/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import PeopleIcon from "@material-ui/icons/People";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";
import { getUserRole } from "../utils";

interface PropsInter {
  match: any;
}
interface DataLabel {
  value: string;
  label: string;
}

interface OfferNotify {
  offer_token: string;
  isLoading: boolean;
  styles: any;
  offerFound: boolean;
  offerNotFound: boolean;
  offerData: any;
  snackbar: boolean;
}

const staticStyles = {
  snackbar: {
    width: "100%",
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
  paperItemElem: {
    paddingLeft: "2em",
    paddingRight: "2em",
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  titleOffer: {
    fontSize: "2em",
    marginBottom: "0.5em",
    marginTop: "0.5em",
    fontWeight: "bold" as "bold",
  },
  textFieldRenderStyle: {
    padding: "0.8em",
  },
  textFieldRenderTitle: {
    marginBottom: "revert",
    fontSize: "1.2em",
    color: "#646464",
  },
  checkboxFilled: {
    width: "1.2em",
    height: "1.2em",
    border: "3px solid #646464",
    backgroundColor: "#646464",
    marginTop: "0.2em",
    marginBottom: "0.2em",
    marginRight: "1em",
    borderRadius: "1.2em",
  },
  checkboxNotFilled: {
    width: "1.2em",
    height: "1.2em",
    border: "3px solid #646464",
    marginTop: "0.2em",
    marginBottom: "0.2em",
    marginRight: "1em",
    borderRadius: "1.2em",
  },
  checkboxFilledOrigin: {
    width: "1.2em",
    height: "1.2em",
    border: "3px solid #646464",
    backgroundColor: "#646464",
    marginTop: "0.2em",
    marginBottom: "0.2em",
    marginRight: "1em",
  },
  checkboxNotFilledOrigin: {
    width: "1.2em",
    height: "1.2em",
    border: "3px solid #646464",
    marginTop: "0.2em",
    marginBottom: "0.2em",
    marginRight: "1em",
  },
  optionTextField: {
    marginTop: "0.2em",
  },
  paperTags: {
    padding: "30px 0",
  },
  interestedQtyInfo: {
    marginTop: "6px",
    color: "#505050",
  },
  groupIcon: {
    width: "3rem",
    height: "3rem",
    color: "#01C09E",
  },
  infoOffersNotFound: {
    width: "100%",
    textAlign: "center" as "center",
    marginTop: "2em",
    marginBottom: "2em",
    fontSize: "1.8em",
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
    fontStyle: "italic" as "italic",
    color: "#dedede",
  },
  paperOffersNotFound: {
    width: "100%",
    marginTop: "1em",
    height: "87vh",
  },
  imageRenderData: {
    width: "100%",
    maxWidth: "850px",
    height: "auto",
  },
};

const styles = makeStyles((theme: any) => ({
  spinner: {
    flex: "1",
    alignSelf: "center",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ShowOffer extends Component<OfferNotify & PropsInter> {
  state: OfferNotify;

  constructor(props: any) {
    super(props);
    this.state = {
      offer_token: this.props.match.params.id,
      isLoading: true,
      styles: styles,
      offerFound: false,
      offerNotFound: false,
      offerData: [],
      snackbar: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }
  GetFormFromApi() {
    var that = this;
    axios
      .get(
        API_URL +
          `/offer/${this.state.offer_token}?user_id=${localStorage.getItem(
            "id"
          )}`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `offer${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        if (response.data.code === 136) {
          this.setState({ offerData: response.data.data, isLoading: false });
        } else if (response.data.code === 1028) {
          this.setState({
            offerNotFound: true,
            isLoading: false,
            snackbar: true,
          });
        } else {
          this.setState({
            offerNotFound: true,
            isLoading: false,
            snackbar: true,
          });
        }
      })
      .catch(function (error) {
        that.setState({
          offerNotFound: true,
          isLoading: false,
          snackbar: true,
        });
      });
  }
  handleClose() {
    this.setState({ snackbar: false });
  }
  downloadData(event: any, link: any) {
    window.open(link, "Download");
  }
  showPDF(event: any, link: any) {
    var tempLink = link.split("/");
  }
  RenderOffer() {
    var indents: any[] = [];
    this.state.offerData.form_data.map((item, index) => {
      if (item.type === "text") {
        indents.push(
          <Grid item>
            <Paper
              elevation={0}
              variant="outlined"
              square
              style={staticStyles.paperItemElem}
            >
              <Typography style={staticStyles.textFieldRenderTitle}>
                {item.label != null ? item.label.replace("<br>", "") : ""}
              </Typography>
              <Paper
                variant="outlined"
                style={staticStyles.textFieldRenderStyle}
              >
                <Typography>{item.value}</Typography>
              </Paper>
            </Paper>
          </Grid>
        );
      } else if (item.type === "date") {
        indents.push(
          <Grid item>
            <Paper
              elevation={0}
              variant="outlined"
              square
              style={staticStyles.paperItemElem}
            >
              <Typography style={staticStyles.textFieldRenderTitle}>
                {item.label != null ? item.label.replace("<br>", "") : ""}
              </Typography>
              <Paper
                variant="outlined"
                style={staticStyles.textFieldRenderStyle}
              >
                <Typography>{item.value}</Typography>
              </Paper>
            </Paper>
          </Grid>
        );
      } else if (item.type === "paragraph") {
        indents.push(
          <Grid item>
            <Paper
              elevation={0}
              variant="outlined"
              square
              style={staticStyles.paperItemElem}
            >
              <Typography style={staticStyles.textFieldRenderTitle}>
                {item.label != null ? item.label.replace("<br>", "") : ""}
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: item.value }} />
            </Paper>
          </Grid>
        );
      } else if (item.type === "textarea") {
        indents.push(
          <Grid item>
            <Paper
              elevation={0}
              variant="outlined"
              square
              style={staticStyles.paperItemElem}
            >
              <Typography style={staticStyles.textFieldRenderTitle}>
                {item.label != null ? item.label.replace("<br>", "") : ""}
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: item.value }} />
            </Paper>
          </Grid>
        );
      } else if (item.type === "number") {
        indents.push(
          <Grid item>
            <Paper
              elevation={0}
              variant="outlined"
              square
              style={staticStyles.paperItemElem}
            >
              <Typography style={staticStyles.textFieldRenderTitle}>
                {item.label != null ? item.label.replace("<br>", "") : ""}
              </Typography>
              <Paper
                variant="outlined"
                style={staticStyles.textFieldRenderStyle}
              >
                <Typography>{item.value}</Typography>
              </Paper>
            </Paper>
          </Grid>
        );
      } else if (item.type === "select") {
        var points: any[] = [];
        item.value.map((line, index) => {
          if (line.value !== "" && line.label !== "") {
            if (line.selected) {
              points.push(
                <Grid container direction="row">
                  <Grid item>
                    <Paper
                      variant="outlined"
                      style={staticStyles.checkboxFilled}
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={staticStyles.optionTextField}>
                      {line.label != null ? line.label.replace("<br>", "") : ""}
                    </Typography>
                  </Grid>
                </Grid>
              );
            } else {
              points.push(
                <Grid container direction="row">
                  <Grid item>
                    <Paper
                      variant="outlined"
                      style={staticStyles.checkboxNotFilled}
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={staticStyles.optionTextField}>
                      {line.label != null ? line.label.replace("<br>", "") : ""}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }
          }
        });
        indents.push(
          <Grid item>
            <Paper
              elevation={0}
              variant="outlined"
              square
              style={staticStyles.paperItemElem}
            >
              <Typography style={staticStyles.textFieldRenderTitle}>
                {item.label != null ? item.label.replace("<br>", "") : ""}
              </Typography>
              <Paper
                variant="outlined"
                style={staticStyles.textFieldRenderStyle}
              >
                <Grid>{points}</Grid>
              </Paper>
            </Paper>
          </Grid>
        );
      } else if (item.type === "checkbox-group") {
        var points: any[] = [];
        item.value.map((line, index) => {
          if (line.selected) {
            points.push(
              <Grid container direction="row">
                <Grid item>
                  <Paper
                    variant="outlined"
                    style={staticStyles.checkboxFilledOrigin}
                  />
                </Grid>
                <Grid item>
                  <Typography style={staticStyles.optionTextField}>
                    {line.label != null ? line.label.replace("<br>", "") : ""}
                  </Typography>
                </Grid>
              </Grid>
            );
          } else {
            points.push(
              <Grid container direction="row">
                <Grid item>
                  <Paper
                    variant="outlined"
                    style={staticStyles.checkboxNotFilledOrigin}
                  />
                </Grid>
                <Grid item>
                  <Typography style={staticStyles.optionTextField}>
                    {line.label != null ? line.label.replace("<br>", "") : ""}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        });
        indents.push(
          <Grid item>
            <Paper
              elevation={0}
              variant="outlined"
              square
              style={staticStyles.paperItemElem}
            >
              <Typography style={staticStyles.textFieldRenderTitle}>
                {item.label != null ? item.label.replace("<br>", "") : ""}
              </Typography>
              <Paper
                variant="outlined"
                style={staticStyles.textFieldRenderStyle}
              >
                <Grid>{points}</Grid>
              </Paper>
            </Paper>
          </Grid>
        );
      } else if (item.type === "file") {
        if (item.dataType == "image") {
          indents.push(
            <Grid item>
              <Paper
                elevation={0}
                variant="outlined"
                square
                style={staticStyles.paperItemElem}
              >
                <Typography style={staticStyles.textFieldRenderTitle}>
                  {item.label != null ? item.label.replace("<br>", "") : ""}
                </Typography>
                <img
                  src={item.link}
                  style={staticStyles.imageRenderData}
                  alt=""
                ></img>
              </Paper>
            </Grid>
          );
        } else if (item.dataType == "pdf") {
          var elem = <></>;
          if (item.link) {
            var tempLink = item.link.split("/");
            elem = (
              <a href={`/showfile/${tempLink[tempLink.length - 1]}`}>pdf</a>
            );
          }
          indents.push(
            <Grid item>
              <Paper
                elevation={0}
                variant="outlined"
                square
                style={staticStyles.paperItemElem}
              >
                <Typography style={staticStyles.textFieldRenderTitle}>
                  {item.label != null ? item.label.replace("<br>", "") : ""}
                </Typography>
                {elem}
              </Paper>
            </Grid>
          );
        } else if (item.dataType == "file") {
          var elem = <></>;
          if (item.link) {
            elem = (
              <a href="#" onClick={(e) => this.downloadData(e, item.link)}>
                link
              </a>
            );
          }
          indents.push(
            <Grid item>
              <Paper
                elevation={0}
                variant="outlined"
                square
                style={staticStyles.paperItemElem}
              >
                <Typography style={staticStyles.textFieldRenderTitle}>
                  {item.label != null ? item.label.replace("<br>", "") : ""}
                </Typography>
                {elem}
              </Paper>
            </Grid>
          );
        }
      }
    });
    return <>{indents}</>;
  }

  GetRightUpperSide() {
    const userRole = getUserRole();

    if (userRole === "uzytkownik_1" || userRole === "administrator") {
      return (
        <>
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item>
              <Paper style={staticStyles.paperTags} variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                  >
                    <Typography
                      style={{ fontWeight: "bold" as "bold", color: "#9D9D9D" }}
                    >
                      <PeopleIcon style={staticStyles.groupIcon} />
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                  >
                    <Typography
                      style={{ fontWeight: "bold" as "bold", color: "#9D9D9D" }}
                      variant="h3"
                    >
                      {this.state.offerData.views_qty}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                  >
                    <Typography style={staticStyles.interestedQtyInfo}>
                      Liczba wyświetleń
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      );
    }
  }

  render() {
    while (this.state.isLoading) {
      this.GetFormFromApi();
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader
              size={45}
              css={this.state.styles.spinner}
              color={"#01C09E"}
            />
          </Grid>
        </Grid>
      );
    }
    if (this.state.offerNotFound) {
      return (
        <div>
          <Grid container spacing={3}>
            <Paper
              elevation={0}
              variant="outlined"
              style={staticStyles.paperOffersNotFound}
            >
              <Typography style={staticStyles.infoOffersNotFound}>
                Nie znaleziono oferty
              </Typography>
            </Paper>
          </Grid>
          <Snackbar
            open={this.state.snackbar}
            autoHideDuration={3000}
            style={staticStyles.snackbar}
            onClose={this.handleClose}
          >
            <Alert severity="error">Oferta nie znaleziona</Alert>
          </Snackbar>
        </div>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography style={staticStyles.titleOffer}>
              {this.state.offerData.form_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            {this.RenderOffer()}
          </Grid>
          <Grid item xs={12} md={3}>
            {this.GetRightUpperSide()}
          </Grid>
        </Grid>
      );
    }
  }
}

export default ShowOffer;
