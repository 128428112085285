import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { Admin, Resource, fetchUtils   } from 'react-admin';
// import polyglotI18nProvider from "ra-i18n-polyglot";
// import polishMessages from 'ra-language-polish';
// import { stringify } from 'query-string';
// import { AppBar, UserMenu } from 'react-admin';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
