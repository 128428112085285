import React, { Component, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { makeStyles } from "@material-ui/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import draftToHtml from "draftjs-to-html";
// import { stringify } from 'query-string';
import Input from "@material-ui/core/Input";
import { Editor } from "react-draft-wysiwyg";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Select as SelectUI } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Select from 'react-select';
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const staticStyles = {
  snackbar: {
    width: "100%",
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
  paperItemElem: {
    paddingLeft: "2em",
    paddingRight: "2em",
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  titleOffer: {
    fontSize: "2em",
    marginBottom: "2em",
    marginTop: "0.5em",
    fontWeight: "bold" as "bold",
  },
  textFieldRenderStyle: {
    padding: "0.8em",
  },
  textFieldRenderTitle: {
    marginBottom: "revert",
    fontSize: "1.2em",
    color: "#646464",
  },
  checkboxFilled: {
    width: "1.2em",
    height: "1.2em",
    border: "3px solid #646464",
    backgroundColor: "#646464",
    marginTop: "0.2em",
    marginBottom: "0.2em",
    marginRight: "1em",
  },
  checkboxNotFilled: {
    width: "1.2em",
    height: "1.2em",
    border: "3px solid #646464",
    marginTop: "0.2em",
    marginBottom: "0.2em",
    marginRight: "1em",
  },
  optionTextField: {
    marginTop: "0.2em",
  },
  buttonPrimary: {
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
    float: "inline-end" as "inline-end",
    width: "14em",
  },
  buttonSecondary: {
    width: "9rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    height: "1.8em",
    textAlign: "center" as "center",
    marginTop: "0.8em",
    marginLeft: "0.6em",
  },
  muiRichTextField: {
    width: "100%",
    marginTop: "2em",
    marginBottom: "3em",
  },
  paperOutlineLogo: {
    height: "auto",
    marginTop: "2em",
    marginBottom: "2em",
  },
  textField: {
    width: "-moz-available",
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  selectBoxRender: {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  selectFieldStyle: {
    width: "26em",
  },
  labelInfoLogo: {
    position: "absolute" as "absolute",
    transformOrigin: "top left",
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0",
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal" as "normal",
    lineHeight: "1",
    letterSpacing: "0.00938em",
    backgroundColor: "#fff",
    paddingLeft: "0.3em",
    width: "11.5em",
  },
  fileNameText: {
    marginTop: "1em",
    marginLeft: "1em",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.57)",
  },
  inputValues: {
    display: "none",
  },
};

const styles = makeStyles((theme: any) => ({
  spinner: {
    flex: "1",
    alignSelf: "center",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface PropsInter {
  match: any;
}
interface DataLabel {
  value: string;
  label: string;
}

interface OfferNotify {
  offer_token: string;
  isLoading: boolean;
  styles: any;
  offerFound: boolean;
  offerNotFound: boolean;
  offerData: any;
  values: any;
  valuesCopy: any;
  offer_name: any;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
  showNotifyErrorNameExist: boolean;
  showNotifyErrorFieldEmpty: boolean;
  group_id: any;
}

class ShowOffer extends Component<OfferNotify & PropsInter> {
  state: OfferNotify;

  constructor(props: any) {
    super(props);
    this.state = {
      group_id: null,
      offer_token: this.props.match.params.id,
      isLoading: true,
      styles: styles,
      offerFound: false,
      offerNotFound: false,
      offerData: [],
      values: [],
      valuesCopy: [],
      offer_name: undefined,
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      showNotifyErrorNameExist: false,
      showNotifyErrorFieldEmpty: false,
    };
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClickSaveNew = this.handleClickSaveNew.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  GetFormFromApi() {
    axios
      .get(API_URL + `/offer/${this.state.offer_token}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `offer${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 136) {
          try {
            var responseData = JSON.parse(response.data.data.form_data);
          } catch (e) {
            var responseData = response.data.data.form_data;
          }
          this.setState({ offerData: responseData });
          this.setState({ group_id: response.data.data.group_id });
          this.setState({ offer_name: response.data.data.form_name });
          this.setState({ isLoading: false });
        } else {
          this.setState({ offerNotFound: true });
        }
      })
      .catch(function (err) {});
  }
  ParsedDataReturn() {
    var PrepareData: any[] = [];
    var files: any[] = [];
    this.state.offerData.map((item, index) => {
      if (item.required && (item.value === "" || !item.value)) {
        return [false, false];
      }
      if (item.type === "file") {
        if (this.state.values[index].file)
          files.push(this.state.values[index].file);
      }
      PrepareData.push(this.state.values[index]);
    });
    return [PrepareData, files];
  }
  handleClickSave() {
    var ParseSecure: any[] = [];
    var files: any[] = [];
    [ParseSecure, files] = this.ParsedDataReturn();
    if (!ParseSecure && !files) {
      this.setState({ showNotifyErrorFieldEmpty: true });
    } else {
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("id")!);
      formData.append("privileges", localStorage.getItem("privileges")!);
      formData.append("token", this.props.match.params.id);
      formData.append("form_name", this.state.offer_name);
      formData.append("form_data", JSON.stringify(ParseSecure));
      for (let k = 0; k < files.length; k++) {
        formData.append(`offer_files_${k}`, files[k]);
      }
      axios
        .post(API_URL + "/modifyoffer", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bcrypt.hashSync(
              `modifyoffer${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        })
        .then((response) => {
          if (response.data.code === 133) {
            this.setState({ showNotify: true });
            this.sleep(2000).then(() => {
              window.location.href = "/getofferlist";
            });
          } else {
            this.setState({ showNotifyError: true });
            this.sleep(2000).then(() => {
              window.location.href = "/getofferlist";
            });
          }
        })
        .catch(function (error) {});
    }
  }

  handleClickSaveNew() {
    var ParseSecure: any[] = [];
    var files: any[] = [];
    [ParseSecure, files] = this.ParsedDataReturn();
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("id")!);
    formData.append("privileges", localStorage.getItem("privileges")!);
    formData.append("group_id", this.state.group_id);
    formData.append("offer_name", this.state.offer_name);
    formData.append("offer_data", JSON.stringify(ParseSecure));
    for (let i = 0; i < files.length; i++) {
      formData.append(`offer_files_${i}`, files[i]);
    }
    var responses;
    var that = this;
    axios
      .post(API_URL + "/addoffer", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${bcrypt.hashSync(
            `addoffer${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        responses = response;
        if (response.data.code === 132) {
          this.setState({ showNotify: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getofferlist";
          });
        }
        if (responses.data.code === 1031) {
          that.setState({ showNotifyErrorNameExist: true });
        } else {
          this.setState({ showNotifyError: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getofferlist";
          });
        }
      })
      .catch(function (error) {
        if (responses.data.code === 1031) {
          that.setState({ showNotifyErrorNameExist: true });
        }
      });
  }
  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  handleClose() {
    this.setState({
      offerNotFound: false,
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      showNotifyErrorNameExist: false,
      showNotifyErrorFieldEmpty: false,
    });
  }
  handlePrintWhenChange(event: any, index: any) {
    var tempDict = this.state.values;
    tempDict[index].value = draftToHtml(event);
    this.setState({ values: tempDict });
  }
  handleTextFieldOnChange(event: any) {
    this.state.values[event.target.id].value = event.target.value;
    var tempDict = this.state.values;
    tempDict[event.target.id].value = event.target.value;
    this.setState({ values: tempDict });
  }
  handleNumberFieldOnChange(event: any) {
    if (event.target.validity.valid) {
      let tempArr: any[] = this.state.values;
      if (
        event.target.value.charAt(event.target.value.length - 1).match("^[.]$")
      ) {
        tempArr[event.target.id].value = event.target.value;
      } else {
        if (event.target.value.match("^[+-]$")) {
          tempArr[event.target.id].value = event.target.value;
        } else {
          tempArr[event.target.id].value = parseFloat(
            event.target.value
          ).toString();
        }
      }
      tempArr[event.target.id].match = true;
      this.setState({ values: tempArr });
    } else if (event.target.value === "") {
      let tempArr: any[] = this.state.values;
      tempArr[event.target.id].value = event.target.value;
      this.setState({ values: tempArr });
    } else {
    }
  }
  handleChangeFormRender(event: any, index: number) {
    let tempArr: any[] = this.state.values;
    if (tempArr[index].type === "select") {
      tempArr[index].default = event.target.value;
      tempArr[index].value.map((elem, mapIndex) => {
        if (elem.value === event.target.value) {
          tempArr[index].value[mapIndex].selected = true;
        } else {
          tempArr[index].value[mapIndex].selected = false;
        }
      });
    } else {
      if (event.target.value.length <= 1) {
        tempArr[index].default = event.target.value;
      } else {
        tempArr[index].default = event.target.value;
      }
    }
    this.setState({ values: tempArr });
  }
  handleChangeCheckbox(event, index, valindex) {
    let tempArr: any[] = this.state.values;
    tempArr[index].value[valindex].selected =
      !tempArr[index].value[valindex].selected;
    this.setState({ values: tempArr });
  }
  handleChangeFile(event: any, index: any) {
    if (this.state.values[index].dataType === "file") {
      let tempArr: any[] = this.state.values;
      tempArr[index].fileName = event.target.files[0].name;
      tempArr[index].file = event.target.files[0];
      tempArr[index].changed = true;
      this.setState({ values: tempArr });
    } else if (this.state.values[index].dataType === "image") {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        let tempArr: any[] = this.state.values;
        tempArr[index].fileName = event.target.files[0].name;
        tempArr[index].file = event.target.files[0];
        tempArr[index].changed = true;
        this.setState({ values: tempArr });
      }
    } else if (this.state.values[index].dataType === "pdf") {
      if (event.target.files[0].name.match(/\.(pdf)$/)) {
        let tempArr: any[] = this.state.values;
        tempArr[index].fileName = event.target.files[0].name;
        tempArr[index].file = event.target.files[0];
        tempArr[index].changed = true;
        this.setState({ values: tempArr });
      }
    }
  }

  customContentStateConverter(contentState) {
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block.merge({
              type: "atomic",
              text: "img",
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set("blockMap", newBlockMap);
    return newContentState;
  }
  RenderOffer() {
    var indents: any[] = [];
    var indexTemp = 0;
    this.handlePrintWhenChange = this.handlePrintWhenChange.bind(this);
    this.handleTextFieldOnChange = this.handleTextFieldOnChange.bind(this);
    this.handleNumberFieldOnChange = this.handleNumberFieldOnChange.bind(this);
    this.handleChangeFormRender = this.handleChangeFormRender.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.state.offerData.map((item: any, index: any) => {
      if (item.type === "text") {
        this.state.values.push({
          index: index,
          type: item.type,
          value: item.value ? item.value : null,
          label: item.label,
          required: item.required,
        });
        indents.push(
          <Grid item>
            <TextField
              id={index}
              onChange={this.handleTextFieldOnChange}
              value={this.state.values[index].value}
              style={staticStyles.textField}
              label={item.label != null ? item.label.replace("<br>", "") : ""}
              variant="outlined"
              type={item.type}
              required={item.required}
            />
          </Grid>
        );
      } else if (item.type === "date") {
        this.state.values.push({
          index: index,
          type: item.type,
          value: item.value ? item.value : null,
          label: item.label,
          required: item.required,
        });
        indents.push(
          <Grid item>
            <TextField
              id={index}
              onChange={this.handleTextFieldOnChange}
              value={this.state.values[index].value}
              style={staticStyles.textField}
              label={item.label != null ? item.label.replace("<br>", "") : ""}
              variant="outlined"
              type={item.type}
              required={item.required}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        );
      } else if (item.type === "paragraph") {
        this.state.values.push({
          index: index,
          type: item.type,
          value: item.value ? item.value : null,
          label: item.label,
          required: item.required,
        });
        indents.push(
          <Grid item>
            <Typography
              id={index}
              onChange={this.handleTextFieldOnChange}
              style={staticStyles.textField}
            >
              {item.label != null ? item.label.replace("<br>", "") : ""}
            </Typography>
          </Grid>
        );
      } else if (item.type === "textarea") {
        this.state.values.push({
          label: item.label,
          index: index,
          type: item.type,
          value: item.value,
        });
        var elem = this.state.values[index].value;
        const blocksFromHTML = convertFromHTML(elem);
        indents.push(
          <Grid item>
            <Paper variant="outlined" style={staticStyles.paperOutlineLogo}>
              <Editor
                id={index}
                editorClassName={{ minHeight: "auto" }}
                onContentStateChange={(e: any) =>
                  this.handlePrintWhenChange(e, index)
                }
                defaultEditorState={EditorState.createWithContent(
                  this.customContentStateConverter(
                    ContentState.createFromBlockArray(
                      blocksFromHTML.contentBlocks,
                      blocksFromHTML.entityMap
                    )
                  )
                )}
              />
            </Paper>
          </Grid>
        );
      } else if (item.type === "number") {
        var isSet = this.state.values[index] == null ? false : true;
        this.state.values.push({
          label: item.label,
          index: index,
          type: item.type,
          value: item.value != null ? item.value : "",
          match: true,
          required: item.required,
        });
        var value = isSet ? this.state.values[index].value : "";
        if (item.min && item.max) {
          var inputProps = {
            pattern:
              "^((([]*))|(([+-]))|([+-]?((0|([1-9][0-9]*))((.[0-9]+)|(.+))?)))$",
            min: item.min.toString(),
            max: item.max.toString(),
            step: item.step ? item.step.toString() : "1",
          };
          indents.push(
            <Grid item>
              <TextField
                id={index}
                onChange={this.handleNumberFieldOnChange}
                value={this.state.values[index].value}
                style={staticStyles.textField}
                label={item.label != null ? item.label.replace("<br>", "") : ""}
                variant="outlined"
                required={item.required}
                inputProps={inputProps}
              />
            </Grid>
          );
        } else {
          var inputProps2 = {
            pattern:
              "^((([]*))|(([+-]))|([+-]?((0|([1-9][0-9]*))((.[0-9]+)|(.+))?)))$",
          }; //inputMode: "numeric" as "numeric"
          indents.push(
            <Grid item>
              <TextField
                id={index}
                onChange={this.handleNumberFieldOnChange}
                value={this.state.values[index].value}
                style={staticStyles.textField}
                label={item.label != null ? item.label.replace("<br>", "") : ""}
                variant="outlined"
                inputProps={inputProps2}
                required={item.required}
              />
            </Grid>
          );
        }
      } else if (item.type === "select") {
        if (this.state.values[index] == null) {
          this.state.values.push({
            label: item.label,
            index: index,
            type: item.type,
            value: item.value ? item.value : null,
            multiple: item.multiple,
            default: null,
            required: item.required,
          });
        }
        var choices: any[] = [];
        var defaultVal: any[] = this.state.values[index].default;
        var isSet = this.state.values[index].default === null ? false : true;

        this.state.values[index].value.map((choice, valIndex) => {
          if (!isSet) {
            if (item.multiple && choice.selected) {
              if (defaultVal == null) {
                defaultVal = [choice.value];
              } else {
                defaultVal.push(choice.value);
              }
            } else if (!item.mutiple && choice.selected) {
              defaultVal = [choice.value];
            }
          }
          choices.push({
            value: choice.value,
            label: choice.label,
            index: index,
            selected: choice.selected,
            multiple: item.multiple,
          });
        });
        this.state.values[index].default = defaultVal;
        if (item.multiple == true) {
          indents.push(
            <Grid style={staticStyles.selectBoxRender}>
              <FormControl>
                <InputLabel id={`select-element-${index}`}>
                  {item.label != null ? item.label.replace("<br>", "") : ""}
                </InputLabel>
                <SelectUI
                  id={`select-element-${index}`}
                  style={staticStyles.selectFieldStyle}
                  multiple
                  value={this.state.values[index].default}
                  onChange={(e) => this.handleChangeFormRender(e, index)}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {choices.map((chc, valIndex) => (
                    <MenuItem id={index} key={chc.label} value={chc.value}>
                      {chc.label}
                    </MenuItem>
                  ))}
                </SelectUI>
              </FormControl>
            </Grid>
          );
        } else {
          indents.push(
            <Grid style={staticStyles.selectBoxRender}>
              <FormControl>
                <InputLabel id={`select-element-${index}`}>
                  {item.label != null ? item.label.replace("<br>", "") : ""}
                </InputLabel>
                <SelectUI
                  id={`select-element-${index}`}
                  style={staticStyles.selectFieldStyle}
                  value={this.state.values[index].default}
                  onChange={(e) => this.handleChangeFormRender(e, index)}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {choices.map((chc, valIndex) => (
                    <MenuItem id={index} key={chc.label} value={chc.value}>
                      {chc.label}
                    </MenuItem>
                  ))}
                </SelectUI>
              </FormControl>
            </Grid>
          );
        }
        this.state.values[index] = {
          index: index,
          label: item.label,
          type: item.type,
          value: choices != [] ? choices : null,
          multiple: item.multiple,
          default: defaultVal,
          required: item.required,
        };
      } else if (item.type === "checkbox-group") {
        if (this.state.values[index] == null) {
          this.state.values.push({
            label: item.label,
            index: index,
            type: item.type,
            value: item.value ? item.value : null,
            multiple: item.multiple,
            default: null,
            required: item.required,
          });
        }
        var choices: any[] = [];
        var isSet = this.state.values[index] == null ? false : true;
        let tempArr: any[] = this.state.values;
        this.state.values[index].value.map((choice, valIndex) => {
          if (!isSet) {
            choices.push({
              value: choice.value,
              label: choice.label,
              index: index,
              selected: choice.selected,
              multiple: item.multiple,
            });
          } else {
            choices.push({
              value: choice.value,
              label: choice.label,
              index: index,
              selected: tempArr[index].value[valIndex].selected,
            });
          }
        });
        indents.push(
          <Grid style={staticStyles.selectBoxRender}>
            <Typography id={`select-element-${index}`}>
              {item.label != null ? item.label.replace("<br>", "") : ""}
            </Typography>
            {choices.map((chc, valIndex) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chc.selected}
                    name={chc.value}
                    onChange={(e) =>
                      this.handleChangeCheckbox(e, index, valIndex)
                    }
                  />
                }
                label={chc.label}
              />
            ))}
          </Grid>
        );

        this.state.values[index] = {
          index: index,
          label: item.label,
          type: item.type,
          value: choices != [] ? choices : null,
          multiple: item.multiple,
          default: null,
          required: item.required,
        };
      } else if (item.type === "file") {
        var accept = {};
        var text = "Zmień plik";
        var type = "file";
        if (item.dataType === "image") {
          accept = { accept: "image/png, image/jpeg" };
          text = "Zmień obrazek";
          type = "image";
        } else if (item.dataType === "pdf") {
          accept = { accept: "application/pdf" };
          text = "Zmień plik PDF";
          type = "pdf";
        }
        this.state.values.push({
          label: item.label,
          index: index,
          type: item.type,
          dataType: type,
          fileName: item.fileName,
          file: item.file,
          required: item.required,
          changed: false,
          link: item.link,
        });
        indents.push(
          <Paper style={staticStyles.paperOutlineLogo}>
            <label style={staticStyles.labelInfoLogo}>{text}</label>
            <label style={{ display: "inline-flex" }}>
              <div style={{ display: "table" }}>
                <Paper
                  variant="outlined"
                  style={staticStyles.buttonSecondary}
                  color="primary"
                >
                  <div style={{ marginTop: "0.3em" }}>Wybierz plik...</div>
                  <Input
                    style={staticStyles.inputValues}
                    fullWidth
                    type="file"
                    inputProps={accept}
                    onChange={(e) => this.handleChangeFile(e, index)}
                  />
                </Paper>
              </div>
              <div style={{ display: "table" }}>
                <div style={staticStyles.fileNameText}>
                  {this.state.values[index].fileName}
                </div>
              </div>
            </label>
          </Paper>
        );
      }
      indexTemp++;
    });
    if (this.state.values.length > indexTemp) {
      for (let i = indexTemp; i < this.state.values.length; i++) {
        this.state.values.splice(i, 1);
      }
    }
    return <>{indents}</>;
  }

  render() {
    const {
      showNotify,
      showNotifyBeta,
      showNotifyError,
      showNotifyErrorNameExist,
      showNotifyErrorFieldEmpty,
      styles,
    } = this.state;
    const useStyles = {
      snackbar: {
        width: "100%",
      },
      spinner: {
        flex: 1,
        alignSelf: "center",
      },
    };
    const NOTIFY = (
      <div>
        <Snackbar
          open={showNotify}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zmiany zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={showNotifyBeta}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Nowy szablon zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={showNotifyError}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERRORNAMEEXIST = (
      <div>
        <Snackbar
          open={showNotifyErrorNameExist}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Formularz o wybranej nazwie już istnieje, wybierz inną nazwę
          </Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROREMPTYFIELD = (
      <div>
        <Snackbar
          open={showNotifyErrorFieldEmpty}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Jedno z pół oznaczone jako wymagane (*) jest puste
          </Alert>
        </Snackbar>
      </div>
    );
    while (this.state.isLoading) {
      this.GetFormFromApi();
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader
              size={45}
              css={this.state.styles.spinner}
              color={"#01C09E"}
            />
          </Grid>
        </Grid>
      );
    }
    if (this.state.offerNotFound) {
      return (
        <div>
          <Snackbar
            open={this.state.offerNotFound}
            autoHideDuration={3000}
            style={staticStyles.snackbar}
            onClose={this.handleClose}
          >
            <Alert severity="error">Oferta nie znaleziona</Alert>
          </Snackbar>
        </div>
      );
    } else {
      return (
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography style={staticStyles.titleOffer}>
              Nazwa oferty: {this.state.offer_name}
            </Typography>
            <TextField
              required
              label="Nowa nazwa oferty"
              defaultValue={this.state.offer_name}
              onChange={(e) => this.setState({ offer_name: e.target.value })}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={7}>
            {this.RenderOffer()}
          </Grid>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Button
                id="saveForm"
                variant="contained"
                color="primary"
                onClick={this.handleClickSave}
              >
                Zapisz ofertę
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                id="saveForm"
                variant="contained"
                color="primary"
                onClick={this.handleClickSaveNew}
              >
                Zapisz jako nową
              </Button>
            </Grid>
          </Grid>
          {NOTIFY}
          {NOTIFYBETA}
          {NOTIFYERROR}
          {NOTIFYERRORNAMEEXIST}
          {NOTIFYERROREMPTYFIELD}
        </Grid>
      );
    }
  }
}

export default ShowOffer;
