//import $ from "jquery";
import React, { Component, createRef } from "react";
//import { useAuthenticated } from 'react-admin';

import Button from "@material-ui/core/Button";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import ClipLoader from "react-spinners/ClipLoader";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import { format } from "date-fns";
import bcrypt from "bcryptjs";

import Grid from "@material-ui/core/Grid";
import { API_URL } from "../config";
// window.jQuery = $;
// window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const useStyles = makeStyles({
  Datagrid: {
    cursor: "pointer",
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
});
const staticStyles = {
  titleGroup: {
    marginTop: "1.5em",
    marginBottom: "1em",
    fontSize: "1.8em",
    fontWeight: "bold" as "bold",
  },
  createGroupInfo: {
    color: "#aaa",
    marginTop: "3em",
    marginBottom: "1em",
  },
  groupImage: {
    marginLeft: "3rem",
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    marginBottom: "1em",
  },
  groupTitle: {
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal" as "normal",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    alignSelf: "center",
    marginLeft: "2em",
  },
  buttonPrimary: {
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
    float: "inline-end" as "inline-end",
    width: "14em",
  },
  buttonSecondary: {
    width: "9rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    height: "1.8em",
    textAlign: "center" as "center",
    marginTop: "0.8em",
    marginLeft: "0.6em",
  },
  buttonSecondaryError: {
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
    float: "inline-end" as "inline-end",
    width: "14em",
  },
  imageFieldResize: {
    width: "8rem",
    height: "8em",
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
  paperStyleInput: {
    borderColor: "rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  labelInfo: {
    display: "inline-block",
    backgroundColor: "#01C09E",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: "bold" as "bold",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    padding: "0.5rem",
    borderRadius: "0.3rem",
    cursor: "pointer",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    marginLeft: "0.5rem",
    boxShadow:
      " 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  inputValues: {
    display: "none",
  },
  labelInfoLogo: {
    position: "absolute" as "absolute",
    transformOrigin: "top left",
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0",
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal" as "normal",
    lineHeight: "1",
    letterSpacing: "0.00938em",
    backgroundColor: "#fff",
    paddingLeft: "0.3em",
    width: "5.5em",
  },
  paperOutlineLogo: {
    height: "3.2em",
    borderColor: "rgba(0, 0, 0, 0.25)",
    marginTop: "1.8em",
  },
  fileNameText: {
    marginTop: "1em",
    marginLeft: "1em",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.57)",
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface CreateGroupNotify {
  isLoading: boolean;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
  showNotifyEmpty: boolean;
  logo: string;
  styles: any;
  fileName: string;
  image_link: string;
  creator_id: string;
  group_name: string;
  errorGroupName: boolean;
}

class CreateGroup extends Component<CreateGroupNotify> {
  state: CreateGroupNotify;
  constructor(props) {
    super(props);
    this.state = {
      // id: this.props.match.params.id,
      isLoading: true,
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      showNotifyEmpty: false,
      logo: "",
      fileName: "",
      image_link: "",
      styles: useStyles,
      creator_id: "",
      group_name: "",
      errorGroupName: false,
    };
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeIMG = this.handleChangeIMG.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  fb = createRef();
  handleChange = (e: any) => {
    this.setState({
      logo: e.target.files[0],
    });
  };
  sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  handleClickSave() {
    if (this.state.group_name === "") {
      this.setState({ errorGroupName: true });
    } else {
      const formData = new FormData();
      var logo = (document.querySelector("#image_link") as HTMLInputElement)!;
      var user = localStorage.getItem("id")!;
      if (logo.files != null) {
        formData.append("logo", logo.files[0]);
      }
      if (user != null) {
        formData.append("creator_id", user);
      }
      if (user != null) {
        formData.append("group_name", this.state.group_name);
      }

      axios
        .post(API_URL + "/creategroup", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bcrypt.hashSync(
              `creategroup${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        })
        .then((response) => {
          if (response.data.code === 104) {
            this.setState({ showNotify: true });
            this.sleep(2000).then(() => {
              window.location.href = "/getgrouplist";
            });
          } else {
            this.setState({ showNotifyError: true });
          }
        })
        .catch(function (error) {});
    }
  }

  handleClose(event: any, reason: any) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showNotify: false });
    this.setState({ showNotifyBeta: false });
    this.setState({ showNotifyError: false });
  }
  handleChangeIMG(event: any) {
    if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({ showNotifyErrorImage: true });
    } else if (event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({
        image_link: URL.createObjectURL(event.target.files[0]),
      });
      this.setState({ fileName: event.target.files[0].name });
    }
  }
  handleTextChange(event: any) {
    // var correct = (event.target.value === "" && this.state.errorGroupName) ? true : false
    this.setState({ group_name: event.target.value });
  }
  returnImage() {
    if (this.state.image_link !== "") {
      return (
        <>
          <img
            alt=""
            src={this.state.image_link}
            style={staticStyles.imageFieldResize}
          />
          <br />
        </>
      );
    } else return <></>;
  }
  valueGroupValidation() {
    if (this.state.errorGroupName) {
      return (
        <TextField
          error
          variant="outlined"
          margin="normal"
          fullWidth
          helperText="Pole nie może być puste"
          name="group_name"
          value={this.state.group_name}
          label="Nazwa grupy"
          type="text"
          id="group_name"
          autoComplete="group_name"
          onChange={this.handleTextChange}
        />
      );
    } else {
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="group_name"
          value={this.state.group_name}
          label="Nazwa grupy"
          type="text"
          id="group_name"
          autoComplete="group_name"
          onChange={this.handleTextChange}
        />
      );
    }
  }
  returnButton() {
    if (this.state.group_name === "") {
      return (
        <Button
          disabled
          id="saveForm"
          variant="contained"
          style={staticStyles.buttonSecondaryError}
          color="primary"
        >
          Zapisz
        </Button>
      );
    } else {
      return (
        <Button
          id="saveForm"
          variant="contained"
          style={staticStyles.buttonPrimary}
          color="primary"
          onClick={this.handleClickSave}
        >
          Zapisz
        </Button>
      );
    }
  }

  render() {
    const NOTIFY = (
      <div>
        <Snackbar
          open={this.state.showNotify}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zmiany zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={this.state.showNotifyBeta}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Nowy szablon zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={this.state.showNotifyError}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );
    const NOTEMPTY = (
      <div>
        <Snackbar
          open={this.state.showNotifyEmpty}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">Pole z nazwą grupy nie może być puste</Alert>
        </Snackbar>
      </div>
    );

    while (this.state.isLoading) {
      this.setState({ isLoading: false });
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader
              size={45}
              css={this.state.styles.spinner}
              color={"#01C09E"}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <div>
        <Grid item xs={12} md={6}>
          <Typography style={staticStyles.titleGroup}>Nowa grupa</Typography>
          <Typography style={staticStyles.createGroupInfo}>
            uzupełnij poniższe pola
          </Typography>
          <form>
            {this.valueGroupValidation()}
            {this.returnImage()}
            <Paper variant="outlined" style={staticStyles.paperOutlineLogo}>
              <label style={staticStyles.labelInfoLogo}>Logo grupy</label>
              <label style={{ display: "inline-flex" }}>
                <div style={{ display: "table" }}>
                  <Paper
                    variant="outlined"
                    style={staticStyles.buttonSecondary}
                    color="primary"
                  >
                    <div style={{ marginTop: "0.3em" }}>Wybierz plik...</div>
                    <Input
                      style={staticStyles.inputValues}
                      fullWidth
                      id="image_link"
                      type="file"
                      inputProps={{ accept: "image/png, image/jpeg" }}
                      onChange={this.handleChangeIMG}
                    />
                  </Paper>
                </div>
                <div style={{ display: "table" }}>
                  <div style={staticStyles.fileNameText}>
                    {this.state.fileName}
                  </div>
                </div>
              </label>
            </Paper>
            {this.returnButton()}
          </form>
          {NOTIFY}
          {NOTIFYBETA}
          {NOTIFYERROR}
          {NOTEMPTY}
        </Grid>
      </div>
    );
  }
}

export default CreateGroup;
