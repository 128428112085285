import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

// STYLE
//import Avatar from '@material-ui/core/Avatar';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import merge from "lodash/merge";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import createPalette from "@material-ui/core/styles/createPalette";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import ReactPhoneInput from "react-phone-input-mui";
import { API_URL } from "../config";

const staticStyles = {
  logoTypeTemporary: {
    fontWeight: "bold" as "bold",
    color: "#01C09E",
  },
  linkStyleNotHover: {
    textDecoration: "none",
    fontWeight: "bold" as "bold",
    color: "#01C09E",
    cursor: "pointer" as "pointer",
    fontSize: "0.9em",
  },
  linkStyleHover: {
    textDecoration: "none",
    fontWeight: "bold" as "bold",
    color: "#00deb6",
    cursor: "pointer" as "pointer",
    fontSize: "0.9em",
  },
  buttonPrimary: {
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
  },
  buttonSecondary: {
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
  },
  acceptTermsOfService: {
    marginTop: "0.5em",
  },
  errorMessageReset: {
    color: "red",
  },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 400,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#01C09E",
  },
  container: {
    minHeight: "88vh",
    display: "flex",
    alignItems: "center",
  },
  logoTypeTemporary: {
    fontWeight: "bold" as "bold",
    color: "#01C09E",
  },
}));

var tokenData = localStorage.getItem("token");
var isDownloaded = false;

export default function MyLoginPage({ theme }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hover, setHover] = useState(false);
  const [type, setType] = useState("login");
  const [nextStep, setNextStep] = useState("");
  const [hover2, setHover2] = useState(false);
  const [checked, setChecked] = useState(false);
  const [registerValue, setRegisterValue] = useState(0);

  //Dla checktoken state
  const [emailRegister, setEmailRegister] = useState("");
  const [resetPageEmail, setResetPageEmail] = useState("");
  const [userTypeRegister, setUserTypeRegister] = useState("");
  const [offerToken, setOfferToken] = useState("");
  const [tokenPass, setTokenPass] = useState("");

  const login = useLogin();

  if (localStorage.getItem("auth")) {
    window.location.href = `/showoffer/${tokenData}`;
  }
  if (tokenData !== "undefined" && !isDownloaded) {
    axios
      .get(API_URL + `/checktoken/${localStorage.getItem("token")}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `checktoken${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 142) {
          setType("resetPassPass");
          setTokenPass(response.data.token);
        }
        if (response.data.code === 143) {
          setNextStep("group");
          //Group redirect
        }
        if (response.data.code === 144) {
          setType("login");
          setNextStep("offer");
          setEmailRegister(response.data.data.email);
          setUserTypeRegister(response.data.data.user_type);
          setOfferToken(response.data.data.token);
        }
        if (response.data.code === 145) {
          setType("login");
        }
        if (response.data.code === 146) {
          setType("register");
          setEmailRegister(response.data.data.email);
          setUserTypeRegister(response.data.data.user_type);
        }
      })
      .catch(function (error) {});
    isDownloaded = true;
  }
  const notify = useNotify();
  const classes = useStyles();
  var linkStyle = staticStyles.linkStyleNotHover;
  var linkStyle2 = staticStyles.linkStyleNotHover;
  const { register, handleSubmit, watch, errors, control } = useForm({
    mode: "onBlur",
  });
  let pwd = watch("password");
  const watchShowUser1 = watch("User1", false);
  const watchShowUser2 = watch("User2", false);

  // const options = [
  //     { value: "2", label: "Jestem sprzedawcą energii", name: "User1" },
  //     { value: "3", label: "Jestem odbiorca energii", name: "User2" }
  // ]
  const handleOptions = () => {
    if (userTypeRegister === "1") {
      return (
        <option key={2} value={2}>
          Jestem sprzedawcą energii
        </option>
      );
    } else if (userTypeRegister === "2") {
      return (
        <option key={3} value={3}>
          Jestem odbiorca energii
        </option>
      );
    } else {
      return (
        <>
          <option key={2} value={2}>
            Jestem sprzedawcą energii
          </option>
          <option key={3} value={3}>
            Jestem odbiorca energii
          </option>
        </>
      );
    }
  };
  const handleClickRegister = (e: any) => {
    setType("register");
    setChecked(false);
  };
  const returnMoreInfo = () => {
    var postCodeRegx = /^\d{2}-\d{3}$/;
    if (registerValue == 3 || userTypeRegister === "2") {
      return (
        <>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="company_name"
            label="Nazwa firmy"
            type="text"
            id="company_name"
            autoComplete="company_name"
            inputRef={register}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="nip_num"
            label="Numer NIP"
            type="number"
            id="nip_num"
            inputProps={{
              max: "9999999999",
            }}
            autoComplete="nip_num"
            inputRef={register}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="city_name"
            label="Miasto"
            type="text"
            id="city_name"
            autoComplete="city_name"
            inputRef={register}
          />
          <Controller
            defaultValue=""
            variant="outlined"
            margin="normal"
            fullWidth
            name="post_code"
            label="Kod pocztowy (12-123)"
            type="text"
            control={control}
            id="post_code"
            autoComplete="post_code"
            inputRef={register}
            rules={{
              validate: (value) =>
                postCodeRegx.test(value) === true ||
                value === "" ||
                "Nieprawidłowy format kodu pocztowego",
            }}
            as={<TextField />}
          />
          {errors.post_code && (
            <p style={staticStyles.errorMessageReset}>
              {" "}
              {errors.post_code.message}
            </p>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };
  const handleClickReset = (e: any) => {
    setType("resetPass");
    setChecked(false);
  };
  const handleClickLogin = (e: any) => {
    setType("login");
    setChecked(false);
  };
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const onSubmitReset = (data) => {
    var payload = {
      email: resetPageEmail,
    };
    axios
      .post(API_URL + "/resetpasswdlink", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `resetpasswdlink${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 102) {
          localStorage.removeItem("token");
          notify("Wysłano maila");
          sleep(2000).then(() => {
            setType("login");
          });
        }
      })
      .catch(function (error) {
        if (error.toString() === "User already exists")
          notify("Błędny email lub hasło");
      });
  };
  const onSubmitResetPass = (data) => {
    var payload = {
      token: localStorage.getItem("token"),
      password: data.password,
    };
    axios
      .post(API_URL + "/access", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `access${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 108) {
          notify("Hasło zresetowane");
          localStorage.removeItem("token");
          sleep(2000).then(() => {
            setType("login");
          });
        }
      })
      .catch(function (error) {
        notify("Coś poszło nie tak");
      });
  };
  const onSubmit = (data) => {
    if (checked) {
      var emailReg;
      if (emailRegister) {
        emailReg = emailRegister;
      } else emailReg = data.email;
      //To be done:check for empty values before hitting submit
      if (registerValue == 3 || userTypeRegister === "2") {
        var payload = {
          firstname: data.first_name,
          lastname: data.last_name,
          email: emailReg,
          password: data.password,
          user_type: data.user_type,
          phone: data.phone,
          company_name: data.company_name,
          nip_num: data.nip_num,
          post_code: data.post_code,
          city_name: data.city_name,
        };
        axios
          .post(API_URL + "/register", payload, {
            headers: {
              Authorization: `Bearer ${bcrypt.hashSync(
                `register${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
                10
              )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
            },
          })
          .then((response) => {
            if (response.data.code === 101) {
              notify("Dodano użytkownika");
              sleep(2000).then(() => {
                window.location.href = "/login";
              });
            }
          })
          .catch(function (error) {
            if (error.toString() === "User already exists")
              notify("Błędny email lub hasło");
          });
      } else {
        var payload2 = {
          firstname: data.first_name,
          lastname: data.last_name,
          email: emailReg,
          password: data.password,
          user_type: data.user_type,
          phone: data.phone,
        };
        axios
          .post(API_URL + "/register", payload2, {
            headers: {
              Authorization: `Bearer ${bcrypt.hashSync(
                `register${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
                10
              )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
            },
          })
          .then((response) => {
            if (response.data.code === 101) {
              notify("Dodano użytkownika");
              sleep(2000).then(() => {
                window.location.href = "/login";
              });
            }
          })
          .catch(function (error) {
            if (error.toString() === "User already exists")
              notify("Błędny email lub hasło");
          });
      }
    }
  };
  const handleLogin = (e: any) => {
    e.preventDefault();
    e.nextStep = nextStep;
    login({ email: email, password: password, prev: e }).catch(() =>
      notify("Błędny email lub hasło")
    );
  };
  const toggleHover = () => {
    setHover(!hover);
  };
  const toggleHover2 = () => {
    setHover2(!hover);
  };

  const palette = createPalette(
    merge({}, theme.palette, {
      overrides: {
        MuiFormLabel: {
          root: {
            "&$focused": {
              color: "tomato",
              fontWeight: "bold",
            },
          },
          focused: {},
        },
      },
    })
  );

  const rawTheme = {
    palette,
  };
  const theme2 = createMuiTheme(merge({}, theme, rawTheme));
  const ResetPageReturn = () => {
    return (
      <React.Fragment>
        <Container className={classes.container} component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography className={classes.logoTypeTemporary} variant="h5">
              SELECTRIN
            </Typography>
            <form className={classes.form} noValidate name="form">
              <MuiThemeProvider theme={theme2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                  value={resetPageEmail}
                  onChange={(e) => setResetPageEmail(e.target.value)}
                />
              </MuiThemeProvider>

              <Button
                type="submit"
                fullWidth
                style={staticStyles.buttonPrimary}
                variant="contained"
                onClick={handleSubmit(onSubmitReset)}
              >
                Resetuj Hasło
              </Button>
            </form>
            <Grid container>
              <Grid item xs>
                <Typography
                  style={linkStyle}
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                  onClick={handleClickLogin}
                >
                  {" "}
                  {/*to={{ pathname: "/resetPass"}}>*/}
                  {"Zaloguj się"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={linkStyle2}
                  onMouseEnter={toggleHover2}
                  onMouseLeave={toggleHover2}
                  onClick={handleClickRegister}
                >
                  {" "}
                  {/*}to={{ pathname: "/register"}}>*/}
                  {"Nie masz konta? Zarejestruj się"}
                </Typography>
              </Grid>
            </Grid>
            <Notification />
          </div>
        </Container>
      </React.Fragment>
    );
  };

  const ResetPassPageReturn = () => {
    var strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$(:'";_+=!.#,%*?)&])[A-Za-z\d@$(:'";_+=!.#,%*?)&]{8,}$/i;
    return (
      <React.Fragment>
        <Container className={classes.container} component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography className={classes.logoTypeTemporary} variant="h5">
              SELECTRIN
            </Typography>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmitResetPass)}
            >
              <MuiThemeProvider theme={theme2}>
                <Controller
                  defaultValue=""
                  name="password"
                  control={control}
                  type="password"
                  variant="outlined"
                  label="Hasło"
                  margin="normal"
                  required
                  fullWidth
                  rules={{
                    required: "Pole jest puste",
                    validate: (value) =>
                      strongRegex.test(value) === true ||
                      "Co najmniej osiem znaków, co najmniej jedna wielka litera, jedna mała litera, jedna cyfra i jeden znak specjalny",
                  }}
                  as={<TextField />}
                />
                {errors.password && (
                  <p style={staticStyles.errorMessageReset}>
                    {errors.password.message}
                  </p>
                )}

                <Controller
                  defaultValue=""
                  name="password_repeat"
                  type="password"
                  variant="outlined"
                  label="Powtórz hasło"
                  margin="normal"
                  required
                  fullWidth
                  control={control}
                  rules={{
                    required: "Pole jest puste",
                    validate: (value) => value === pwd || "Hasła nie pasują",
                  }}
                  as={<TextField />}
                />
                {errors.password_repeat && (
                  <p style={staticStyles.errorMessageReset}>
                    {" "}
                    {errors.password_repeat.message}
                  </p>
                )}
              </MuiThemeProvider>

              <Button
                type="submit"
                fullWidth
                style={staticStyles.buttonPrimary}
                variant="contained"
              >
                Resetuj Hasło
              </Button>
            </form>
            <Grid container>
              <Grid item xs>
                <Typography
                  style={linkStyle}
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                  onClick={handleClickLogin}
                >
                  {" "}
                  {/*to={{ pathname: "/resetPass"}}>*/}
                  {"Zaloguj się"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={linkStyle2}
                  onMouseEnter={toggleHover2}
                  onMouseLeave={toggleHover2}
                  onClick={handleClickRegister}
                >
                  {" "}
                  {/*}to={{ pathname: "/register"}}>*/}
                  {"Nie masz konta? Zarejestruj się"}
                </Typography>
              </Grid>
            </Grid>
            <Notification />
          </div>
        </Container>
      </React.Fragment>
    );
  };
  const handleChangeCheckBox = (event: any) => {
    setChecked(!checked);
  };
  const handleOnChangeSelect = (event: any) => {
    setRegisterValue(event.target.value);
  };

  const RegisterPageReturn = () => {
    if (hover) {
      linkStyle = staticStyles.linkStyleHover;
    } else {
      linkStyle = staticStyles.linkStyleNotHover;
    }
    return (
      <div>
        <Container className={classes.container} component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
                          <LockOutlinedIcon />
                      </Avatar> */}
            <Typography className={classes.logoTypeTemporary} variant="h5">
              SELECTRIN
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MuiThemeProvider theme={theme2}>
                <InputLabel id="user_type">Typ użytkownika</InputLabel>
                <Select
                  native
                  labelId="user_type"
                  id="user_type"
                  required
                  name="user_type"
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                  onChange={handleOnChangeSelect}
                >
                  {handleOptions()}
                </Select>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="first_name"
                  label="Imię"
                  type="text"
                  id="first_name"
                  autoComplete="imie"
                  inputRef={register}
                  required
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="last_name"
                  label="Nazwisko"
                  type="text"
                  id="last_name"
                  autoComplete="last_name"
                  inputRef={register}
                  required
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="email_register"
                  label="E-mail"
                  type="email"
                  id="email_register"
                  autoComplete="email_register"
                  inputRef={register}
                  required
                  value={emailRegister}
                  onChange={(e) => setEmailRegister(e.target.value)}
                />
                <Controller
                  as={
                    <ReactPhoneInput
                      component={TextField}
                      inputExtraProps={{
                        margin: "normal",
                        variant: "outlined",
                        name: "phone",
                        id: "phone",
                        autoComplete: "phone",
                        required: true,
                        placeholder: "test",
                      }}
                      defaultCountry={"pl"}
                      fullWidth
                      placeholder="test"
                    />
                  }
                  name="phone"
                  control={control}
                  defaultValue=""
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Hasło"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  inputRef={register({
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$(:'";_+=!.#,%*?)&])[A-Za-z\d@$(:'";_+=!.#,%*?)&]{8,}$/i,
                      message:
                        "Co najmniej osiem znaków, co najmniej jedna wielka litera, jedna mała litera, jedna cyfra i jeden znak specjalny",
                    },
                  })}
                  required
                />
                {errors.password && (
                  <p style={staticStyles.errorMessageReset}>
                    {errors.password.message}
                  </p>
                )}
                {returnMoreInfo()}
                <Grid container direction="row">
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeCheckBox}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <Typography style={staticStyles.acceptTermsOfService}>
                    Akceptuję Terms of Service
                  </Typography>
                </Grid>
              </MuiThemeProvider>
              <Button
                disabled={!checked}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={
                  checked
                    ? staticStyles.buttonPrimary
                    : staticStyles.buttonSecondary
                }
              >
                Zarejestruj
              </Button>
            </form>
            <Grid item>
              <Link
                style={linkStyle}
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
                onClick={handleClickLogin}
                to={{ pathname: "/login" }}
              >
                {"Masz już konto? Zaloguj się"}
              </Link>
            </Grid>
            <Notification />
          </div>
        </Container>
      </div>
    );
  };

  const LoginPageReturn = () => {
    if (hover) {
      linkStyle = staticStyles.linkStyleHover;
    } else {
      linkStyle = staticStyles.linkStyleNotHover;
    }
    if (hover2) {
      linkStyle2 = staticStyles.linkStyleHover;
    } else {
      linkStyle2 = staticStyles.linkStyleNotHover;
    }
    return (
      <React.Fragment>
        <Container className={classes.container} component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography className={classes.logoTypeTemporary} variant="h5">
              SELECTRIN
            </Typography>
            <form className={classes.form} noValidate name="form">
              <MuiThemeProvider theme={theme2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </MuiThemeProvider>

              <Button
                type="submit"
                fullWidth
                style={staticStyles.buttonPrimary}
                variant="contained"
                onClick={handleLogin}
              >
                Zaloguj się
              </Button>
            </form>
            <Grid container>
              <Grid item xs>
                <Typography
                  style={linkStyle}
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                  onClick={handleClickReset}
                >
                  {" "}
                  {/*to={{ pathname: "/resetPass"}}>*/}
                  {"Zapomniałeś hasła?"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={linkStyle2}
                  onMouseEnter={toggleHover2}
                  onMouseLeave={toggleHover2}
                  onClick={handleClickRegister}
                >
                  {" "}
                  {/*}to={{ pathname: "/register"}}>*/}
                  {"Nie masz konta? Zarejestruj się"}
                </Typography>
              </Grid>
            </Grid>
            <Notification />
          </div>
        </Container>
      </React.Fragment>
    );
  };
  if (type === "login") {
    return <>{LoginPageReturn()}</>;
  } else if (type === "register") {
    return <>{RegisterPageReturn()}</>;
  } else if (type === "resetPass") {
    return <>{ResetPageReturn()}</>;
  } else if (type === "resetPassPass") {
    return <>{ResetPassPageReturn()}</>;
  }
}
