import jwt_decode from "jwt-decode";
import { AuthProvider } from "react-admin";
import { API_URL } from "./config";
import { getRoleFromPrivilegesToken } from "./utils";

const url = window.location;
var acessToken;

(() => {
  if (
    localStorage.getItem("token") === "null" ||
    localStorage.getItem("token") === "undefined"
  ) {
    localStorage.removeItem("token");
  }
})();

const access_token = () => {
  if (localStorage.getItem("token")) {
    return localStorage.getItem("token");
  } else if (new URLSearchParams(url.search).get("token")) {
    acessToken = new URLSearchParams(url.search).get("token");
    return acessToken;
  }
};

localStorage.setItem("token", access_token());

const authProvider: AuthProvider = {
  login: ({ email, password, prev }) => {
    const request = new Request(`${API_URL}/login`, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ data }) => {
        localStorage.setItem("privileges", data.privileges);
        const role = getRoleFromPrivilegesToken(data.privileges);
        localStorage.setItem("role", role);
        localStorage.setItem("id", data.id);
        localStorage.setItem("fullName", data.fullName);
        localStorage.setItem("avatar_link", data.avatar_link);
        localStorage.setItem("auth", JSON.stringify(data));
        var token = localStorage.getItem("token");
        localStorage.removeItem("token");
        if (token == null || token === "undefined") {
          window.location.href = `/`;
        } else {
          if (prev.nextStep === "group") {
            window.location.href = `/getdashboards/${token}`;
          }
          if (prev.nextStep === "offer") {
            window.location.href = `/showoffer/${token}`;
          } else {
            window.location.href = `/`;
          }
        }
      });
  },
  logout: () => {
    localStorage.removeItem("privileges");
    localStorage.removeItem("fullName");
    localStorage.removeItem("avatar_link");
    localStorage.removeItem("id");
    localStorage.removeItem("auth");
    localStorage.removeItem("token");
    window.location.href = `/login`;
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    if (
      localStorage.getItem("token") !== "null" ||
      localStorage.getItem("token") !== "undefined"
    ) {
      return localStorage.getItem("privileges")
        ? Promise.resolve({
            redirectTo: `/showoffer/${localStorage.getItem("token")}`,
          })
        : Promise.reject({ redirectTo: `/login` });
    } else
      return localStorage.getItem("privileges")
        ? Promise.resolve()
        : Promise.reject({ redirectTo: `/login` });
  },

  getPermissions: () => {
    const role = localStorage.getItem("privileges");
    return role
      ? Promise.resolve(role)
      : Promise.reject({ redirectTo: `/login` });
  },
  getIdentity: () =>
    Promise.resolve({
      id: localStorage.getItem("id"),
      fullName: localStorage.getItem("fullName"),
      avatar: localStorage.getItem("avatar_link"),
    }),
};

export default authProvider;
