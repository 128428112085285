// in src/App.js
import * as React from "react";
import {
  Admin,
  Resource,
  fetchUtils,
  AppBar,
  UserMenu,
  defaultTheme,
  Layout,
  MenuItemLink,
} from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import polishMessages from "ra-language-polish";

// import dashboard from './dashboard/dashboard';
import authProvider from "./authProvider";
import merge from "lodash/merge";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
//import createPalette from "@material-ui/core/styles/createPalette";
//import { makeStyles } from '@material-ui/core/styles';
import MyLoginPage from "./login/MyLoginPage";
import MyLogoutButton from "./login/MyLogoutButton";
import { GroupList } from "./groups/groups";
import { FormList, FormCreate } from "./formBuilder/getForm";
import { Offers } from "./offers/offers";
import { FieldLibrary } from "./library/fieldLibrary";
import DashboardData from "./dashboard/dashboard";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";

import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import Typography from "@material-ui/core/Typography";

import { UserList } from "./users/users";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";

import customRoutes from "./customRoutes";
import { stringify } from "query-string";
//import { withStyles } from '@material-ui/core/styles';
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import axios from "axios";
import { API_URL } from "./config";
import {
  getRoleFromPrivilegesToken,
  getUserRole,
  getUserRoleName,
} from "./utils";

declare global {
  interface Window {
    jQuery: any;
    $: any;
  }
}

const messages = {
  pl: polishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "pl", {
  allowMissing: true,
});

// const useStyles = makeStyles({
//     avatar: {
//         height: 30,
//         width: 30,
//     },
//     background: {
//         backgroundColor: "#01C09E",
//     }
// });

const staticStyles = {
  avatar: {
    height: 30,
    width: 30,
  },
  userTitleInfo: {
    fontSize: "0.9em",
    fontWeight: "bold" as "bold",
    marginRight: "3vw",
    color: "#fff",
  },
  userRoleInfo: {
    fontSize: "0.7em",
    marginRight: "3vw",
    color: "#fff",
  },
};

const MyCustomIcon = () => {
  if (localStorage.getItem("avatar_link") === null) {
    return <Avatar style={staticStyles.avatar} />;
  } else {
    return (
      <Avatar
        style={staticStyles.avatar}
        src={localStorage.getItem("avatar_link")!}
      />
    );
  }
};
const lightTheme = {
  palette: {
    background: {
      paper: "#fff",
      default: "#fff",
    },
    primary: {
      main: "#01C09E",
      contrastText: "#fff",
    },
    secondary: {
      main: "#01C09E",
    },
    sidebarColor: "#676767",
    menuItemsColor: "#4B6471",
  },
  overrides: {
    RaAppBar: {
      toolbar: {
        "& button": {
          "&:not(:nth-child(1))": {
            display: "none",
          },
          "&:not(:nth-child(0))": {
            color: "#fff",
          },
        },
        "& h6": {
          color: "#fff",
        },
      },
    },
    RaSidebar: {
      drawerPaper: {
        backgroundColor: "#F4F2F5",
        color: "#676767",
        height: "100%",
        boxShadow:
          "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
        marginRight: "2em",
      },
    },
  },
};
const palette = createMuiTheme(
  merge({}, defaultTheme.palette, {
    primary: {
      main: "#01C09E",
      contrastText: "#fff",
    },
    secondary: {
      main: "#01C09E",
    },
  })
);

const rawTheme = {
  palette,
};
const theme = createMuiTheme(merge({}, defaultTheme, rawTheme));

const MyUserMenu = (props) => {
  const userRole = getUserRoleName();

  return (
    <>
      <Grid>
        <Typography style={staticStyles.userTitleInfo}>
          {localStorage.getItem("fullName")}
        </Typography>
        <Typography style={staticStyles.userRoleInfo}>{userRole}</Typography>
      </Grid>
      <UserMenu {...props} icon={<MyCustomIcon />}>
        <MenuItemLink to="/profile" primaryText="Profil" />
      </UserMenu>
    </>
  );
};

const MyAppBar = (props) => {
  return <AppBar {...props} userMenu={<MyUserMenu />} />;
};

const MyLayout = (props) => {
  return <Layout {...props} theme={lightTheme} appBar={MyAppBar} />;
};

const history = require("history").createBrowserHistory();

const httpClient = (url, resource, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set(
    "Authorization",
    `Bearer ${bcrypt.hashSync(
      `${resource}${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
      10
    )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`
  );
  return fetchUtils.fetchJson(url, options);
  //     .then((response) => response.text().then(text => ({
  //         status: response.status,
  //         statusText: response.statusText,
  //         headers: response.headers,
  //         body: text,
  //     }))).catch((error) => ({
  //         status: 404,
  //         statusText: "Not found",
  //         headers: error.headers,
  //         body: error,
  //     }))
};
console.warn = () => {};
const dataProvider = {
  getList: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    var query;
    if (params.group_id === null) {
      query = {
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
        user_id: localStorage.getItem("id"),
        simple: JSON.stringify(1),
        sort: JSON.stringify([field, order]),
      };
    } else {
      query = {
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
        user_id: localStorage.getItem("id"),
        simple: JSON.stringify(1),
        sort: JSON.stringify([field, order]),
        group_id: params.group_id,
      };
    }

    const url = `${API_URL}/${resource}?${stringify(query)}`;
    return httpClient(url, resource).then(({ json }) => ({
      data: json.data,
      total: json.range,
    }));
  },

  getOne: (resource: any, params: any) => {
    const query = {
      user_id: params.id,
    };
    return httpClient(
      `${API_URL}/${resource}?${stringify(query)}`,
      resource
    ).then(({ headers, json }) => ({
      data: json,
    }));
  },

  getMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;
    return httpClient(url, resource).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;

    return httpClient(url, resource).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: (resource: any, params: any) => {
    const query = {
      user_id: params.id,
      form_id: JSON.stringify(1),
    };
    return httpClient(`${API_URL}/${resource}/${stringify(query)}`, resource, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${API_URL}/${resource}?${stringify(query)}`, resource, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource: any, params: any) => {
    return httpClient(`${API_URL}/${resource}`, resource, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource: any, params: any) =>
    httpClient(`${API_URL}/${resource}/${params.id}`, resource, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${API_URL}/${resource}?${stringify(query)}`, resource, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

const getResources = (permissions: string) => {
  const role = getRoleFromPrivilegesToken(permissions);

  const resources = [
    [
      "administrator",
      "group_creator",
      "group_manager",
      "uzytkownik_1",
    ].includes(role) ? (
      <Resource
        name="getdashboards"
        options={{ label: "Dashboard" }}
        list={DashboardData}
        icon={DashboardOutlinedIcon}
        permission={permissions}
      />
    ) : null,
    [
      "administrator",
      "group_creator",
      "group_manager",
      "uzytkownik_1",
      "uzytkownik_2",
    ].includes(role) ? (
      <Resource
        name="getgrouplist"
        options={{ label: "Grupy" }}
        list={GroupList}
        icon={GroupWorkOutlinedIcon}
        permission={permissions}
      />
    ) : null,
  ];

  if (role === "administrator") {
    resources.push(
      ...[
        <Resource
          name="getformList"
          options={{ label: "Formularze" }}
          list={FormList}
          icon={AssignmentOutlinedIcon}
        />,
        <Resource
          name="getofferlist"
          options={{ label: "Oferty" }}
          list={Offers}
          icon={StarBorderIcon}
        />,
        <Resource
          name="getinputlist"
          options={{ label: "Biblioteka pól" }}
          list={FieldLibrary}
          icon={DnsOutlinedIcon}
        />,
        <Resource
          name="getuserlist"
          options={{ label: "Użytkownicy" }}
          list={UserList}
          icon={GroupOutlinedIcon}
        />,
      ]
    );
  }

  if (role === "uzytkownik_1" || role === "uzytkownik_2") {
    resources.push(
      <Resource
        name="getofferlist"
        options={{ label: "Oferty" }}
        list={Offers}
        icon={StarBorderIcon}
      />
    );
  }

  if (role === "group_creator" || role === "group_manager") {
    resources.push(
      ...[
        <Resource
          name="getDashboard"
          options={{ label: "Dashboard" }}
          list={FormList}
          create={FormCreate}
          icon={DashboardOutlinedIcon}
        />,
        <Resource
          name="getformList"
          options={{ label: "Formularze" }}
          list={FormList}
          create={FormCreate}
          icon={AssignmentOutlinedIcon}
        />,
        <Resource
          name="getofferlist"
          options={{ label: "Oferty" }}
          list={Offers}
          icon={StarBorderIcon}
        />,
      ]
    );
  }

  return resources;
};

const App = () => (
  <Admin
    loginPage={MyLoginPage}
    logoutButton={MyLogoutButton}
    dataProvider={dataProvider}
    authProvider={authProvider}
    customRoutes={customRoutes}
    locale="pl"
    i18nProvider={i18nProvider}
    history={history}
    theme={theme}
    layout={MyLayout}
  >
    {(permissions) => getResources(permissions)}
  </Admin>
);

export default App;
