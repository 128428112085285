import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  Filter,
  TextInput,
  TopToolbar,
  sanitizeListRestProps,
} from "react-admin";
import FormBuilder from "../formBuilder/formBuilder";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import axios from "axios";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

const staticStyles = {
  actionIcons: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw",
    cursor: "pointer",
  },
};

export const FormList = (props) => {
  const isSmall = useMediaQuery("(max-width:1000px)");

  return (
    <List
      title="SELECTRIN - Formularze"
      filters={<FormFilter />}
      bulkActionButtons={false}
      exporter={false}
      actions={<ListActions />}
      {...props}
    >
      {isSmall ? (
        <Datagrid>
          <TextField source="form_name" label="Nazwa formularza" />
          <TextField source="scheme_author" label="Autor" />
          <ButtonGroups />
        </Datagrid>
      ) : (
        <Datagrid>
          <TextField source="form_name" label="Nazwa formularza" />
          <TextField source="scheme_author" label="Autor" />
          <TextField source="create_date" label="Data utworzenia" />
          <TextField source="modified_at" label="Data modyfikacji" />
          <ButtonGroups />
        </Datagrid>
      )}
    </List>
  );
};
// const Empty = () => {
//   return (null)
// }

export const FormCreate = (props) => (
  <>
    <Create>
      <FormBuilder {...props} />
    </Create>
  </>
);

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button href="/createForm" color="primary" startIcon={<AddIcon />}>
        STWÓRZ
      </Button>
    </TopToolbar>
  );
};

const ButtonGroups = (record: any) => {
  return (
    <Grid container direction="row">
      <ShowButton {...record} />
      <EditFormButton {...record} />
      <ButtonDelete {...record} />
    </Grid>
  );
};

const EditFormButton = (record: any) => {
  const history = useHistory();

  const handleClickEdit = () => {
    history.push(`/formEditor/${record.record.id}`);
  };
  return (
    <Tooltip title="Edytuj">
      <CreateOutlinedIcon
        style={staticStyles.actionIcons}
        onClick={handleClickEdit}
      />
    </Tooltip>
  );
};

const ShowButton = (record: any) => {
  const history = useHistory();

  const handleClickShow = () => {
    history.push(`/showForm/${record.record.id}`);
  };
  return (
    <Tooltip title="Podgląd">
      <VisibilityOutlinedIcon
        style={staticStyles.actionIcons}
        onClick={handleClickShow}
      />
    </Tooltip>
  );
};

const ButtonDelete = (record: any) => {
  const [open, setOpen] = React.useState(false);
  const [fileName, setFileName] = React.useState("");

  const handleClickRemove = () => {
    setFileName(record.record.form_name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {/* <Button startIcon={<DeleteOutlineIcon />} onClick={handleClickRemove} /> */}
      <Tooltip title="Usuń">
        <DeleteOutlineIcon
          style={staticStyles.actionIcons}
          onClick={handleClickRemove}
        />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Usuwanie formularza"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Czy na pewno chcesz usunąć szablon `{fileName}`?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Nie
          </Button>
          <Button
            onClick={() => {
              DeleteButton(record);
            }}
            color="primary"
            autoFocus
          >
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteButton = (data) => {
  axios
    .delete(
      API_URL +
        `/formscheme?privileges=${localStorage.getItem("privileges")}&form_id=${
          data.record.id
        }`,
      {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `formscheme${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      }
    )
    .then((response) => {
      if (response.data.code === 112) {
        window.location.href = "/getformList";
      }
    })
    .catch(function (error) {});
};

const FormFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Szukaj po: Nazwie" source="form_name" alwaysOn />
  </Filter>
);
