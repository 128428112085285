import jwt_decode from "jwt-decode";

export const getRoleFromPrivilegesToken = (token: string) => {
  const decoded = jwt_decode(token);
  return decoded.privileges;
};

export const getUserRole = () => {
  return localStorage.getItem("role");
};

export const getUserRoleName = () => {
  const userRole = getUserRole();
  switch (userRole) {
    case "administrator":
      return "Admin";
    case "uzytkownik_1":
      return "Użytkownik 1";
    case "uzytkownik_2":
      return "Użytkownik 2";
    default:
      return userRole;
  }
};
