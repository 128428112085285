import React from "react";
import {
  List,
  Datagrid,
  TextField,
  sanitizeListRestProps,
  Filter,
  TextInput,
  TopToolbar,
} from "react-admin";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
// import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import { useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Fade from "@material-ui/core/Fade";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ClipLoader from "react-spinners/ClipLoader";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

const staticStyles = {
  actionIcons: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw",
    cursor: "pointer",
  },
  userImage: {
    marginLeft: "3rem",
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    marginBottom: "0.5em",
    marginTop: "0.5em",
  },
  userTitle: {
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal" as "normal",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    alignSelf: "center",
    marginLeft: "2em",
  },
  buttonDialogLeft: {
    color: "#01C09E",
    fontWeight: "bold" as "bold",
  },
  buttonDialogRight: {
    color: "#01C09E",
    fontWeight: "bold" as "bold",
    marginLeft: "0.7em",
  },
  titleBarInfo: {
    marginTop: "1em",
    marginBottom: "1em",
    fontSize: "2em",
    fontWeight: "bold" as "bold",
  },
};
const TextFieldLabel = (record) => {
  return (
    <Typography style={{ fontSize: "1em" }}>
      {record.record.input_label.replace("<br>", "")}
    </Typography>
  );
};

export const FieldLibrary = ({ permissions, ...props }) => {
  const isSmall = useMediaQuery("(max-width:1000px)");

  return (
    <List
      title="SELECTRIN - Biblioteka pól"
      bulkActionButtons={false}
      exporter={false}
      actions={<ListActions {...props} />}
      filters={<InputsFilters permissions={permissions} {...props} />}
      {...props}
    >
      {isSmall ? (
        <Datagrid>
          <TextField source="input_name" label="Nazwa pola" />
          <TextField source="created_at" label="Data utworzenia" />
          <ButtonGroups />
        </Datagrid>
      ) : (
        <Datagrid>
          <TextField source="input_name" label="Nazwa pola" />
          <TextField source="created_at" label="Data utworzenia" />
          <TextFieldLabel record={props} label="Label" />
          <TextField source="input_type" label="Typ" />
          <ButtonGroups />
        </Datagrid>
      )}
    </List>
  );
};

const InputsFilters = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Szukaj po: Nazwa" source="input_name" alwaysOn />
  </Filter>
);

const ListActions = ({ ...props }) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button href="/createinput" color="primary" startIcon={<AddIcon />}>
        STWÓRZ
      </Button>
    </TopToolbar>
  );
};

const ButtonGroups = (record: any) => {
  return (
    <Grid container direction="row">
      <EditInputButton {...record} />
      <DeleteButton {...record} />
    </Grid>
  );
};

const EditInputButton = (record: any) => {
  const history = useHistory();

  const handleClickEdit = () => {
    history.push(`/editinput/${record.record.input_token}`);
  };
  return (
    <Tooltip title="Edytuj">
      <CreateOutlinedIcon
        style={staticStyles.actionIcons}
        onClick={handleClickEdit}
      />
    </Tooltip>
  );
};

const DeleteButton = (record: any) => {
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [sended, setSended] = React.useState(false);
  const [notSended, setNotSended] = React.useState(false);
  const [openSecond, setOpenSecond] = React.useState(false);

  const handleClickRemove = () => {
    setUserName(record.record.input_name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function asyncSleepFunct() {
    await sleep(2000);
    setOpen(false);
    axios
      .delete(
        API_URL +
          `/addinput?privileges=${localStorage.getItem(
            "privileges"
          )}&input_id=${record.record.input_token}`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `addinput${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        if (response.data.code === 130) {
          setSended(true);
        } else {
          setNotSended(true);
        }
      })
      .catch(function (error) {
        setNotSended(true);
      });
  }
  const handleCloseAll = () => {
    setOpen(false);
    setOpenSecond(false);
    setSended(false);
    setNotSended(false);
    window.location.href = "/getinputlist";
  };
  const DeleteButton = () => {
    setOpen(false);
    setOpenSecond(true);
    asyncSleepFunct();
  };
  const GetSpinner = () => {
    if (!sended && !notSended) {
      return (
        <>
          <Tooltip title="Usuń pole">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>Przetwarzanie</DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "10em" }}
              >
                <Grid xs={12} item>
                  <ClipLoader size={"6em"} color={"#01C09E"} />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      );
    } else if (sended && !notSended) {
      return (
        <>
          <Tooltip title="Usuń pole">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Pole usunięte
              </DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "9em" }}
              >
                <Grid xs={12} item>
                  <Fade in={sended}>
                    <CheckCircleOutlineIcon
                      style={{ width: "6em", height: "6em", color: "#01C09E" }}
                    />
                  </Fade>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseAll}
                style={staticStyles.buttonDialogRight}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      return (
        <>
          <Tooltip title="Usuń pole">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Wystąpił nieoczekiwany błąd? Jesteś administratorem?
              </DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "9em" }}
              >
                <Grid xs={12} item>
                  <Fade in={notSended}>
                    <CancelOutlinedIcon
                      style={{ width: "6em", height: "6em", color: "#ff0000" }}
                    />
                  </Fade>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseAll}
                style={staticStyles.buttonDialogRight}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  };
  if (openSecond) {
    return <>{GetSpinner()}</>;
  } else {
    return (
      <>
        <Tooltip title="Usuń pole">
          <DeleteOutlinedIcon
            style={staticStyles.actionIcons}
            onClick={handleClickRemove}
          />
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Usuwanie pola"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz usunąć pole {userName}? Usunięcie pola jest
              permanentne i nie można będzie cofnąć operacji
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Nie
            </Button>
            <Button
              onClick={() => {
                DeleteButton();
              }}
              color="primary"
              autoFocus
            >
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};
