import React, { useState } from "react";
import Pdf from "@mikecousins/react-pdf";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { API_URL } from "../config";

const MyPdfViewer = (props) => {
  const [page, setPage] = useState(1);

  return (
    <Pdf
      file={`${API_URL}/fileget/pdf/${props.match.params.id}`}
      page={page}
      scale={2}
    >
      {({ pdfDocument, pdfPage, canvas }) => (
        <>
          <Grid container>
            {!pdfDocument && <span>Loading...</span>}
            {canvas}
            {Boolean(pdfDocument && pdfDocument.numPages) && (
              <Grid container spacing={5}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                  >
                    Poprzednia
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setPage(page + 1)}
                    disabled={page === pdfDocument.numPages}
                  >
                    Następna
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Pdf>
  );
};

export default MyPdfViewer;
