import * as React from "react";
import { useForm } from "react-hook-form";
import { useNotify, Notification } from "react-admin";
import { Link } from "react-router-dom";
import axios from "axios";

// STYLE
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "./config";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPasswd(theme?) {
  const { register, handleSubmit } = useForm();

  const notify = useNotify();

  const onSubmit = (data) => {
    //To be done:check for empty values before hitting submit
    var payload = {
      email: data.email,
    };
    axios
      .post(API_URL + "/resetpasswdlink", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `resetpasswdlink${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 102) {
          notify("Wysłano maila");
          //this.props.navigation.navigate(<MyLoginPage />);
        }
      })
      .catch(function (error) {
        if (error.toString() === "User already exists")
          notify("Invalid email or password");
      });
  };

  const classes = useStyles();
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form
            className={classes.form}
            noValidate
            name="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Reset
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/">{"Login"}</Link>
              </Grid>
              <Grid item>
                <Link to="/register">{"Don't have an account? Sign Up"}</Link>
              </Grid>
            </Grid>
          </form>
          <Notification />
        </div>
      </Container>
    </div>
  );
}
