import $ from "jquery";
import React, { Component, createRef, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { FileInput } from "react-admin";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Grid from "@material-ui/core/Grid";
import { string } from "prop-types";
import Typography from "@material-ui/core/Typography";
import ClipLoader from "react-spinners/ClipLoader";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

// window.jQuery = $;
// window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

var id;

const staticStyles = {
  titleGroup: {
    marginTop: "1.5em",
    marginBottom: "1em",
    fontSize: "1.8em",
    fontWeight: "bold" as "bold",
  },
  groupImage: {
    marginLeft: "3rem",
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    marginBottom: "1em",
  },
  groupTitle: {
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal" as "normal",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    alignSelf: "center",
    marginLeft: "2em",
  },
  buttonPrimary: {
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
    float: "inline-end" as "inline-end",
    width: "14em",
  },
  buttonSecondary: {
    width: "9rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    height: "1.8em",
    textAlign: "center" as "center",
    marginTop: "0.8em",
    marginLeft: "0.6em",
  },
  imageFieldResize: {
    width: "8rem",
    height: "8em",
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
  paperStyleInput: {
    borderColor: "rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  labelInfo: {
    display: "inline-block",
    backgroundColor: "#01C09E",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: "bold" as "bold",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    padding: "0.5rem",
    borderRadius: "0.3rem",
    cursor: "pointer",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    marginLeft: "0.5rem",
    boxShadow:
      " 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  inputValues: {
    display: "none",
  },
  labelInfoLogo: {
    position: "absolute" as "absolute",
    transformOrigin: "top left",
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0",
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal" as "normal",
    lineHeight: "1",
    letterSpacing: "0.00938em",
    backgroundColor: "#fff",
    paddingLeft: "0.3em",
    width: "5.5em",
  },
  paperOutlineLogo: {
    height: "3.2em",
    borderColor: "rgba(0, 0, 0, 0.25)",
    marginTop: "1.8em",
  },
  fileNameText: {
    marginTop: "1em",
    marginLeft: "1em",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.57)",
  },
};

const useStyles = makeStyles({
  Datagrid: {
    cursor: "pointer",
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
});

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface PropsNotify {
  match: any;
}

interface EditGroupNotify {
  isLoading: boolean;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
  showNotifyError1013: boolean;
  showNotifyError1014: boolean;
  showNotifyErrorImage: boolean;
  fileName: string;
  image_link: string;
  logo: string;
  creator_id: string;
  group_name: string;
  id: string;
  styles: any;
}

class EditGroup extends Component<EditGroupNotify & PropsNotify> {
  state: EditGroupNotify;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      id: this.props.match.params.id,
      image_link: "",
      logo: "",
      styles: useStyles,
      group_name: "",
      creator_id: "",
      fileName: "",
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      showNotifyError1013: false,
      showNotifyError1014: false,
      showNotifyErrorImage: false,
    };
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeIMG = this.handleChangeIMG.bind(this);
  }

  fb = createRef();
  componentDidMount() {
    axios
      .get(
        API_URL +
          `/getgroup?group_id=${this.state.id}&user_id=${localStorage.getItem(
            "id"
          )}`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `getgroup${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        //id = this.state.id;
        if (response.data.code === 119) {
          this.setState({ isLoading: false });
          this.setState({ image_link: response.data.data.image_link });
          this.setState({ group_name: response.data.data.group_name });
          (document.getElementById("group_name") as HTMLInputElement).value =
            this.state.group_name;
        }
      })
      .catch(function (error) {});
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleClickSave() {
    const formData = new FormData();
    var logo = (document.querySelector("#image_link") as HTMLInputElement)!;
    var errorCode = 100;
    if (logo.files != null) {
      formData.append("logo", logo.files[0]);
    }
    formData.append("user_id", localStorage.getItem("id")!);
    formData.append(
      "group_name",
      (document.querySelector("#group_name") as HTMLInputElement).value
    );
    formData.append("group_id", this.state.id);
    var that = this;
    axios
      .post(API_URL + "/modifygroup", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${bcrypt.hashSync(
            `modifygroup${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 106) {
          this.setState({ showNotify: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getgrouplist";
          });
        } else {
          this.setState({ showNotifyBeta: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getgrouplist";
          });
        }
      })
      .catch(function (error) {
        if (error.response.data.code === 1013) {
          that.setState({ showNotifyError1013: true });
        }
        if (error.response.data.code === 1014) {
          that.setState({ showNotifyError1014: true });
        } else {
          that.setState({ showNotifyError: true });
        }
      });
  }

  handleClose(event: any, reason: any) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showNotify: false });
    this.setState({ showNotifyBeta: false });
    this.setState({ showNotifyError: false });
    this.setState({ showNotifyError1013: false });
    this.setState({ showNotifyError1014: false });
    this.setState({ showNotifyErrorImage: false });
  }

  handleChangeIMG(event: any) {
    if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({ showNotifyErrorImage: true });
    } else if (event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({
        image_link: URL.createObjectURL(event.target.files[0]),
      });
      this.setState({ fileName: event.target.files[0].name });
    }
  }

  render() {
    const {
      isLoading,
      showNotify,
      showNotifyBeta,
      showNotifyError,
      showNotifyError1013,
      showNotifyError1014,
      showNotifyErrorImage,
      fileName,
    } = this.state;
    const useStyles = {
      snackbar: {
        width: "100%",
      },
    };

    const NOTIFY = (
      <div>
        <Snackbar
          open={showNotify}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zmiany zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );

    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={showNotifyBeta}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={showNotifyError}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR1013 = (
      <div>
        <Snackbar
          open={showNotifyError1013}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wybrana nazwa grupy już istnieje, wybierz inną nazwę
          </Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR1014 = (
      <div>
        <Snackbar
          open={showNotifyError1014}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">Wybrany plik jest zbyt duży</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERRORIMAGE = (
      <div>
        <Snackbar
          open={showNotifyErrorImage}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">Nieprawidłowy format pliku</Alert>
        </Snackbar>
      </div>
    );
    const classes = useStyles;
    const uploadInputRef = null;

    while (isLoading) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader
              size={45}
              css={this.state.styles.spinner}
              color={"#01C09E"}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <div>
        <Grid item xs={12} md={6}>
          <Typography style={staticStyles.titleGroup}>Edycja grupy</Typography>
          <form>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="group_name"
              label="Nazwa grupy"
              type="text"
              id="group_name"
              autoComplete="group_name"
            />

            <img
              src={this.state.image_link}
              style={staticStyles.imageFieldResize}
            />
            <br />
            <Paper variant="outlined" style={staticStyles.paperOutlineLogo}>
              <label style={staticStyles.labelInfoLogo}>Logo grupy</label>
              <label style={{ display: "inline-flex" }}>
                <div style={{ display: "table" }}>
                  <Paper
                    variant="outlined"
                    style={staticStyles.buttonSecondary}
                    color="primary"
                  >
                    <div style={{ marginTop: "0.3em" }}>Wybierz plik...</div>
                    <Input
                      style={staticStyles.inputValues}
                      fullWidth
                      id="image_link"
                      type="file"
                      inputProps={{ accept: "image/png, image/jpeg" }}
                      onChange={this.handleChangeIMG}
                    />
                  </Paper>
                </div>
                <div style={{ display: "table" }}>
                  <div style={staticStyles.fileNameText}>{fileName}</div>
                </div>
              </label>
            </Paper>
            <Button
              id="saveForm"
              variant="contained"
              style={staticStyles.buttonPrimary}
              color="primary"
              onClick={this.handleClickSave}
            >
              Zapisz zmiany
            </Button>
          </form>
          {NOTIFY}
          {NOTIFYBETA}
          {NOTIFYERROR}
          {NOTIFYERROR1013}
          {NOTIFYERROR1014}
          {NOTIFYERRORIMAGE}
        </Grid>
      </div>
    );
  }
}

export default EditGroup;
