import $ from "jquery";
import React, { Component, createRef, useState } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TextField from "@material-ui/core/TextField";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import ClipLoader from "react-spinners/ClipLoader";
import { makeStyles } from "@material-ui/styles";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { element } from "prop-types";
import { API_URL } from "../config";

window.jQuery = $;
window.$ = $;
const drawerWidth = 240;

require("jquery-ui-sortable");
require("formBuilder");

var formData = "";
var formBuilder;
var id;
const styles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    marginLeft: 12,
  },
  toolbar: {
    paddingRight: 1,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "7",
    [theme.breakpoints.up("sm")]: {
      width: "9",
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: "4",
    paddingBottom: "4",
  },
  paper: {
    padding: "2",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  spinner: {
    flex: "1",
    alignSelf: "center",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface PropsInter {
  match: any;
}

interface FormEditorNotify {
  isLoading: boolean;
  formData: any;
  options: any;
  fbEditor: any;
  formBuilder: any;
  formName: any;
  id: any;
  styles: any;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
  inputsets: any;
}

class FormEditor extends Component<FormEditorNotify & PropsInter> {
  state: FormEditorNotify;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      formData: "",
      options: undefined,
      inputsets: undefined,
      fbEditor: undefined,
      formBuilder: undefined,
      formName: undefined,
      id: this.props.match.params.id,
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      styles: styles,
    };
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClickSaveNew = this.handleClickSaveNew.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  fb = createRef<HTMLDivElement>();
  componentDidMount() {
    axios
      .get(
        API_URL +
          `/getinputsets?privileges=${localStorage.getItem("privileges")}`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `getinputsets${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        if (response.data.code === 131) {
          this.setState({ inputsets: response.data.data });
          var options = {
            i18n: {
              override: {
                "en-US": {
                  addOption: "Dodaj opcję +",
                  allFieldsRemoved: "Wszystkie pliki zostały usunięte.",
                  allowMultipleFiles:
                    "Pozwól użytkownikom na upload wielu plików",
                  autocomplete: "Autouzupełnianie",
                  button: "Przycisk",
                  cannotBeEmpty: "Pole nie może być puste",
                  checkboxGroup: "Checkbox",
                  className: "Class",
                  clearAllMessage: "Czy chcesz wyczyścić wszystkie pola?",
                  clear: "Czyść",
                  close: "Zamknij",
                  content: "Treść",
                  copy: "Kopiuj do schowka",
                  copyButton: "&#43;",
                  copyButtonTooltip: "Kopiuj",
                  dateField: "Wybierz date",
                  description: "Tekst pomocniczy",
                  descriptionField: "Opis",
                  devMode: "Tryb developerski",
                  editNames: "Edytuj nazwy",
                  editorTitle: "Elementy formularza",
                  editXML: "Edytuj XML",
                  enableOther: "EWłącz &quot;Inne&quot;",
                  enableOtherMsg:
                    "Pozwól użytkownikom wprowadzać wartości spoza listy",
                  fieldVars: "Zmienne pola",
                  fieldNonEditable: "To pole nie może być edytowane.",
                  fieldRemoveWarning: "Czy na pewno chcesz usunąć to pole?",
                  fileUpload: "Upload plików",
                  formUpdated: "Zaktualizowano formularz",
                  getStarted: "Przeciągnij tutaj elementy z prawego menu",
                  header: "Nagłówek",
                  hide: "Edytuj",
                  hidden: "Ukryte pole",
                  inline: "W jenej lini",
                  inlineDesc: "Wyświetl {type} w lini",
                  label: "Label",
                  labelEmpty: "Label nie może być pusty",
                  limitRole:
                    "Ogranicz dostęp tylko dla wybranych grup użytkowników:",
                  mandatory: "Obowiązkowy",
                  maxlength: "Maksymalna długość",
                  minOptionMessage: "To pole wymaga co majmniej dwóch opcji",
                  minSelectionRequired:
                    "Minimanla ilość wybranych opcji: {min}",
                  multipleFiles: "Wiele plików",
                  name: "Nazwa",
                  no: "Nie",
                  noFieldsToClear: "Nie można usunąć zawartości tego pola",
                  number: "Liczba",
                  off: "Wyłączony",
                  on: "Włączony",
                  option: "Opcja",
                  options: "Opcje",
                  optional: "opcjonalny",
                  optionLabelPlaceholder: "Label",
                  optionValuePlaceholder: "Wartość",
                  optionEmpty: "Wymagana wartość",
                  other: "Inny",
                  paragraph: "Paragraf",
                  placeholder: "Placeholder",
                  "placeholder.value": "Wartość",
                  "placeholder.label": "Label",
                  "placeholder.text": "",
                  "placeholder.textarea": "",
                  "placeholder.email": "Podaj swój adres e-mail",
                  "placeholder.placeholder": "",
                  "placeholder.className":
                    "klasy muszą być oddzielone spacjami",
                  "placeholder.password": "Wprowadź swoje hasło",
                  preview: "Poprzedni",
                  radioGroup: "Radio Group",
                  radio: "Radio",
                  removeMessage: "Usuń element",
                  removeOption: "Usuń opcję",
                  remove: "&#215;",
                  required: "Wymagany",
                  richText: "Rich Text Editor",
                  roles: "Dostęp",
                  rows: "Wiersze",
                  save: "Zapisz",
                  selectOptions: "Opcje",
                  select: "Wybierz",
                  selectColor: "Wybierz kolor",
                  selectionsMessage: "Pozwól na wielokrotny wybór",
                  size: "Rozmiar",
                  "size.xs": "Bardzo mały",
                  "size.sm": "Mały",
                  "size.m": "Domyślny",
                  "size.lg": "Duży",
                  style: "Styl",
                  styles: {
                    btn: {
                      default: "Domyślny",
                      danger: "Niebezpieczeństwo",
                      info: "Informacyjny",
                      primary: "Primary",
                      success: "Sukcess",
                      warning: "Ostrzeżenie",
                    },
                  },
                  subtype: "Typ",
                  text: "Pole tekstowe",
                  textArea: "Duże pole tekstowe",
                  toggle: "Przełącz",
                  warning: "Ostrzeżenie!",
                  value: "Wartość",
                  viewJSON: "[{&hellip;}]",
                  viewXML: "&lt;/&gt;",
                  yes: "Tak",
                },
              },
            },
            disableFields: [
              "autocomplete",
              "date",
              "paragraph",
              "button",
              "checkbox-group",
              "header",
              "radio-group",
              "hidden",
              "text",
              "textarea",
              "number",
              "select",
              "file",
            ],
            disabledFieldButtons: {
              text: ["copy"],
              textarea: ["copy"],
              number: ["copy"],
              select: ["copy"],
              file: ["copy"],
              date: ["copy"],
              "checkbox-group": ["copy"],
            },
            editOnAdd: true,
            disabledActionButtons: ["clear", "save", "data"],
            disabledSubtypes: {
              textarea: ["quill"],
              file: ["fineuploader"],
            },
            disabledAttrs: ["access"],
            onAddField: function (fieldId) {
              const labelField = Array.from(
                document.getElementsByClassName(
                  "fld-label form-control"
                ) as HTMLCollectionOf<HTMLElement>
              );
              const nameField = Array.from(
                document.getElementsByClassName(
                  "fld-name form-control"
                ) as HTMLCollectionOf<HTMLElement>
              );
              const changeStars = Array.from(
                document.getElementsByClassName(
                  "field-label"
                ) as HTMLCollectionOf<HTMLElement>
              );
              labelField.forEach((element) => {
                element.setAttribute("disabled", "true");
                element.setAttribute("contenteditable", "false");
              });
              nameField.forEach((element) => {
                element.setAttribute("disabled", "true");
              });
              changeStars.forEach((element) => {
                if (element.getElementsByTagName("br")[0] != null) {
                  element.getElementsByTagName("br")[0].remove();
                }
              });
            },
            fieldRemoveWarn: true,
            inputSets: this.state.inputsets,
            typeUserAttrs: {
              file: {
                chooseFile: {
                  label: "Wybierz typ pliku",
                  type: "select",
                  value: "file_type",
                  options: ["Other files", "Images", "PDF"],
                },
              },
            },
          };

          axios
            .get(
              API_URL +
                `/formscheme?user_id=${localStorage.getItem("id")}&form_id=${
                  this.state.id
                }`,
              {
                headers: {
                  Authorization: `Bearer ${bcrypt.hashSync(
                    `formscheme${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
                    10
                  )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
                  "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
                },
              }
            )
            .then((response) => {
              id = this.state.id;
              if (response.data.code === 111) {
                var optionsTemp = options;
                optionsTemp["formData"] = response.data.data.form_data;
                this.setState({ options: optionsTemp });
                this.setState({ formData: response.data.data.form_data });
                this.setState({ isLoading: false });
                this.setState({
                  fbEditor: document.getElementById("fb-editor"),
                });
                this.setState({
                  formBuilder: $(this.state.fbEditor).formBuilder(
                    this.state.options,
                    this.state.formData
                  ),
                });
                this.setState({ formName: response.data.data.form_name });
                formBuilder = this.state.formBuilder;
                (
                  document.getElementById("form_name") as HTMLInputElement
                ).value = this.state.formName;
              }
            })
            .catch(function (error) {});
        }
      })
      .catch(function (error) {});
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleClickSave() {
    var payload = {
      user_id: localStorage.getItem("id"),
      form_id: id,
      privileges: localStorage.getItem("privileges"),
      form_name: (document.getElementById("form_name") as HTMLInputElement)
        .value,
      form_data: formBuilder.actions.getData("json", true),
    };
    axios
      .put(API_URL + "/formscheme", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `formscheme${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 114) {
          this.setState({ showNotify: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getformList";
          });
        } else {
          this.setState({ showNotifyError: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getformList";
          });
        }
      })
      .catch(function (error) {});
  }

  handleClickSaveNew() {
    var payload = {
      user_id: localStorage.getItem("id"),
      privileges: localStorage.getItem("privileges"),
      form_name: (document.getElementById("form_name") as HTMLInputElement)
        .value,
      form_data: formBuilder.actions.getData("json", true),
    };
    axios
      .post(API_URL + "/formscheme", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `formscheme${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 110) {
          this.setState({ showNotifyBeta: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getformList";
          });
        } else {
          this.setState({ showNotifyError: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getformList";
          });
        }
      })
      .catch(function (error) {});
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showNotify: false });
    this.setState({ showNotifyBeta: false });
    this.setState({ showNotifyError: false });
  }

  render() {
    const { isLoading, showNotify, showNotifyBeta, showNotifyError, styles } =
      this.state;
    const useStyles = {
      snackbar: {
        width: "100%",
      },
      spinner: {
        flex: 1,
        alignSelf: "center",
      },
    };

    const NOTIFY = (
      <div>
        <Snackbar
          open={showNotify}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zmiany zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={showNotifyBeta}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Nowy szablon zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={showNotifyError}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );

    while (isLoading) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={styles.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }
    return (
      <div>
        <form>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="name"
            label="Nazwa szablonu"
            type="text"
            id="form_name"
            autoComplete="form_name"
            value={this.state.formName}
            onChange={(e) => this.setState({ formName: e.target.value })}
          />
          <div id="fb-editor" ref={this.fb} />
          <Grid container>
            <Grid item xs={12} md={3}>
              <Button
                id="saveForm"
                variant="contained"
                color="primary"
                onClick={this.handleClickSave}
              >
                Zapisz szablon
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                id="saveForm"
                variant="contained"
                color="primary"
                onClick={this.handleClickSaveNew}
              >
                Zapisz jako nowy
              </Button>
            </Grid>
          </Grid>
        </form>
        {NOTIFY}
        {NOTIFYBETA}
        {NOTIFYERROR}
      </div>
    );
  }
}

export default FormEditor;
