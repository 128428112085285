import $ from "jquery";
import React, { Component, createRef } from "react";
import axios from "axios";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import ClipLoader from "react-spinners/ClipLoader";
import { makeStyles } from "@material-ui/styles";
import Field from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Select as SelectUI } from "@material-ui/core";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { stringify } from "query-string";
import Input from "@material-ui/core/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Paper from "@material-ui/core/Paper";
import { Editor } from "react-draft-wysiwyg";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

window.jQuery = $;
window.$ = $;
require("../");
require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");

var formData = "";
var formBuilder;
var id;
var showForm;

const styles = makeStyles((theme: any) => ({
  spinner: {
    flex: "1",
    alignSelf: "center",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const staticStyles = {
  buttonPrimary: {
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
    float: "inline-end" as "inline-end",
    width: "14em",
  },
  buttonSecondary: {
    width: "9rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    height: "1.8em",
    textAlign: "center" as "center",
    marginTop: "0.8em",
    marginLeft: "0.6em",
  },
  muiRichTextField: {
    width: "100%",
    marginTop: "2em",
    marginBottom: "3em",
  },
  paperOutlineLogo: {
    height: "auto",
    marginTop: "2em",
    marginBottom: "2em",
  },
  textField: {
    width: "-moz-available",
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  selectBoxRender: {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  selectFieldStyle: {
    width: "26em",
  },
  labelInfoLogo: {
    position: "absolute" as "absolute",
    transformOrigin: "top left",
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0",
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal" as "normal",
    lineHeight: "1",
    letterSpacing: "0.00938em",
    backgroundColor: "#fff",
    paddingLeft: "0.3em",
    width: "11.5em",
  },
  fileNameText: {
    marginTop: "1em",
    marginLeft: "1em",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.57)",
  },
  inputValues: {
    display: "none",
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface PropsInter {
  match: any;
}
interface DataLabel {
  value: string;
  label: string;
}

interface FormEditorNotify {
  isLoading: boolean;
  formData: any;
  styles: any;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
  showForm: any;
  group_id: any;
  offer_name: any;
  form_id: any;
  dashboardData: any;
  user_id: any;
  groups: undefined[];
  forms: undefined[];
  offer_data: any;
  formSchemes: any;
  api1: boolean;
  api2: boolean;
  api3: boolean;
  options: any;
  fbEditor: any;
  formBuilder: any;
  isRender: boolean;
  values: any;
  valuesCopy: any;
}

class CreateOffer extends Component<FormEditorNotify & PropsInter> {
  state: FormEditorNotify;
  constructor(props: any) {
    super(props);
    this.state = {
      options: undefined,
      fbEditor: undefined,
      formBuilder: undefined,
      isLoading: true,
      formData: null,
      showForm: undefined,
      groups: [],
      forms: [],
      offer_data: undefined,
      formSchemes: null,
      dashboardData: null,
      user_id: localStorage.getItem("id"),
      form_id: 50,
      group_id: this.props.match.params.id,
      offer_name: "",
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      styles: styles,
      api1: false,
      api2: false,
      api3: false,
      isRender: false,
      values: [],
      valuesCopy: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleChangeForm = this.handleChangeForm.bind(this);
  }

  fb = createRef<HTMLDivElement>();

  componentDidMount() {
    this.GetFormFromApi();
  }

  GetFormSchemeFromApi(form_id_temp: number) {
    var fbEditor = document.getElementById("fb-editor");
    if (fbEditor != null) fbEditor.innerHTML = "";

    var options = {
      i18n: {
        override: {
          "en-US": {
            addOption: "Dodaj opcję +",
            allFieldsRemoved: "Wszystkie pliki zostały usunięte.",
            allowMultipleFiles: "Pozwól użytkownikom na upload wielu plików",
            autocomplete: "Autouzupełnianie",
            button: "Przycisk",
            cannotBeEmpty: "Pole nie może być puste",
            checkboxGroup: "Checkbox Group",
            className: "Class",
            clearAllMessage: "Czy chcesz wyczyścić wszystkie pola?",
            clear: "Czyść",
            close: "Zamknij",
            content: "Treść",
            copy: "Kopiuj do schowka",
            copyButton: "&#43;",
            copyButtonTooltip: "Kopiuj",
            dateField: "Wybierz date",
            description: "Tekst pomocniczy",
            descriptionField: "Opis",
            devMode: "Tryb developerski",
            editNames: "Edytuj nazwy",
            editorTitle: "Elementy formularza",
            editXML: "Edytuj XML",
            enableOther: "EWłącz &quot;Inne&quot;",
            enableOtherMsg:
              "Pozwól użytkownikom wprowadzać wartości spoza listy",
            fieldVars: "Zmienne pola",
            fieldNonEditable: "To pole nie może być edytowane.",
            fieldRemoveWarning: "Czy na pewno chcesz usunąć to pole?",
            fileUpload: "Upload plików",
            formUpdated: "Zaktualizowano formularz",
            getStarted: "Przeciągnij tutaj elementy z prawego menu",
            header: "Nagłówek",
            hide: "Ukryj",
            hidden: "Ukryte pole",
            inline: "W jenej lini",
            inlineDesc: "Wyświetl {type} w lini",
            label: "Label",
            labelEmpty: "Label nie może być pusty",
            limitRole: "Ogranicz dostęp tylko dla wybranych grup użytkowników:",
            mandatory: "Obowiązkowy",
            maxlength: "Maksymalna długość",
            minOptionMessage: "To pole wymaga co majmniej dwóch opcji",
            minSelectionRequired: "Minimanla ilość wybranych opcji: {min}",
            multipleFiles: "Wiele plików",
            name: "Nazwa",
            no: "Nie",
            noFieldsToClear: "Nie można usunąć zawartości tego pola",
            number: "Liczba",
            off: "Wyłączony",
            on: "Włączony",
            option: "Opcja",
            options: "Opcje",
            optional: "opcjonalny",
            optionLabelPlaceholder: "Label",
            optionValuePlaceholder: "Wartość",
            optionEmpty: "Wymagana wartość",
            other: "Inny",
            paragraph: "Paragraf",
            placeholder: "Placeholder",
            "placeholder.value": "Wartość",
            "placeholder.label": "Label",
            "placeholder.text": "",
            "placeholder.textarea": "",
            "placeholder.email": "Podaj swój adres e-mail",
            "placeholder.placeholder": "",
            "placeholder.className": "klasy muszą być oddzielone spacjami",
            "placeholder.password": "Wprowadź swoje hasło",
            preview: "Poprzedni",
            radioGroup: "Radio Group",
            radio: "Radio",
            removeMessage: "Usuń element",
            removeOption: "Usuń opcję",
            remove: "&#215;",
            required: "Wymagany",
            richText: "Rich Text Editor",
            roles: "Dostęp",
            rows: "Wiersze",
            save: "Zapisz",
            selectOptions: "Opcje",
            select: "Wybierz",
            selectColor: "Wybierz kolor",
            selectionsMessage: "Pozwól na wielokrotny wybór",
            size: "Rozmiar",
            "size.xs": "Bardzo mały",
            "size.sm": "Mały",
            "size.m": "Domyślny",
            "size.lg": "Duży",
            style: "Styl",
            styles: {
              btn: {
                default: "Domyślny",
                danger: "Niebezpieczeństwo",
                info: "Informacyjny",
                primary: "Primary",
                success: "Sukcess",
                warning: "Ostrzeżenie",
              },
            },
            subtype: "Typ",
            text: "Pole tekstowe",
            textArea: "Duże pole tekstowe",
            toggle: "Przełącz",
            warning: "Ostrzeżenie!",
            value: "Wartość",
            viewJSON: "[{&hellip;}]",
            viewXML: "&lt;/&gt;",
            yes: "Tak",
          },
        },
      },

      disableFields: [
        "autocomplete",
        "date",
        "paragraph",
        "button",
        "checkbox-group",
        "header",
        "radio-group",
        "hidden",
        "text",
        "textarea",
        "number",
        "select",
        "file",
      ],
      disabledFieldButtons: {
        text: ["remove", "edit", "copy"],
        textarea: ["remove", "edit", "copy"],
        number: ["remove", "edit", "copy"],
        select: ["remove", "edit", "copy"],
        file: ["remove", "edit", "copy"],
        date: ["remove", "edit", "copy"],
        "checkbox-group": ["remove", "edit", "copy"],
      },
      editOnAdd: true,
      disabledActionButtons: ["clear", "save", "data"],
      disabledSubtypes: {
        textarea: ["quill"],
        file: ["fineuploader"],
      },
      disabledAttrs: ["access"],
      fieldRemoveWarn: true,
      typeUserAttrs: {
        file: {
          chooseFile: {
            label: "Wybierz typ pliku",
            type: "select",
            value: "file_type",
            options: ["Other files", "Images", "PDF"],
          },
        },
      },
    };
    axios
      .get(
        API_URL +
          `/formschemeparsed?user_id=${localStorage.getItem(
            "id"
          )}&form_id=${form_id_temp}`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `formschemeparsed${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        if (response.data.code === 111) {
          var optionsTemp = options;
          optionsTemp["formData"] = response.data.data.form_data;
          this.setState({ formData: response.data.data.form_data });
          this.setState({ isLoading: false });
          this.setState({ options: optionsTemp });
          this.setState({ fbEditor: document.getElementById("fb-editor") });
          this.setState({
            formBuilder: $(this.state.fbEditor).formBuilder(
              this.state.options,
              this.state.formData
            ),
          });
          formBuilder = this.state.formBuilder;
          this.setState({ isRender: false });
        }
      })
      .catch(function (error) {});
  }

  GetDataFromApi(group_id: any, second_user_id: any) {
    var payload = {
      user_id: this.state.user_id,
      group_id: group_id,
    };

    axios
      .get(API_URL + `/dashboard?${stringify(payload)}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `dashboard${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 121) {
          this.setState({ dashboardData: response.data.data });
          this.GetFormSchemeFromApi(0);
        }
        if (response.data.code === 123) {
          this.setState({ dashboardData: response.data.data });
          this.GetFormSchemeFromApi(0);
        }
      })
      .catch(function (err) {});
  }

  GetFormFromApi() {
    this.setState({ api1: true });
    axios
      .get(API_URL + `/getformList?user_id=${localStorage.getItem("id")}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `getformList${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 105) {
          this.setState({ formSchemes: response.data.data });
          this.GetDataFromApi(this.state.group_id, null);
        }
      })
      .catch(function (err) {});
  }

  handleChangeGroup = (event: any, newValue: number) => {
    this.setState({ group_id: event.value });
  };
  handleChangeForm = (event: any, newValue: number) => {
    this.setState({ isRender: true });
    this.setState({ form_id: event.value });
    this.setState({ values: [] });
    this.state.values = [];
    this.GetFormSchemeFromApi(event.value);
  };

  GetGroupList = () => {
    var arr: DataLabel[] = [];
    var defaultVal = {};
    var groups = this.state.dashboardData.groups;
    var groupName = this.state.dashboardData.group_name;
    groups.map((item, index) => {
      if (item.group_name === groupName) {
        defaultVal = { value: item.group_token, label: item.group_name };
        if (this.state.group_id === undefined) {
          this.setState({ group_id: item.group_token });
        }
      }
      arr.push({ value: item.group_token, label: item.group_name });
    });
    return (
      <Select
        options={arr}
        defaultValue={defaultVal}
        onChange={this.handleChangeGroup}
      />
    );
  };

  GetSelectForm = () => {
    var arr: DataLabel[] = [];
    var defaultVal = {};
    var forms = this.state.formSchemes;
    forms.map((item, index) => {
      if (index === 0) {
        defaultVal = { value: item.id, label: item.form_name };
      }
      arr.push({ value: item.id, label: item.form_name });
    });
    return (
      <Select
        options={arr}
        defaultValue={defaultVal}
        onChange={this.handleChangeForm}
      />
    );
  };

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showNotify: false });
    this.setState({ showNotifyBeta: false });
    this.setState({ showNotifyError: false });
  }
  renderForm() {
    $("#showForm").formRender({
      formData: this.state.formData,
      dataType: "json",
    });
  }

  handleTextChange(event: any) {
    this.setState({ offer_name: event.target.value });
  }
  valueGroupValidation() {
    if (this.state.offer_name === "") {
      return (
        <TextField
          error
          variant="outlined"
          margin="normal"
          fullWidth
          helperText="Pole nie może być puste"
          name="offer_name"
          value={this.state.offer_name}
          label="Nazwa oferty"
          type="text"
          id="offer_name"
          autoComplete="offer_name"
          onChange={this.handleTextChange}
        />
      );
    } else {
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="offer_name"
          value={this.state.offer_name}
          label="Nazwa oferty"
          type="text"
          id="offer_name"
          autoComplete="offer_name"
          onChange={this.handleTextChange}
        />
      );
    }
  }
  returnButton() {
    if (this.state.offer_name === "") {
      return (
        <Button
          disabled
          id="saveForm"
          variant="contained"
          style={staticStyles.buttonSecondary}
          color="primary"
        >
          Zapisz
        </Button>
      );
    } else {
      return (
        <Button
          id="saveForm"
          variant="contained"
          style={staticStyles.buttonPrimary}
          color="primary"
          onClick={this.handleClickSave}
        >
          Zapisz
        </Button>
      );
    }
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  ParsedDataReturn() {
    var PrepareData: any[] = [];
    var files: any[] = [];
    this.state.formData.map((item, index) => {
      if (item.type === "file") {
        if (this.state.values[index].file)
          files.push(this.state.values[index].file);
      }
      PrepareData.push(this.state.values[index]);
    });
    return [PrepareData, files];
  }

  handleClickSave() {
    var ParseSecure: any[] = [];
    var files: any[] = [];
    [ParseSecure, files] = this.ParsedDataReturn();
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("id")!);
    formData.append("privileges", localStorage.getItem("privileges")!);
    formData.append("group_id", this.state.group_id);
    formData.append("offer_name", this.state.offer_name);
    formData.append("offer_data", JSON.stringify(ParseSecure));
    for (let i = 0; i < files.length; i++) {
      formData.append(`offer_files_${i}`, files[i]);
    }
    axios
      .post(API_URL + "/addoffer", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${bcrypt.hashSync(
            `addoffer${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 132) {
          this.setState({ showNotify: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getofferlist";
          });
        } else {
          this.setState({ showNotifyError: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getofferlist";
          });
        }
      })
      .catch(function (error) {});
  }
  handlePrintWhenChange(event: any, index: any) {
    var tempDict = this.state.values;
    tempDict[index].value = draftToHtml(event);
    this.setState({ values: tempDict });
  }
  handleTextFieldOnChange(event: any) {
    this.state.values[event.target.id].value = event.target.value;
  }
  handleNumberFieldOnChange(event: any) {
    if (event.target.validity.valid) {
      let tempArr: any[] = this.state.values;
      if (
        event.target.value.charAt(event.target.value.length - 1).match("^[.]$")
      ) {
        tempArr[event.target.id].value = event.target.value;
      } else {
        if (event.target.value.match("^[+-]$")) {
          tempArr[event.target.id].value = event.target.value;
        } else {
          tempArr[event.target.id].value = parseFloat(
            event.target.value
          ).toString();
        }
      }
      tempArr[event.target.id].match = true;
      this.setState({ values: tempArr });
    } else if (event.target.value === "") {
      let tempArr: any[] = this.state.values;
      tempArr[event.target.id].value = event.target.value;
      this.setState({ values: tempArr });
    } else {
    }
  }
  handleChangeFormRender(event: any, index: number) {
    let tempArr: any[] = this.state.values;
    if (tempArr[index].type === "select") {
      tempArr[index].default = event.target.value;
      tempArr[index].value.map((elem, mapIndex) => {
        if (elem.value === event.target.value) {
          tempArr[index].value[mapIndex].selected = true;
        } else {
          tempArr[index].value[mapIndex].selected = false;
        }
      });
    } else {
      if (event.target.value.length <= 1) {
        tempArr[index].default = event.target.value;
      } else {
        tempArr[index].default = event.target.value;
      }
    }
    this.setState({ values: tempArr });
  }
  handleChangeFile(event: any, index: any) {
    if (this.state.values[index].dataType === "file") {
      let tempArr: any[] = this.state.values;
      tempArr[index].fileName = event.target.files[0].name;
      tempArr[index].file = event.target.files[0];
      this.setState({ values: tempArr });
    } else if (this.state.values[index].dataType === "image") {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
        let tempArr: any[] = this.state.values;
        tempArr[index].fileName = event.target.files[0].name;
        tempArr[index].file = event.target.files[0];
        this.setState({ values: tempArr });
      }
    } else if (this.state.values[index].dataType === "pdf") {
      if (event.target.files[0].name.match(/\.(pdf)$/)) {
        let tempArr: any[] = this.state.values;
        tempArr[index].fileName = event.target.files[0].name;
        tempArr[index].file = event.target.files[0];
        this.setState({ values: tempArr });
      }
    }
  }
  handleChangeCheckbox(event, index, valindex) {
    let tempArr: any[] = this.state.values;
    tempArr[index].value[valindex].selected =
      !tempArr[index].value[valindex].selected;
    this.setState({ values: tempArr });
  }

  customContentStateConverter(contentState) {
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block.merge({
              type: "atomic",
              text: "img",
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set("blockMap", newBlockMap);
    return newContentState;
  }
  PreparedData() {
    var indents: any[] = [];
    var indexTemp = 0;
    this.handlePrintWhenChange = this.handlePrintWhenChange.bind(this);
    this.handleTextFieldOnChange = this.handleTextFieldOnChange.bind(this);
    this.handleNumberFieldOnChange = this.handleNumberFieldOnChange.bind(this);
    this.handleChangeFormRender = this.handleChangeFormRender.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.state.formData.map((item: any, index: any) => {
      if (item.type === "text") {
        this.state.values.push({
          index: index,
          type: item.type,
          value: item.value ? item.value : null,
          label: item.label,
          required: item.required,
        });
        indents.push(
          <Grid item>
            <TextField
              id={index}
              onChange={this.handleTextFieldOnChange}
              style={staticStyles.textField}
              label={item.label != null ? item.label.replace("<br>", "") : ""}
              variant="outlined"
              type={item.type}
              required={item.required}
            />
          </Grid>
        );
      } else if (item.type === "date") {
        this.state.values.push({
          index: index,
          type: item.type,
          value: item.value,
          label: item.label,
          required: item.required,
        });
        indents.push(
          <Grid item>
            <TextField
              id={index}
              onChange={this.handleTextFieldOnChange}
              style={staticStyles.textField}
              label={item.label != null ? item.label.replace("<br>", "") : ""}
              variant="outlined"
              type={item.type}
              required={item.required}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        );
      } else if (item.type === "paragraph") {
        this.state.values.push({
          index: index,
          type: item.type,
          value: item.value ? item.value : null,
          label: item.label,
          required: item.required,
        });
        indents.push(
          <Grid item>
            <Typography
              id={index}
              onChange={this.handleTextFieldOnChange}
              style={staticStyles.textField}
            >
              {item.label != null ? item.label.replace("<br>", "") : ""}
            </Typography>
          </Grid>
        );
      } else if (item.type === "textarea") {
        this.state.values.push({
          label: item.label,
          index: index,
          type: item.type,
          value: item.value,
        });
        var elem = this.state.values[index].value;
        const blocksFromHTML = convertFromHTML(elem);
        indents.push(
          <Grid item>
            <Paper variant="outlined" style={staticStyles.paperOutlineLogo}>
              <Editor
                id={index}
                editorClassName={{ minHeight: "auto" }}
                onContentStateChange={(e: any) =>
                  this.handlePrintWhenChange(e, index)
                }
                defaultEditorState={EditorState.createWithContent(
                  this.customContentStateConverter(
                    ContentState.createFromBlockArray(
                      blocksFromHTML.contentBlocks,
                      blocksFromHTML.entityMap
                    )
                  )
                )}
              />
            </Paper>
          </Grid>
        );
      } else if (item.type === "number") {
        var isSet = this.state.values[index] == null ? false : true;
        this.state.values.push({
          label: item.label,
          index: index,
          type: item.type,
          value: item.value != null ? item.value : "",
          match: true,
          required: item.required,
        });
        var value = isSet ? this.state.values[index].value : "";
        if (item.min && item.max) {
          var inputProps = {
            pattern:
              "^((([]*))|(([+-]))|([+-]?((0|([1-9][0-9]*))((.[0-9]+)|(.+))?)))$",
            min: item.min.toString(),
            max: item.max.toString(),
            step: item.step ? item.step.toString() : "1",
          };
          indents.push(
            <Grid item>
              <TextField
                id={index}
                onChange={this.handleNumberFieldOnChange}
                value={value}
                style={staticStyles.textField}
                label={item.label != null ? item.label.replace("<br>", "") : ""}
                variant="outlined"
                required={item.required}
                inputProps={inputProps}
              />
            </Grid>
          );
        } else {
          var inputProps2 = {
            pattern:
              "^((([]*))|(([+-]))|([+-]?((0|([1-9][0-9]*))((.[0-9]+)|(.+))?)))$",
          }; //inputMode: "numeric" as "numeric"
          indents.push(
            <Grid item>
              <TextField
                id={index}
                onChange={this.handleNumberFieldOnChange}
                value={value}
                style={staticStyles.textField}
                label={item.label != null ? item.label.replace("<br>", "") : ""}
                variant="outlined"
                inputProps={inputProps2}
                required={item.required}
              />
            </Grid>
          );
        }
      } else if (item.type === "select") {
        if (this.state.values[index] == null) {
          this.state.values.push({
            label: item.label,
            index: index,
            type: item.type,
            value: item.values,
            multiple: item.multiple,
            default: null,
            required: item.required,
          });
        }
        var choices: any[] = [];
        var defaultVal: any[] = this.state.values[index].default;
        var isSet = this.state.values[index].default === null ? false : true;
        this.state.values[index].value.map((choice, valIndex) => {
          if (!isSet) {
            if (item.multiple && choice.selected) {
              if (defaultVal == null) {
                defaultVal = [choice.value];
              } else {
                defaultVal.push(choice.value);
              }
            } else if (!item.mutiple && choice.selected) {
              defaultVal = [choice.value];
            }
          }
          choices.push({
            value: choice.value,
            label: choice.label,
            index: index,
            selected: choice.selected,
            multiple: item.multiple,
          });
        });
        this.state.values[index].default = defaultVal;
        if (item.multiple == true) {
          indents.push(
            <Grid style={staticStyles.selectBoxRender}>
              <FormControl>
                <InputLabel id={`select-element-${index}`}>
                  {item.label != null ? item.label.replace("<br>", "") : ""}
                </InputLabel>
                <SelectUI
                  id={`select-element-${index}`}
                  label={item.label.replace("<br>", "")}
                  style={staticStyles.selectFieldStyle}
                  multiple
                  value={this.state.values[index].default}
                  onChange={(e) => this.handleChangeFormRender(e, index)}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {choices.map((chc, valIndex) => (
                    <MenuItem id={index} key={chc.label} value={chc.value}>
                      {chc.label}
                    </MenuItem>
                  ))}
                </SelectUI>
              </FormControl>
            </Grid>
          );
        } else {
          indents.push(
            <Grid style={staticStyles.selectBoxRender}>
              <FormControl>
                <InputLabel id={`select-element-${index}`}>
                  {item.label != null ? item.label.replace("<br>", "") : ""}
                </InputLabel>
                <SelectUI
                  id={`select-element-${index}`}
                  label={item.label.replace("<br>", "")}
                  style={staticStyles.selectFieldStyle}
                  value={this.state.values[index].default}
                  onChange={(e) => this.handleChangeFormRender(e, index)}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {choices.map((chc, valIndex) => (
                    <MenuItem id={index} key={chc.label} value={chc.value}>
                      {chc.label}
                    </MenuItem>
                  ))}
                </SelectUI>
              </FormControl>
            </Grid>
          );
        }
        this.state.values[index] = {
          index: index,
          label: item.label,
          type: item.type,
          value: choices !== [] ? choices : null,
          multiple: item.multiple,
          default: defaultVal,
          required: item.required,
        };
      } else if (item.type === "checkbox-group") {
        if (this.state.values[index] == null) {
          this.state.values.push({
            label: item.label,
            index: index,
            type: item.type,
            value: item.values ? item.values : null,
            multiple: item.multiple,
            default: null,
            required: item.required,
          });
        }
        var choices: any[] = [];
        var defaultVal: any[] = this.state.values[index].default;
        var isSet = this.state.values[index] == null ? false : true;
        let tempArr: any[] = this.state.values;
        this.state.values[index].value.values.map((choice, valIndex) => {
          if (!isSet) {
            choices.push({
              value: choice.value,
              label: choice.label,
              index: index,
              selected: choice.selected,
              multiple: item.multiple,
            });
          } else {
            choices.push({
              value: choice.value,
              label: choice.label,
              index: index,
              selected: tempArr[index].value[valIndex].selected,
            });
          }
        });
        this.state.values[index].default = defaultVal;
        indents.push(
          <Grid style={staticStyles.selectBoxRender}>
            <Typography id={`select-element-${index}`}>
              {item.label !== null ? item.label.replace("<br>", "") : ""}
            </Typography>
            {choices.map((chc, valIndex) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chc.selected}
                    name={chc.value}
                    onChange={(e) =>
                      this.handleChangeCheckbox(e, index, valIndex)
                    }
                  />
                }
                label={chc.label}
              />
            ))}
          </Grid>
        );
        this.state.values[index] = {
          index: index,
          label: item.label,
          type: item.type,
          value: choices != [] ? choices : null,
          multiple: item.multiple,
          default: defaultVal,
          required: item.required,
        };
      } else if (item.type === "file") {
        var accept = {};
        var text = "Dodaj plik";
        var type = "file";
        if (item.chooseFile === "1") {
          accept = { accept: "image/png, image/jpeg" };
          text = "Dodaj obrazek";
          type = "image";
        } else if (item.chooseFile === "2") {
          accept = { accept: "application/pdf" };
          text = "Dodaj plik PDF";
          type = "pdf";
        }
        this.state.values.push({
          label: item.label,
          index: index,
          type: item.type,
          dataType: type,
          fileName: "",
          file: null,
          required: item.required,
        });
        indents.push(
          <Paper variant="outlined" style={staticStyles.paperOutlineLogo}>
            <label style={staticStyles.labelInfoLogo}>{text}</label>
            <label style={{ display: "inline-flex" }}>
              <div style={{ display: "table" }}>
                <Paper
                  variant="outlined"
                  style={staticStyles.buttonSecondary}
                  color="primary"
                >
                  <div style={{ marginTop: "0.3em" }}>Wybierz plik...</div>
                  <Input
                    style={staticStyles.inputValues}
                    fullWidth
                    type="file"
                    inputProps={accept}
                    onChange={(e) => this.handleChangeFile(e, index)}
                  />
                </Paper>
              </div>
              <div style={{ display: "table" }}>
                <div style={staticStyles.fileNameText}>
                  {this.state.values[index].fileName}
                </div>
              </div>
            </label>
          </Paper>
        );
      }
      indexTemp++;
    });
    if (this.state.values.length > indexTemp) {
      for (let i = indexTemp; i < this.state.values.length; i++) {
        this.state.values.splice(i, 1);
      }
    }
    return <>{indents}</>;
  }
  returnSheme() {
    if (this.state.isRender) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader
              size={45}
              css={this.state.styles.spinner}
              color={"#01C09E"}
            />
          </Grid>
        </Grid>
      );
    } else {
      return <Grid direction="column">{this.PreparedData()}</Grid>;
    }
  }

  render() {
    const { isLoading, showNotify, showNotifyBeta, showNotifyError, styles } =
      this.state;
    const useStyles = {
      snackbar: {
        width: "100%",
      },
      spinner: {
        flex: 1,
        alignSelf: "center",
      },
    };

    const NOTIFY = (
      <div>
        <Snackbar
          open={showNotify}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zmiany zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={showNotifyBeta}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Nowy szablon zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={showNotifyError}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );

    if (isLoading) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={styles.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Typography style={{ fontSize: 20, marginBottom: 20 }}>
                {this.valueGroupValidation()}
              </Typography>
              {/* <div id='showForm'></div> */}
              {/* <div id="fb-editor" ref={this.fb} /> */}
              {this.returnSheme()}
              {/* {this.returnSheme()} */}
              <Grid container justify="flex-end">
                {this.returnButton()}
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography>Wybierz szablon</Typography>
              {this.GetSelectForm()}
              <Typography>Wybierz grupe</Typography>
              {this.GetGroupList()}
            </Grid>
          </Grid>

          {NOTIFY}
          {NOTIFYBETA}
          {NOTIFYERROR}
        </form>
      </>
    );
  }
}

export default CreateOffer;
