import React from "react";
import { useState } from "react";
import { useNotify, Notification } from "react-admin";
import { useForm } from "react-hook-form";
import axios from "axios";

// STYLE
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// { Redirect } from 'react-router';
import MyLoginPage from "./login/MyLoginPage";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "./config";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const staticStyles = {
  logoTypeTemporary: {
    fontWeight: "bold" as "bold",
    color: "#01C09E",
  },
  linkStyleNotHover: {
    textDecoration: "none",
    fontWeight: "bold" as "bold",
    color: "#01C09E",
  },
  linkStyleHover: {
    textDecoration: "none",
    fontWeight: "bold" as "bold",
    color: "#00deb6",
  },
  buttonPrimary: {
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
  },
};

export default function Register({ themes, type }) {
  const [hover, setHover] = useState(false);
  const [types, setType] = useState(type);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();
  const notify = useNotify();
  var linkStyle = staticStyles.linkStyleNotHover;
  const onSubmit = (data) => {
    //To be done:check for empty values before hitting submit
    var payload = {
      firstname: data.first_name,
      lastname: data.last_name,
      email: data.email,
      password: data.password,
      user_type: data.user_type,
      phone: data.phone,
    };
    axios
      .post(API_URL + "/register", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `register${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 101) {
          notify("Dodano użytkownika");
          //window.location = "/login";
        }
      })
      .catch(function (error) {
        if (error.toString() === "User already exists")
          notify("Invalid email or password");
      });
  };
  const watchShowUser1 = watch("User1", false);
  const watchShowUser2 = watch("User2", false);

  const options = [
    { value: "2", label: "Użytkownik 1", name: "User1" },
    { value: "3", label: "Użytkownik 2", name: "User2" },
  ];
  const toggleHover = () => {
    setHover(!hover);
  };
  const handleClickLogin = (e: any) => {
    setType("login");
    setRedirectLogin(true);
  };

  const classes = useStyles();

  if (types === "register") {
    if (hover) {
      linkStyle = staticStyles.linkStyleHover;
    } else {
      linkStyle = staticStyles.linkStyleNotHover;
    }
    return (
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <InputLabel id="user_type">Typ użytkownika</InputLabel>
                <Select
                  native
                  labelId="user_type"
                  id="user_type"
                  required
                  name="user_type"
                  variant="outlined"
                  fullWidth
                  inputRef={register}
                >
                  {options.map(function (item) {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="first_name"
                label="Imię"
                type="text"
                id="first_name"
                autoComplete="imie"
                inputRef={register}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="last_name"
                label="Nazwisko"
                type="text"
                id="last_name"
                autoComplete="last_name"
                inputRef={register}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                label="E-mail"
                type="email"
                id="email"
                autoComplete="current-email"
                inputRef={register}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="phone"
                label="Telefon"
                type="text"
                id="phone"
                autoComplete="current-phone"
                inputRef={register}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Hasło"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i,
                    message:
                      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                  },
                })}
                required
              />
              {errors.password && errors.password.message}
              {watchShowUser1 && (
                <>
                  <input type="number" name="age" />
                </>
              )}
              {watchShowUser2 && (
                <>
                  <input type="text" name="age" />
                </>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Zarejestruj
              </Button>
            </form>
            <Grid item>
              <Link
                style={linkStyle}
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
                onClick={handleClickLogin}
                to={{ pathname: "/login" }}
              >
                {"Masz już konto? Zaloguj się"}
              </Link>
            </Grid>
            <Notification />
          </div>
        </Container>
      </div>
    );
  } else {
    // return <Redirect push to="/login" />;
    return { MyLoginPage };
  }
}
