import $ from "jquery";
import React, { Component, createRef } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
//import { makeStyles } from "@material-ui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

//var formData = ""
const staticStyles = {
  titleGroup: {
    marginTop: "1.5em",
    marginBottom: "1em",
    fontSize: "1.8em",
    fontWeight: "bold" as "bold",
  },
  createGroupInfo: {
    color: "#aaa",
    marginTop: "3em",
    marginBottom: "1em",
  },
  groupImage: {
    marginLeft: "3rem",
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    marginBottom: "1em",
  },
  groupTitle: {
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal" as "normal",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    alignSelf: "center",
    marginLeft: "2em",
  },
  buttonPrimary: {
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    marginTop: "1em",
    marginBottom: "2rem",
    float: "inline-end" as "inline-end",
    width: "14em",
  },
  buttonSecondary: {
    width: "9rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    height: "1.8em",
    textAlign: "center" as "center",
    marginTop: "0.8em",
    marginLeft: "0.6em",
  },
  imageFieldResize: {
    width: "8rem",
    height: "8em",
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
  paperStyleInput: {
    borderColor: "rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  labelInfo: {
    display: "inline-block",
    backgroundColor: "#01C09E",
    color: "white",
    fontSize: "0.875rem",
    fontWeight: "bold" as "bold",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    padding: "0.5rem",
    borderRadius: "0.3rem",
    cursor: "pointer",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    marginLeft: "0.5rem",
    boxShadow:
      " 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  inputValues: {
    display: "none",
  },
  labelInfoLogo: {
    position: "absolute" as "absolute",
    transformOrigin: "top left",
    transform: "translate(14px, -6px) scale(0.75)",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0",
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal" as "normal",
    lineHeight: "1",
    letterSpacing: "0.00938em",
    backgroundColor: "#fff",
    paddingLeft: "0.3em",
    width: "5.5em",
  },
  paperOutlineLogo: {
    height: "3.2em",
    borderColor: "rgba(0, 0, 0, 0.25)",
    marginTop: "1.8em",
  },
  fileNameText: {
    marginTop: "1em",
    marginLeft: "1em",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.57)",
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface FormEditorNotify {
  formBuilder: any;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
  showNotifyErrorEmpty: boolean;
  input_name: any;
}

class CreateInput extends Component<FormEditorNotify> {
  state: FormEditorNotify;
  constructor(props: any) {
    super(props);
    this.state = {
      input_name: "",
      formBuilder: undefined,
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      showNotifyErrorEmpty: false,
    };
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  fb = createRef<HTMLDivElement>();

  componentDidMount() {
    var options = {
      i18n: {
        override: {
          "en-US": {
            addOption: "Dodaj opcję +",
            allFieldsRemoved: "Wszystkie pliki zostały usunięte.",
            allowMultipleFiles: "Pozwól użytkownikom na upload wielu plików",
            autocomplete: "Autouzupełnianie",
            button: "Przycisk",
            cannotBeEmpty: "Pole nie może być puste",
            checkboxGroup: "Checkbox",
            className: "Class",
            clearAllMessage: "Czy chcesz wyczyścić wszystkie pola?",
            clear: "Czyść",
            close: "Zamknij",
            content: "Treść",
            copy: "Kopiuj do schowka",
            copyButton: "&#43;",
            copyButtonTooltip: "Kopiuj",
            dateField: "Wybierz date",
            description: "Tekst pomocniczy",
            descriptionField: "Opis",
            devMode: "Tryb developerski",
            editNames: "Edytuj nazwy",
            editorTitle: "Elementy formularza",
            editXML: "Edytuj XML",
            enableOther: "Włącz &quot;Inne&quot;",
            enableOtherMsg:
              "Pozwól użytkownikom wprowadzać wartości spoza listy",
            fieldVars: "Zmienne pola",
            fieldNonEditable: "To pole nie może być edytowane.",
            fieldRemoveWarning: "Czy na pewno chcesz usunąć to pole?",
            fileUpload: "Upload plików",
            formUpdated: "Zaktualizowano formularz",
            getStarted: "Przeciągnij tutaj elementy z prawego menu",
            header: "Nagłówek",
            hide: "Edytuj",
            hidden: "Ukryte pole",
            inline: "W jenej lini",
            inlineDesc: "Wyświetl {type} w lini",
            label: "Label",
            labelEmpty: "Label nie może być pusty",
            limitRole: "Ogranicz dostęp tylko dla wybranych grup użytkowników:",
            mandatory: "Obowiązkowy",
            maxlength: "Maksymalna długość",
            minOptionMessage: "To pole wymaga co majmniej dwóch opcji",
            minSelectionRequired: "Minimanla ilość wybranych opcji: {min}",
            multipleFiles: "Wiele plików",
            name: "Nazwa",
            no: "Nie",
            noFieldsToClear: "Nie można usunąć zawartości tego pola",
            number: "Liczba",
            off: "Wyłączony",
            on: "Włączony",
            option: "Opcja",
            options: "Opcje",
            optional: "opcjonalny",
            optionLabelPlaceholder: "Label",
            optionValuePlaceholder: "Wartość",
            optionEmpty: "Wymagana wartość",
            other: "Inny",
            paragraph: "Tekst nieedytowalny",
            placeholder: "Placeholder",
            "placeholder.value": "Wartość",
            "placeholder.label": "Label",
            "placeholder.text": "",
            "placeholder.textarea": "",
            "placeholder.email": "Podaj swój adres e-mail",
            "placeholder.placeholder": "",
            "placeholder.className": "klasy muszą być oddzielone spacjami",
            "placeholder.password": "Wprowadź swoje hasło",
            preview: "Poprzedni",
            radioGroup: "Radio Group",
            radio: "Radio",
            removeMessage: "Usuń element",
            removeOption: "Usuń opcję",
            remove: "&#215;",
            required: "Wymagany",
            richText: "Rich Text Editor",
            roles: "Dostęp",
            rows: "Wiersze",
            save: "Zapisz",
            selectOptions: "Opcje",
            select: "Wybierz",
            selectColor: "Wybierz kolor",
            selectionsMessage: "Pozwól na wielokrotny wybór",
            size: "Rozmiar",
            "size.xs": "Bardzo mały",
            "size.sm": "Mały",
            "size.m": "Domyślny",
            "size.lg": "Duży",
            style: "Styl",
            styles: {
              btn: {
                default: "Domyślny",
                danger: "Niebezpieczeństwo",
                info: "Informacyjny",
                primary: "Primary",
                success: "Sukcess",
                warning: "Ostrzeżenie",
              },
            },
            subtype: "Typ",
            text: "Pole tekstowe",
            textArea: "Duże pole tekstowe",
            toggle: "Przełącz",
            warning: "Ostrzeżenie!",
            value: "Wartość",
            viewJSON: "[{&hellip;}]",
            viewXML: "&lt;/&gt;",
            yes: "Tak",
          },
        },
      },
      disableFields: [
        "autocomplete",
        "button",
        "header",
        "radio-group",
        "hidden",
      ],
      editOnAdd: true,
      controlOrder: [
        "paragraph",
        "text",
        "textarea",
        "number",
        "select",
        "file",
        "checkbox-group",
        "date",
      ],
      disabledActionButtons: ["clear", "save", "data"],
      disabledSubtypes: {
        textarea: ["quill"],
        file: ["fineuploader"],
      },
      disabledAttrs: ["access"],
      disabledFieldButtons: {
        text: ["copy"],
        textarea: ["copy"],
        number: ["copy"],
        select: ["copy"],
        file: ["copy"],
        date: ["copy"],
        "checkbox-group": ["copy"],
      },
      fieldRemoveWarn: true,
      onAddField: function (fieldId) {
        const allField = Array.from(
          document.getElementsByClassName(
            "input-control"
          ) as HTMLCollectionOf<HTMLElement>
        );
        allField.forEach((element) => {
          element.style.display = "none";
        });
      },
      typeUserEvents: {
        paragraph: {
          onremove: function () {
            const allField = Array.from(
              document.getElementsByClassName(
                "input-control"
              ) as HTMLCollectionOf<HTMLElement>
            );
            allField.forEach((element) => {
              element.style.display = "block";
            });
          },
        },
        text: {
          onremove: function () {
            const allField = Array.from(
              document.getElementsByClassName(
                "input-control"
              ) as HTMLCollectionOf<HTMLElement>
            );
            allField.forEach((element) => {
              element.style.display = "block";
            });
          },
        },
        textarea: {
          onremove: function () {
            const allField = Array.from(
              document.getElementsByClassName(
                "input-control"
              ) as HTMLCollectionOf<HTMLElement>
            );
            allField.forEach((element) => {
              element.style.display = "block";
            });
          },
        },
        number: {
          onremove: function () {
            const allField = Array.from(
              document.getElementsByClassName(
                "input-control"
              ) as HTMLCollectionOf<HTMLElement>
            );
            allField.forEach((element) => {
              element.style.display = "block";
            });
          },
        },
        select: {
          onremove: function () {
            const allField = Array.from(
              document.getElementsByClassName(
                "input-control"
              ) as HTMLCollectionOf<HTMLElement>
            );
            allField.forEach((element) => {
              element.style.display = "block";
            });
          },
        },
        file: {
          onremove: function () {
            const allField = Array.from(
              document.getElementsByClassName(
                "input-control"
              ) as HTMLCollectionOf<HTMLElement>
            );
            allField.forEach((element) => {
              element.style.display = "block";
            });
          },
        },
        "checkbox-group": {
          onremove: function () {
            const allField = Array.from(
              document.getElementsByClassName(
                "input-control"
              ) as HTMLCollectionOf<HTMLElement>
            );
            allField.forEach((element) => {
              element.style.display = "block";
            });
          },
        },
        date: {
          onremove: function () {
            const allField = Array.from(
              document.getElementsByClassName(
                "input-control"
              ) as HTMLCollectionOf<HTMLElement>
            );
            allField.forEach((element) => {
              element.style.display = "block";
            });
          },
        },
      },
      typeUserAttrs: {
        file: {
          chooseFile: {
            label: "Wybierz typ pliku",
            type: "select",
            value: "file_type",
            options: ["Other files", "Images", "PDF"],
          },
        },
      },
    };

    const fbEditor = document.getElementById("fb-editor");
    const formBuilder2 = $(fbEditor).formBuilder(options);

    this.setState({ formBuilder: formBuilder2 });
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleTextChange(event: any) {
    this.setState({ input_name: event.target.value });
  }

  handleClickSave() {
    const fbEditor = document.getElementById("fb-editor");

    var payload = {
      user_id: localStorage.getItem("id"),
      privileges: localStorage.getItem("privileges"),
      input_name: this.state.input_name,
      input_data: this.state.formBuilder.actions.getData("json", true),
    };
    axios
      .post(API_URL + "/addinput", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `addinput${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 125) {
          this.setState({ showNotify: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getinputlist";
          });
        }
        if (response.data.code === 1029) {
          this.setState({ showNotifyBeta: true });
          // this.sleep(2000).then(() => {
          //     window.location.href = "/getinputlist";
          // })
        }
        if (response.data.code === 1030) {
          this.setState({ showNotifyErrorEmpty: true });
          // this.sleep(2000).then(() => {
          //     window.location.href = "/getinputlist";
          // })
        }
        if (response.data.code === 1016) {
          this.setState({ showNotifyError: true });
          this.sleep(2000).then(() => {
            window.location.href = "/getinputlist";
          });
        }
      })
      .catch(function (error) {});
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showNotify: false });
    this.setState({ showNotifyBeta: false });
    this.setState({ showNotifyError: false });
    this.setState({ showNotifyErrorEmpty: false });
  }

  valueGroupValidation() {
    if (this.state.input_name == "") {
      return (
        <TextField
          error
          variant="outlined"
          margin="normal"
          fullWidth
          helperText="Pole nie może być puste"
          name="input_name"
          value={this.state.input_name}
          label="Nazwa pola"
          type="text"
          id="input_name"
          autoComplete="input_name"
          onChange={this.handleTextChange}
        />
      );
    } else {
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="input_name"
          value={this.state.input_name}
          label="Nazwa pola"
          type="text"
          id="input_name"
          autoComplete="input_name"
          onChange={this.handleTextChange}
        />
      );
    }
  }
  returnButton() {
    if (this.state.input_name == "") {
      return (
        <Button
          disabled
          id="saveForm"
          variant="contained"
          style={staticStyles.buttonPrimary}
          color="primary"
        >
          Stwórz pole
        </Button>
      );
    } else {
      return (
        <Button
          id="saveForm"
          variant="contained"
          style={staticStyles.buttonPrimary}
          color="primary"
          onClick={this.handleClickSave}
        >
          Stwórz pole
        </Button>
      );
    }
  }

  render() {
    const {
      showNotify,
      showNotifyBeta,
      showNotifyError,
      showNotifyErrorEmpty,
    } = this.state;
    const useStyles = {
      snackbar: {
        width: "100%",
      },
    };

    const NOTIFY = (
      <div>
        <Snackbar
          open={showNotify}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zmiany zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={showNotifyBeta}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">Takie pole już istnieje</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={showNotifyError}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROREMPTY = (
      <div>
        <Snackbar
          open={showNotifyErrorEmpty}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">Błąd: wypełnij wszystkie pola</Alert>
        </Snackbar>
      </div>
    );
    return (
      <div>
        <Typography style={staticStyles.titleGroup}>Nowe pole</Typography>
        <form>
          {this.valueGroupValidation()}
          {/* <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        name="input_name"
                        label="Nazwa pola"
                        type="text"
                        id="input_name"
                        autoComplete="input_name"
                    /> */}
          <div id="fb-editor" ref={this.fb} />
          {this.returnButton()}
          {/* <Button onClick={this.handleClickSave} id="saveForm" variant="contained" color="primary">
                        Stwórz szablon</Button> */}
        </form>
        {NOTIFY}
        {NOTIFYBETA}
        {NOTIFYERROR}
        {NOTIFYERROREMPTY}
      </div>
    );
  }
}

export default CreateInput;
