import React from 'react';
import { Route } from 'react-router-dom';
import { Authenticated } from 'react-admin';

import Register from './Register';
import resetPass from './resetPass';
import FormEditor from './formBuilder/formEditor';
import FormBuilder from './formBuilder/formBuilder';
import EditUser from './users/editUser';
import CreateGroup from './groups/CreateGroup';
import EditGroup from './groups/EditGroup';
import GroupStats from './groups/groupstats';
import EditProfile from './users/profile';
import ShowForm from './formBuilder/showForm';
import { UserView } from './users/userView';
import EditInput from './library/editInput';
import CreateInput from './library/addInput';
import DashboardData from './dashboard/dashboard';
import CreateOffer from './offers/createOffers';
import ShowOffer from './offers/showOffer';
import EditOffer from './offers/editOffer';
import MyPdfViewer from './offers/pdfRender';
import {GroupUserList} from './groups/groupUsers'


const customRoutes = [
    <Route exact path="/getuser/:token/:id" render={props =>
        <Authenticated>
            <UserView {...props} />
        </Authenticated>} />,
    <Route exact path="/register" component={Register} noLayout />,
    <Route exact path="/resetPass" component={resetPass} noLayout />,
    <Route exact path="/profile" render={props =>
        <Authenticated>
            <EditProfile {...props} />
        </Authenticated>
    } />,
    <Route path="/createForm" render={props =>
        <Authenticated>
            <FormBuilder {...props} />
        </Authenticated>
    } />,
    <Route path="/formEditor/:id" render={props =>
        <Authenticated>
            <FormEditor {...props} />
        </Authenticated>
    } />,
    <Route path="/showForm/:id" render={props =>
        <Authenticated>
            <ShowForm {...props} />
        </Authenticated>
    } />,
    <Route exact path="/edituser/:id" render={props =>
        <Authenticated>
            <EditUser {...props} />
        </Authenticated>
    } />,
    <Route path="/creategroup" render={props =>
        <Authenticated>
            <CreateGroup {...props} />
        </Authenticated>
    } />,
    <Route exact path="/editgroup/:id" render={props =>
        <Authenticated>
            <EditGroup {...props} />
        </Authenticated>
    } />,
    <Route exact path="/editgroup/:id" render={props =>
        <Authenticated>
            <EditGroup {...props} />
        </Authenticated>
    } />,
    <Route exact path="/getdashboards/:id" render={props =>
        <Authenticated>
            <DashboardData {...props} />
        </Authenticated>
    } />,
    <Route exact path="/createinput" render={props =>
        <Authenticated>
            <CreateInput {...props} />
        </Authenticated>
    } />,
    <Route exact path="/editinput/:id" render={props =>
        <Authenticated>
            <EditInput {...props} />
        </Authenticated>
    } />,
    <Route exact path="/createoffer" render={props =>
        <Authenticated>
            <CreateOffer {...props} />
        </Authenticated>
    } />,
    <Route exact path="/createoffer/:id" render={props =>
        <Authenticated>
            <CreateOffer {...props} />
        </Authenticated>
    } />,
    <Route path="/showoffer/:id" render={props =>
        <Authenticated authParams={{ foo: props.match.params.id }}>
            <ShowOffer {...props} />
        </Authenticated>
    } />,
    <Route path="/editoffer/:id" render={props =>
        <Authenticated>
            <EditOffer {...props} />
        </Authenticated>

    } />,
    <Route path="/showfile/:id" render={props =>
        <Authenticated>
            <MyPdfViewer {...props} />
        </Authenticated>

    } />,
    <Route path="/groupusers/:id" render={props =>
        <Authenticated>
            <GroupUserList {...props} />
        </Authenticated>

    } />,
];

export default customRoutes;
