import $ from "jquery";
import React, { Component, createRef } from "react";
import axios from "axios";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import ClipLoader from "react-spinners/ClipLoader";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

window.jQuery = $;
window.$ = $;
const drawerWidth = 240;

require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");

var formData = "";
var id;
var showForm;

const styles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    marginLeft: 12,
  },
  toolbar: {
    paddingRight: 1,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "7",
    [theme.breakpoints.up("sm")]: {
      width: "9",
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: "4",
    paddingBottom: "4",
  },
  paper: {
    padding: "2",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  spinner: {
    flex: "1",
    alignSelf: "center",
  },
  card: {
    minWidth: 275,
    maxWidth: 500,
  },
  cardtitle: {
    fontSize: 14,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface PropsInter {
  match: any;
}

interface FormEditorNotify {
  isLoading: boolean;
  formData: any;
  formName: any;
  id: any;
  styles: any;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
  showForm: any;
}

class ShowForm extends Component<FormEditorNotify & PropsInter> {
  state: FormEditorNotify;
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      formData: "",
      showForm: undefined,
      formName: undefined,
      id: this.props.match.params.id,
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      styles: styles,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  fb = createRef<HTMLDivElement>();
  componentDidMount() {
    var that = this;

    axios
      .get(
        API_URL +
          `/formscheme?user_id=${localStorage.getItem("id")}&form_id=${
            this.state.id
          }`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `formscheme${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        id = this.state.id;
        if (response.data.code === 111) {
          this.setState({ formData: response.data.data.form_data });
          this.setState({ isLoading: false });
          this.setState({ formName: response.data.data.form_name });
        }
      })
      .catch(function (error) {
        that.setState({ formData: "" });
        that.setState({ isLoading: false });
        that.setState({ formName: "" });
      });
  }

  handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showNotify: false });
    this.setState({ showNotifyBeta: false });
    this.setState({ showNotifyError: false });
  }
  renderForm() {
    $("#showForm").formRender({
      formData: this.state.formData,
      dataType: "json",
    });
  }

  render() {
    const { isLoading, showNotify, showNotifyBeta, showNotifyError, styles } =
      this.state;
    const useStyles = {
      snackbar: {
        width: "100%",
      },
      spinner: {
        flex: 1,
        alignSelf: "center",
      },
    };

    const NOTIFY = (
      <div>
        <Snackbar
          open={showNotify}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zmiany zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={showNotifyBeta}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Nowy szablon zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={showNotifyError}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );

    while (isLoading && this.state.formData != null) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={styles.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }
    return (
      <>
        <Card style={{ maxWidth: "650px" }}>
          <CardContent>
            <Typography style={{ fontSize: 20, marginBottom: 20 }}>
              {this.state.formName}
            </Typography>
            <div id="showForm"></div>
          </CardContent>
        </Card>
        {NOTIFY}
        {NOTIFYBETA}
        {NOTIFYERROR}
        {this.renderForm()}
      </>
    );
  }
}

export default ShowForm;
