// in src/users.js
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  EditButton,
  BooleanInput,
  useRefresh,
  TabbedForm,
  FormTab,
} from "react-admin";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ClipLoader from "react-spinners/ClipLoader";
import Fade from "@material-ui/core/Fade";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { UserView } from "./userView";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";
// import {UsersListOne} from './usersView'

const staticStyles = {
  actionIcons: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw",
    cursor: "pointer",
  },
  userImage: {
    marginLeft: "3rem",
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    marginBottom: "0.5em",
    marginTop: "0.5em",
  },
  userTitle: {
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal" as "normal",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    alignSelf: "center",
    marginLeft: "2em",
  },
  buttonDialogLeft: {
    color: "#01C09E",
    fontWeight: "bold" as "bold",
  },
  buttonDialogRight: {
    color: "#01C09E",
    fontWeight: "bold" as "bold",
    marginLeft: "0.7em",
  },
  titleBarInfo: {
    marginTop: "1em",
    marginBottom: "1em",
    fontSize: "2em",
    fontWeight: "bold" as "bold",
  },
};
const arrayDict = {
  0: "Użytkownik_1",
  1: "Użytkownik_2",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ClearSearch = (props, value) => {
  if (props.location == null || props.location.search != "") {
    props.location.search = props.location.search.replace(
      encodeURI(arrayDict[value == 0 ? 1 : 0]),
      encodeURI(arrayDict[value == 0 ? 0 : 1])
    );
  } else {
    props.location.search = encodeURI(`?filter=${arrayDict[value]}`);
  }
  return props;
};

export const UserList = ({ permissions, ...props }) => {
  const [value, setValue] = React.useState(0);
  const refresh = useRefresh();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    props = ClearSearch(props, value);
    setValue(newValue);
  };
  const GenerateView = () => {
    props = ClearSearch(props, value);
    return (
      <>
        <Typography style={staticStyles.titleBarInfo}>Użytkownicy</Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: "#01C09E" } }}
        >
          <Tab label="Użytkownicy 1" />
          <Tab label="Użytkownicy 2" />
        </Tabs>
        <List
          title="SELECTRIN"
          filters={<UserFilter value={value} />}
          filterDefaultValues={{ privilege: arrayDict[value] }}
          exporter={false}
          bulkActionButtons={false}
          {...props}
        >
          {isSmall ? (
            <Datagrid>
              <TextField source="name" label="Nazwa" />
              <TextField source="privilege" label="Typ użytkownika" />
              <ButtonActionsGroup />
            </Datagrid>
          ) : (
            <Datagrid>
              <ImageData source="name" label="" sortable={false} />
              <UserName source="name" label="Nazwa użytkownika" />
              <TextField source="created_at" label="Data dołaczenia" />
              <TextField source="email" label="Email" />
              <TextField source="privilege" label="Typ użytkownika" />
              <TextField source="phone_num" label="Numer telefonu" />
              <ButtonActionsGroup />
              {/* <EditUserButton /> */}
            </Datagrid>
          )}
        </List>
      </>
    );
  };
  const isSmall = useMediaQuery("(max-width:1000px)");
  return <>{GenerateView()}</>;
};

const ImageData = (record: any) => {
  return (
    <Grid container direction="row">
      <img src={record.record.avatar} alt="" style={staticStyles.userImage} />
    </Grid>
  );
};
const UserName = (record: any) => {
  return (
    <Grid container direction="row">
      <Typography style={staticStyles.userTitle}>
        {record.record.name}
      </Typography>
    </Grid>
  );
};

const UserFilter = (props) => {
  return (
    <Filter {...props} setFilters={{ privilege: arrayDict[props.value] }}>
      <TextInput label="Szukaj po: Nazwie" source="name" alwaysOn />
    </Filter>
  );
};

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Email" source="email" type="email" />
    </SimpleForm>
  </Create>
);
const ButtonActionsGroup = (record: any) => {
  return (
    <Grid container direction="row">
      <ShowUser {...record} />
      <RemoveUser {...record} />
    </Grid>
  );
};

// const EditUserButton = (record) => (
//     <EditButton
//         component={Link}
//         to={{
//             pathname: `/edituser/${record.token}`,
//             state: { record: { token: record.token } },
//         }}
//     >
//     </EditButton>
// );

const ShowUser = (record: any) => {
  const handleClickShow = () => {
    window.location.href = `getuser/${
      arrayDict[0] == record.record.privilege
        ? "68530c1b1ea942a0b8178f1c2f92c0fe"
        : "3b228a2d119443f29e11f60e183a199c"
    }/${record.record.token}`;
  };
  return (
    <>
      <Tooltip title="Podgląd konta użytkownika">
        <VisibilityOutlinedIcon
          style={staticStyles.actionIcons}
          onClick={handleClickShow}
        />
      </Tooltip>
    </>
  );
};

const RemoveUser = (record: any) => {
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [sended, setSended] = React.useState(false);
  const [notSended, setNotSended] = React.useState(false);
  const [openSecond, setOpenSecond] = React.useState(false);

  const handleClickRemove = () => {
    setUserName(record.record.name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function asyncSleepFunct() {
    await sleep(2000);
    setOpen(false);
    axios
      .delete(
        API_URL +
          `/removeuser?privileges=${localStorage.getItem(
            "privileges"
          )}&user_id=${record.record.token}`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `removeuser${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        if (response.data.code === 129) {
          setSended(true);
        } else {
          setNotSended(true);
        }
      })
      .catch(function (error) {
        setNotSended(true);
      });
  }
  const handleCloseAll = () => {
    setOpen(false);
    setOpenSecond(false);
    setSended(false);
    setNotSended(false);
    window.location.href = "/getuserlist";
  };
  const DeleteButton = () => {
    setOpen(false);
    setOpenSecond(true);
    asyncSleepFunct();
  };
  const GetSpinner = () => {
    if (!sended && !notSended) {
      return (
        <>
          <Tooltip title="Usuń użytkownika">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>Przetwarzanie</DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "10em" }}
              >
                <Grid xs={12} item>
                  <ClipLoader size={"6em"} color={"#01C09E"} />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      );
    } else if (sended && !notSended) {
      return (
        <>
          <Tooltip title="Usuń użytkownika">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Użytkownik usunięty
              </DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "9em" }}
              >
                <Grid xs={12} item>
                  <Fade in={sended}>
                    <CheckCircleOutlineIcon
                      style={{ width: "6em", height: "6em", color: "#01C09E" }}
                    />
                  </Fade>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseAll}
                style={staticStyles.buttonDialogRight}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      return (
        <>
          <Tooltip title="Usuń użytkownika">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Wystąpił nieoczekiwany błąd? Jesteś administratorem?
              </DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "9em" }}
              >
                <Grid xs={12} item>
                  <Fade in={notSended}>
                    <CancelOutlinedIcon
                      style={{ width: "6em", height: "6em", color: "#ff0000" }}
                    />
                  </Fade>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseAll}
                style={staticStyles.buttonDialogRight}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  };
  if (openSecond) {
    return <>{GetSpinner()}</>;
  } else {
    return (
      <>
        <Tooltip title="Usuń użytkownika">
          <DeleteOutlinedIcon
            style={staticStyles.actionIcons}
            onClick={handleClickRemove}
          />
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Usuwanie Użytkownika"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz usunąć użytkownika {userName}? Usunięcie
              użytkownika jest permanentne i nie można będzie cofnąć operacji
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Nie
            </Button>
            <Button
              onClick={() => {
                DeleteButton();
              }}
              color="primary"
              autoFocus
            >
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};
