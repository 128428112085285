import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const MyLogoutButton = forwardRef((props: any, ref: any) => {
    const logout = useLogout();
    const history = useHistory();
    const handleClick = () => {
        logout();
        history.push('/login')
    }
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon /> Wyloguj
        </MenuItem>
    );
});

export default MyLogoutButton;