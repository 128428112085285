import React, { useMemo, useState } from "react";
import keyBy from "lodash/keyBy";
import {
  useQueryWithStore,
  Datagrid,
  TextField,
  sanitizeListRestProps,
  TopToolbar,
  Filter,
  fetchUtils,
  ImageField,
  TextInput,
  Pagination,
  Loading,
  ListContextProvider,
  useListController,
  useRefresh,
} from "react-admin";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "@material-ui/core";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { API_URL } from "../config";
import { getUserRole } from "../utils";

const staticStyles = {
  actionIcons: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw",
    cursor: "pointer",
  },
  imageField: {
    width: "2rem",
    height: "2rem",
  },
  groupImage: {
    marginLeft: "3rem",
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    marginBottom: "1em",
  },
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const GroupUserList = ({ permissions, ...props }) => {
  const isSmall = useMediaQuery("(max-width:1000px)");
  const [paramsProps, setProps] = React.useState(props);
  const [sended, setSended] = React.useState(false);
  const [notSended, setNotSended] = React.useState(false);
  const [page, setPage] = useState(1);
  const perPage = 10;
  const httpClient = fetchUtils.fetchJson;
  const refresh = useRefresh();
  const { data, total, loading, error } = useQueryWithStore({
    type: "getList",
    resource: "getgroupuserlist",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: [],
      group_id: paramsProps.match.params.id,
      user_id: localStorage.getItem("id"),
    },
  });
  const handleChangeTag = (privs, record) => {
    var payload = {
      user_id: localStorage.getItem("id"),
      granted_id: record.record.token,
      granted_privs: privs.value,
      privileges: localStorage.getItem("privileges"),
      group_id: record.props.match.params.id,
    };
    axios
      .post(API_URL + `/changeuserprivs`, payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `changeuserprivs${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        var responses = response;
        if (responses.data.code === 149) {
          setSended(true);
        } else {
          setNotSended(true);
        }
      })
      .catch(function (error) {
        setNotSended(true);
      });
  };
  const handleClose = () => {
    setSended(false);
    setNotSended(false);
    refresh();
  };

  const getDataFromApi = () => {};
  const ReturnChanChangePrivs = (record) => {
    if (record.record.can_change_privs) {
      var defaultVal: any[] = [];
      record.record.privs.map((item) => {
        if (item.label == record.record.privilege) {
          defaultVal = [item];
        }
      });
      return (
        <Select
          options={record.record.privs}
          defaultValue={defaultVal}
          onChange={(e) => handleChangeTag(e, record)}
        />
      );
    } else {
      return <Typography>{record.record.privilege}</Typography>;
    }
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR: {error}</p>;
  }
  // var listConstroller = useListController(props)
  const NOTIFY = (
    <div>
      <Snackbar
        open={sended}
        autoHideDuration={1000}
        //style={useState.snackbar}
        onClose={handleClose}
      >
        <Alert severity="success">Uprawnienia zostały zmienione</Alert>
      </Snackbar>
    </div>
  );
  const NOTIFYERROR = (
    <div>
      <Snackbar
        open={notSended}
        autoHideDuration={1000}
        //style={useState.snackbar}
        onClose={handleClose}
      >
        <Alert severity="error">
          Wystąpił nieoczekiwany błąd podczas zmiany uprawnień
        </Alert>
      </Snackbar>
    </div>
  );
  return (
    <>
      <ListContextProvider
        value={{
          data: keyBy(data, "id"),
          ids: data.map(({ id }) => id),
          total,
          page,
          perPage,
          setPage,
          currentSort: { field: "id", order: "ASC" },
          basePath: "/groupusers",
          resource: "getgroupuserlist",
          selectedIds: [],
        }}
      >
        <ListActions {...props} />
        <OffersFilter permissions={permissions} {...props} />
        {isSmall ? (
          <Datagrid>
            <TextField source="name" label="Nazwa oferty" />
            <ReturnChanChangePrivs label="Uprawnienia" props={props} />
            {/* <ButtonGroups /> */}
          </Datagrid>
        ) : (
          <Datagrid>
            <ImageField
              source="avatar"
              title="Avatar"
              sortable={false}
              cellClassName="groupImage"
            />
            <TextField source="name" label="Nazwa użytkownika" />
            <ReturnChanChangePrivs label="Uprawnienia" props={props} />
            <TextField source="created_at" label="Data dołączenia" />
            <TextField source="email" label="Email" />
            <TextField source="phone" label="Numer telefonu" />
          </Datagrid>
        )}
        <Pagination />
      </ListContextProvider>
      {NOTIFY}
      {NOTIFYERROR}
    </>
  );
};

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const userRole = useMemo(() => getUserRole(), []);

  if (userRole === "administrator" || userRole === "uzytkownik_1") {
    return (
      <TopToolbar {...sanitizeListRestProps(rest)}>
        <Button href="/createoffer" variant="contained" color="primary">
          NOWA OFERTA
        </Button>
      </TopToolbar>
    );
  } else {
    return <TopToolbar></TopToolbar>;
  }
};

const OffersFilter = ({ permissions, ...props }) => (
  <>
    <Filter {...props}>
      <TextInput label="Szukaj po: Nazwa" source="name" alwaysOn />
    </Filter>
  </>
);
