import React, { useMemo } from "react";
import {
  List,
  Datagrid,
  TextField,
  sanitizeListRestProps,
  TopToolbar,
  Filter,
  TextInput,
  BooleanInput,
} from "react-admin";

import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { stringify } from "query-string";
import MuiAlert from "@material-ui/lab/Alert";
import FilterNoneOutlinedIcon from "@material-ui/icons/FilterNoneOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import ShareDialog from "./shareDialog";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Fade from "@material-ui/core/Fade";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";
import { getUserRole } from "../utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface DataLabel {
  value: string;
  label: string;
  offer?: string;
  offerName?: string;
}
interface DataLabelTemp {
  name: string;
  token: string;
}

const useStyles = makeStyles({
  Datagrid: {},
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
});

const staticStyles = {
  actionIcons: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw",
    cursor: "pointer",
  },
  imageField: {
    width: "2rem",
    height: "2rem",
  },
  groupImage: {
    marginLeft: "3rem",
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    marginBottom: "1em",
  },
  groupTitle: {
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal" as "normal",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    alignSelf: "center",
    marginLeft: "2em",
  },
  snackbar: {
    width: "100%",
  },
  buttonDialogRight: {
    color: "#01C09E",
    fontWeight: "bold" as "bold",
    marginLeft: "0.7em",
  },
};

export const Offers = ({ permissions, ...props }) => {
  const isSmall = useMediaQuery("(max-width:1000px)");
  const vars: DataLabelTemp[] = [];
  const [isLoading, setIsLoading] = React.useState(true);
  const [tags, setTags] = React.useState(vars);
  const [sended, setSended] = React.useState(false);
  const [notSended, setNotSended] = React.useState(false);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const history = useHistory();

  const handleChangeTag = (event: any, newValue: number) => {
    UpdateStatus(event.value, event.offer);
  };
  const GetSelectField = (record: any) => {
    var arr: DataLabel[] = [];
    var defaultVal = {};
    var tags_temp = tags;
    const userRole = getUserRole();
    if (tags_temp === null || userRole === "administrator") {
      return <Typography>{record.record.status}</Typography>;
    } else {
      tags_temp.map((item) => {
        if (item.name == record.record.status) {
          defaultVal = { value: item.token, label: item.name };
        }
        arr.push({
          value: item.token,
          label: item.name,
          offer: record.record.token,
        });
      });
      return (
        <Select
          options={arr}
          defaultValue={defaultVal}
          onChange={handleChangeTag}
        />
      );
    }
  };
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const UpdateStatus = (tag: string, offer: string) => {
    var payload = {
      user_id: localStorage.getItem("id"),
      privileges: localStorage.getItem("privileges"),
      offer_token: offer,
      tag_token: tag,
    };
    axios
      .post(API_URL + `/updateofferstatus`, payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `updateofferstatus${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 134) {
          setSended(true);
          sleep(1000).then(() => {
            setIsLoading(true);
          });
        }
      })
      .catch(function (error) {
        setNotSended(true);
      });
  };
  const handleClose = () => {
    setSended(false);
    setNotSended(false);
    setOpenDuplicate(false);
  };

  const GetDataFromApi = () => {
    var payload = {
      user_id: localStorage.getItem("id"),
    };
    axios
      .get(API_URL + `/gettaglist?${stringify(payload)}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `gettaglist${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 135) {
          setTags(response.data.data);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };
  const ShareOffer = (record: any) => {
    return <ShareDialog offer={record.record.token} />;
  };
  const DuplicateOffer = (record: any) => {
    const [open, setOpen] = React.useState(false);
    const [userName, setUserName] = React.useState("");
    const [sended, setSended] = React.useState(false);
    const [notSended, setNotSended] = React.useState(false);
    const [openSecond, setOpenSecond] = React.useState(false);

    const handleClickRemove = () => {
      setUserName(record.record.offer_name);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    async function asyncSleepFunct() {
      await sleep(2000);
      setOpen(false);
      var payload = {
        user_id: localStorage.getItem("id"),
        privileges: localStorage.getItem("privileges"),
        form_id: record.record.token,
        form_name: record.record.offer_name,
      };
      axios
        .put(API_URL + `/addoffer`, payload, {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `addoffer${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        })
        .then((response) => {
          if (response.data.code === 138) {
            setSended(true);
          } else {
            setNotSended(true);
          }
        })
        .catch(function (error) {
          setNotSended(true);
        });
    }
    const handleCloseAll = () => {
      setOpen(false);
      setOpenSecond(false);
      setSended(false);
      setNotSended(false);
      window.location.href = "/getofferlist";
    };
    const DeleteButton = () => {
      setOpen(false);
      setOpenSecond(true);
      asyncSleepFunct();
    };
    const GetSpinner = () => {
      if (!sended && !notSended) {
        return (
          <>
            <Tooltip title="Usuń ofertę">
              <DeleteOutlinedIcon style={staticStyles.actionIcons} />
            </Tooltip>
            <Dialog
              open={openSecond}
              onClose={handleCloseAll}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <DialogContentText>Przetwarzanie</DialogContentText>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ width: "20em", height: "10em" }}
                >
                  <Grid xs={12} item>
                    <ClipLoader size={"6em"} color={"#01C09E"} />
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </>
        );
      } else if (sended && !notSended) {
        return (
          <>
            <Tooltip title="Usuń ofertę">
              <DeleteOutlinedIcon style={staticStyles.actionIcons} />
            </Tooltip>
            <Dialog
              open={openSecond}
              onClose={handleCloseAll}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Oferta usunięta
                </DialogContentText>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ width: "20em", height: "9em" }}
                >
                  <Grid xs={12} item>
                    <Fade in={sended}>
                      <CheckCircleOutlineIcon
                        style={{
                          width: "6em",
                          height: "6em",
                          color: "#01C09E",
                        }}
                      />
                    </Fade>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAll}
                  style={staticStyles.buttonDialogRight}
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      } else {
        return (
          <>
            <Tooltip title="Usuń pole">
              <DeleteOutlinedIcon style={staticStyles.actionIcons} />
            </Tooltip>
            <Dialog
              open={openSecond}
              onClose={handleCloseAll}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Wystąpił nieoczekiwany błąd? Jesteś administratorem?
                </DialogContentText>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ width: "20em", height: "9em" }}
                >
                  <Grid xs={12} item>
                    <Fade in={notSended}>
                      <CancelOutlinedIcon
                        style={{
                          width: "6em",
                          height: "6em",
                          color: "#ff0000",
                        }}
                      />
                    </Fade>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAll}
                  style={staticStyles.buttonDialogRight}
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }
    };
    if (openSecond) {
      return <>{GetSpinner()}</>;
    } else {
      return (
        <>
          <Tooltip title="Duplikuj">
            <FilterNoneOutlinedIcon
              style={staticStyles.actionIcons}
              onClick={handleClickRemove}
            />
          </Tooltip>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Duplikacja oferty"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Czy na pewno chcesz zduplikować ofertę `{userName}`?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Nie
              </Button>
              <Button
                onClick={() => {
                  DeleteButton();
                }}
                color="primary"
                autoFocus
              >
                Tak
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  };
  const ViewOffer = (record: any) => {
    const history = useHistory();

    const handleClickEdit = () => {
      history.push(`/showoffer/${record.record.token}`);
    };
    return (
      <Tooltip title="Podgląd">
        <VisibilityOutlinedIcon
          style={staticStyles.actionIcons}
          onClick={handleClickEdit}
        />
      </Tooltip>
    );
  };
  const EditOffer = (record: any) => {
    const history = useHistory();

    const handleClickEdit = () => {
      history.push(`/editoffer/${record.record.token}`);
    };
    return (
      <Tooltip title="Edytuj">
        <CreateOutlinedIcon
          style={staticStyles.actionIcons}
          onClick={handleClickEdit}
        />
      </Tooltip>
    );
  };

  const RemoveOffer = (record: any) => {
    const [open, setOpen] = React.useState(false);
    const [userName, setUserName] = React.useState("");
    const [sended, setSended] = React.useState(false);
    const [notSended, setNotSended] = React.useState(false);
    const [openSecond, setOpenSecond] = React.useState(false);

    const handleClickRemove = () => {
      setUserName(record.record.offer_name);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    async function asyncSleepFunct() {
      await sleep(2000);
      setOpen(false);
      axios
        .delete(
          API_URL +
            `/addoffer?privileges=${localStorage.getItem(
              "privileges"
            )}&form_id=${record.record.token}&user_id=${localStorage.getItem(
              "id"
            )}`,
          {
            headers: {
              Authorization: `Bearer ${bcrypt.hashSync(
                `addoffer${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
                10
              )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 112) {
            setSended(true);
          } else {
            setNotSended(true);
          }
        })
        .catch(function (error) {
          setNotSended(true);
        });
    }
    const handleCloseAll = () => {
      setOpen(false);
      setOpenSecond(false);
      setSended(false);
      setNotSended(false);
      window.location.href = "/getofferlist";
    };
    const DeleteButton = () => {
      setOpen(false);
      setOpenSecond(true);
      asyncSleepFunct();
    };
    const GetSpinner = () => {
      if (!sended && !notSended) {
        return (
          <>
            <Tooltip title="Usuń ofertę">
              <DeleteOutlinedIcon style={staticStyles.actionIcons} />
            </Tooltip>
            <Dialog
              open={openSecond}
              onClose={handleCloseAll}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <DialogContentText>Przetwarzanie</DialogContentText>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ width: "20em", height: "10em" }}
                >
                  <Grid xs={12} item>
                    <ClipLoader size={"6em"} color={"#01C09E"} />
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </>
        );
      } else if (sended && !notSended) {
        return (
          <>
            <Tooltip title="Usuń ofertę">
              <DeleteOutlinedIcon style={staticStyles.actionIcons} />
            </Tooltip>
            <Dialog
              open={openSecond}
              onClose={handleCloseAll}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Oferta usunięta
                </DialogContentText>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ width: "20em", height: "9em" }}
                >
                  <Grid xs={12} item>
                    <Fade in={sended}>
                      <CheckCircleOutlineIcon
                        style={{
                          width: "6em",
                          height: "6em",
                          color: "#01C09E",
                        }}
                      />
                    </Fade>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAll}
                  style={staticStyles.buttonDialogRight}
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      } else {
        return (
          <>
            <Tooltip title="Usuń pole">
              <DeleteOutlinedIcon style={staticStyles.actionIcons} />
            </Tooltip>
            <Dialog
              open={openSecond}
              onClose={handleCloseAll}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Wystąpił nieoczekiwany błąd? Jesteś administratorem?
                </DialogContentText>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ width: "20em", height: "9em" }}
                >
                  <Grid xs={12} item>
                    <Fade in={notSended}>
                      <CancelOutlinedIcon
                        style={{
                          width: "6em",
                          height: "6em",
                          color: "#ff0000",
                        }}
                      />
                    </Fade>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAll}
                  style={staticStyles.buttonDialogRight}
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }
    };
    if (openSecond) {
      return <>{GetSpinner()}</>;
    } else {
      return (
        <>
          <Tooltip title="Usuń ofertę">
            <DeleteOutlinedIcon
              style={staticStyles.actionIcons}
              onClick={handleClickRemove}
            />
          </Tooltip>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Usuwanie oferty"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Czy na pewno chcesz usunąć ofertę `{userName}`? Usunięcie oferty
                jest permanentne i nie można będzie cofnąć operacji
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Nie
              </Button>
              <Button
                onClick={() => {
                  DeleteButton();
                }}
                color="primary"
                autoFocus
              >
                Tak
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  };

  const ButtonGroups = (record: any) => {
    const userRole = getUserRole();

    if (userRole === "administrator" || userRole === "uzytkownik_1") {
      return (
        <Grid container direction="row">
          <ShareOffer {...record} />
          <DuplicateOffer {...record} />
          <ViewOffer {...record} />
          <EditOffer {...record} />
          <RemoveOffer {...record} />
        </Grid>
      );
    } else return <ViewOffer {...record} />;
  };
  const styles = useStyles();
  const NOTIFY = (
    <div>
      <Snackbar
        open={sended}
        autoHideDuration={1500}
        style={staticStyles.snackbar}
        onClose={handleClose}
      >
        <Alert severity="success">Status zmieniony</Alert>
      </Snackbar>
    </div>
  );
  const NOTIFYERROR = (
    <div>
      <Snackbar
        open={notSended}
        autoHideDuration={1500}
        style={staticStyles.snackbar}
        onClose={handleClose}
      >
        <Alert severity="error">Wystąpił błąd podczas zmiany statusu</Alert>
      </Snackbar>
    </div>
  );
  while (isLoading) {
    if (getUserRole() === "administrator") {
      GetDataFromApi();
    } else {
      setIsLoading(false);
    }
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "70vh" }}
      >
        <Grid xs={12} item>
          <ClipLoader size={45} css={styles.spinner} color={"#01C09E"} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <List
        title="SELECTRIN - Lista ofert"
        bulkActionButtons={false}
        exporter={false}
        actions={<ListActions />}
        {...props}
        filters={<OffersFilter permissions={permissions} {...props} />}
      >
        {isSmall ? (
          <Datagrid>
            <TextField source="offer_name" label="Nazwa oferty" />
            <TextField source="group_name" label="Grupa" />
            {/* <ButtonGroups /> */}
          </Datagrid>
        ) : (
          <Datagrid>
            <TextField source="offer_name" label="Nazwa oferty" />
            <TextField source="group_name" label="Grupa" />
            <GetSelectField label="Status" />
            <TextField source="modified_at" label="Data aktualizacji" />
            <TextField source="created_at" label="Data utworzenia" />
            <ButtonGroups />
          </Datagrid>
        )}
      </List>
      {NOTIFY}
      {NOTIFYERROR}
    </>
  );
};

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const userRole = useMemo(() => getUserRole(), []);

  if (userRole === "administrator" || userRole === "uzytkownik_1") {
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button href="/createoffer" variant="contained" color="primary">
          NOWA OFERTA
        </Button>
      </TopToolbar>
    );
  } else {
    return <TopToolbar></TopToolbar>;
  }
};

const OffersFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Szukaj po: Nazwa" source="offer_name" alwaysOn />
    <TextInput label="Szukaj po: Grupa" source="group_name" alwaysOn />
    <TextInput label="Szukaj po: Status" source="status" alwaysOn />
  </Filter>
);
