import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
//import DialogTitle from '@material-ui/core/DialogTitle';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
//import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ClipLoader from "react-spinners/ClipLoader";
import Fade from "@material-ui/core/Fade";
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import axios, { AxiosResponse } from "axios";
//import { stringify } from 'query-string';
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

export default function AlertDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [validateText, setValidateText] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [sended, setSended] = React.useState(false);
  const [permValue, setpermValue] = React.useState(
    "2971e9ca37de43f1826d0a62081ca93d"
  );
  const [email, setEmail] = React.useState(false);
  const changePrivs = props.changePrivs;
  const groupID = props.group;
  var privs = "2971e9ca37de43f1826d0a62081ca93d";
  var errorText = "Uzupełnij adres email";

  const handleAddToGroup = () => {
    setOpen(true);
  };
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const handleChange = (event: any) => {
    if (event.target.value.match(validEmailRegex)) {
      setValidateText(true);
      errorText = "";
      setEmail(event.target.value);
    } else {
      setValidateText(false);
      errorText = "Uzupełnij adres email";
    }
  };
  const GetSpinnerStatus = () => {
    if (!sended) {
      return (
        <>
          <Button
            style={staticStyles.buttonPrimary}
            variant="contained"
            onClick={handleAddToGroup}
          >
            Zaproś
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>Przetwarzanie</DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "10em" }}
              >
                <Grid xs={12} item>
                  <ClipLoader size={"6em"} color={"#01C09E"} />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      );
    } else {
      return (
        <>
          <Button
            style={staticStyles.buttonPrimary}
            variant="contained"
            onClick={handleAddToGroup}
          >
            Zaproś
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Zaproszenie zostało wysłane
              </DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "9em" }}
              >
                <Grid xs={12} item>
                  <Fade in={sended}>
                    <CheckCircleOutlineIcon
                      style={{ width: "6em", height: "6em", color: "#01C09E" }}
                    />
                  </Fade>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseAll}
                style={staticStyles.buttonDialogRight}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePermissionChange = (event: any) => {
    setpermValue(event.target.value);
    privs = event.target.value;
  };
  const handleCloseAll = (event: any) => {
    setSpinner(false);
    setSended(false);
    setOpen(false);
  };
  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function asyncSleepFunct() {
    await sleep(2000);
    setSended(true);
  }

  const sendInvitation = () => {
    setSpinner(true);

    var payload = {
      user_id: localStorage.getItem("id"),
      group_id: groupID,
      email: email,
      privilege_id: permValue,
    };
    axios
      .post(API_URL + "/addtogroup", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `addtogroup${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 107) {
          asyncSleepFunct();
        }
      })
      .catch(function (error) {});
  };
  const validateEmail = () => {
    if (!validateText) {
      return (
        <form noValidate autoComplete="off" style={{ marginBottom: "1em" }}>
          <TextField
            error
            autoFocus
            margin="dense"
            id="standard-error-helper-text"
            type="email"
            helperText={errorText}
            onChange={handleChange}
            fullWidth
          />
        </form>
      );
    } else {
      return (
        <form noValidate autoComplete="off" style={{ marginBottom: "1em" }}>
          <TextField
            autoFocus
            margin="dense"
            id="standard-error-helper-text"
            type="email"
            onChange={handleChange}
            fullWidth
          />
        </form>
      );
    }
  };
  const getValidateStyleButton = () => {
    return validateText
      ? staticStyles.buttonDialogRightOk
      : staticStyles.buttonDialogRightNotOk;
  };
  const changePermission = () => {
    if (changePrivs) {
      return (
        <>
          <DialogContentText>Wybierz uprawnienia użytkownika</DialogContentText>
          <Select value={permValue} autoFocus onChange={handlePermissionChange}>
            <MenuItem value="698f338fe4554d929cffdd98b5e7c84c">Twórca</MenuItem>
            <MenuItem value="b774642d44114e638f2c97cd1ae044ca">
              Zarządca
            </MenuItem>
            <MenuItem value="2971e9ca37de43f1826d0a62081ca93d">
              Zwykły użytkownik
            </MenuItem>
          </Select>
        </>
      );
    } else {
      return null;
    }
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  const staticStyles = {
    buttonPrimary: {
      width: "20vh",
      color: "#fff",
      backgroundColor: "#01C09E",
      fontWeight: "bold" as "bold",
    },
    buttonDialogLeft: {
      color: "#01C09E",
      fontWeight: "bold" as "bold",
    },
    buttonDialogRight: {
      color: "#01C09E",
      fontWeight: "bold" as "bold",
      marginLeft: "0.7em",
    },
    buttonDialogRightOk: {
      color: "#01C09E",
      fontWeight: "bold" as "bold",
      marginLeft: "0.7em",
    },
    buttonDialogRightNotOk: {
      color: "#C4C4C4",
      fontWeight: "bold" as "bold",
      marginLeft: "0.7em",
    },
    spinner: {
      flex: "1",
      alignSelf: "center",
    },
    actionIcons: {
      marginLeft: "0.5vw",
      marginRight: "0.5vw",
      cursor: "pointer",
    },
  };
  if (!spinner) {
    return (
      <div>
        <Button
          style={staticStyles.buttonPrimary}
          variant="contained"
          onClick={handleAddToGroup}
        >
          Zaproś
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
          <DialogContent>
            <DialogContentText>
              Wpisz adres E-mail osoby, którą chcesz zaprosić
            </DialogContentText>
            {validateEmail()}
            {changePermission()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={staticStyles.buttonDialogLeft}>
              Zamknij
            </Button>
            <Button
              disabled={!validateText}
              onClick={sendInvitation}
              style={getValidateStyleButton()}
            >
              Wyślij
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <>{GetSpinnerStatus()}</>;
  }
}
