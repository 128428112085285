import $ from "jquery";
import React, { Component, createRef, useState } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TextField from "@material-ui/core/TextField";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Grid from "@material-ui/core/Grid";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

var id;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface PropsNotify {
  match: any;
}
interface EditUserNotify {
  isLoading: boolean;
  id: any;
  firstname: any;
  lastname: any;
  company_name: any;
  phone: any;
  nip_num: any;
  post_code: any;
  city_name: any;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
}

class EditUser extends Component<EditUserNotify & PropsNotify> {
  state: EditUserNotify;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      id: this.props.match.params.id,
      firstname: "",
      lastname: "",
      company_name: "",
      phone: "",
      nip_num: "",
      post_code: "",
      city_name: "",
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
    };
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  fb = createRef();
  componentDidMount() {
    axios
      .get(
        API_URL +
          `/getuserdata?user_id=${
            this.state.id
          }&privileges=${localStorage.getItem("privileges")}`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `getuserdata${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        id = this.state.id;
        if (response.data.code === 117) {
          this.setState({ isLoading: false });
          this.setState({ firstname: response.data.data.firstname });
          this.setState({ lastname: response.data.data.lastname });
          this.setState({ phone: response.data.data.phone_num });
          this.setState({
            company_name: response.data.data.address.company_name,
          });
          this.setState({ nip_num: response.data.data.address.nip });
          this.setState({ post_code: response.data.data.address.zipcode });
          this.setState({ city_name: response.data.data.address.city });
          (document.getElementById("firstname") as HTMLInputElement).value =
            this.state.firstname(
              document.getElementById("lastname") as HTMLInputElement
            ).value =
            this.state.lastname(
              document.getElementById("phone") as HTMLInputElement
            ).value =
            this.state.phone(
              document.getElementById("company_name") as HTMLInputElement
            ).value =
            this.state.company_name(
              document.getElementById("nip_num") as HTMLInputElement
            ).value =
            this.state.nip_num(
              document.getElementById("post_code") as HTMLInputElement
            ).value =
            this.state.post_code(
              document.getElementById("city_name") as HTMLInputElement
            ).value =
              this.state.city_name;
        }
      })
      .catch(function (error) {});
  }

  handleClickSave() {
    var payload = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      phone: this.state.phone,
      company_name: this.state.company_name,
      nip: this.state.nip_num,
      zipcode: this.state.post_code,
      city: this.state.city_name,
      privileges: localStorage.getItem("privileges"),
      user_id: this.state.id,
    };
    axios
      .put(API_URL + "/getuserdata", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `getuserdata${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 116) {
          this.setState({ showNotify: true });
          window.location.href = "/getuserlist";
        } else {
          this.setState({ showNotifyError: true });
        }
      })
      .catch(function (error) {});
  }

  handleClose(event: any, reason: any) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showNotify: false });
    this.setState({ showNotifyBeta: false });
    this.setState({ showNotifyError: false });
  }

  render() {
    const { isLoading, showNotify, showNotifyBeta, showNotifyError } =
      this.state;
    const useStyles = {
      snackbar: {
        width: "100%",
      },
    };

    const NOTIFY = (
      <div>
        <Snackbar
          open={showNotify}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Zmiany zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={showNotifyBeta}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Nowy szablon zapisano pomyślnie</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={showNotifyError}
          autoHideDuration={3000}
          style={useStyles.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );

    const NOTHING = <div></div>;

    while (isLoading) {
      return (
        <div>
          <form>
            <h1>"Loading ..."</h1>
          </form>
        </div>
      );
    }
    return (
      <div>
        <Grid item xs={12} md={6}>
          <form>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="firstname"
              label="Imię"
              type="text"
              id="firstname"
              autoComplete="firstname"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="lastname"
              label="Nazwisko"
              type="text"
              id="lastname"
              autoComplete="lastname"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="phone"
              label="Telefon"
              type="text"
              id="phone"
              autoComplete="phone"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="company_name"
              label="Nazwa firmy"
              type="text"
              id="company_name"
              autoComplete="company_name"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="nip_num"
              label="NIP"
              type="text"
              id="nip_num"
              autoComplete="nip_num"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="post_code"
              label="Kod pocztowy"
              type="text"
              id="post_code"
              autoComplete="post_code"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="city_name"
              label="Miasto"
              type="text"
              id="city_name"
              autoComplete="city_name"
            />
            <Button
              id="saveForm"
              variant="contained"
              color="primary"
              onClick={this.handleClickSave}
            >
              Edytuj użytkownika
            </Button>
          </form>
          {NOTIFY}
          {NOTIFYBETA}
          {NOTIFYERROR}
        </Grid>
      </div>
    );
  }
}

export default EditUser;
