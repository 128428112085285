import React, { useMemo } from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  BooleanInput,
  BooleanField,
  sanitizeListRestProps,
  TopToolbar,
} from "react-admin";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import axios from "axios";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlertDialog from "./dialog";
import { useMediaQuery } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import ClipLoader from "react-spinners/ClipLoader";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Fade from "@material-ui/core/Fade";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { API_URL } from "../config";
import { getRoleFromPrivilegesToken } from "../utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  Datagrid: {},
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
});

const staticStyles = {
  actionIcons: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw",
    cursor: "pointer",
  },
  imageField: {
    width: "2rem",
    height: "2rem",
  },
  groupImage: {
    marginLeft: "3rem",
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    marginBottom: "1em",
  },
  groupTitle: {
    fontSize: "0.875rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal" as "normal",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    alignSelf: "center",
    marginLeft: "2em",
  },
  buttonDialogRight: {
    color: "#01C09E",
    fontWeight: "bold" as "bold",
    marginLeft: "0.7em",
  },
  snackbar: {
    width: "100%",
  },
};

const initialState = {
  action: false,
};

export const GroupList = ({ permissions, ...props }) => {
  const classes = useStyles();
  const isSmall = useMediaQuery("(max-width:1000px)");
  const [sended, setSended] = React.useState(false);
  const [sended2, setSended2] = React.useState(false);
  const [notSended, setNotSended] = React.useState(false);

  const ButtonIntrested = (record: any) => {
    if (record.record.is_interested === false)
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleIntrestedGroup(record);
          }}
        >
          ZAINTERESOWANY
        </Button>
      );
    else
      return (
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            handleIntrestedGroup(record);
          }}
        >
          Niezaintersowany
        </Button>
      );
  };

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleIntrestedGroup = (record) => {
    var payload = {
      privileges: localStorage.getItem("privileges"),
      group_id: record.record.token,
      user_id: localStorage.getItem("id"),
    };

    axios
      .post(API_URL + "/markgroup", payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `markgroup${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        if (response.data.code === 140) {
          setSended(true);
          sleep(2000).then(() => {
            window.location.href = "/getgrouplist";
          });
        }
        if (response.data.code === 141) {
          setSended2(true);
          sleep(2000).then(() => {
            window.location.href = "/getgrouplist";
          });
        }
      })
      .catch(function (error) {
        setNotSended(true);
      });
  };

  const handleClose = () => {
    setSended(false);
    setSended2(false);
    setNotSended(false);
  };
  const NOTIFY = (
    <div>
      <Snackbar
        open={sended}
        autoHideDuration={3000}
        style={staticStyles.snackbar}
        onClose={handleClose}
      >
        <Alert severity="success">Wysłano zaproszenie do admina grupy</Alert>
      </Snackbar>
    </div>
  );
  const NOTIFY2 = (
    <div>
      <Snackbar
        open={sended2}
        autoHideDuration={3000}
        style={staticStyles.snackbar}
        onClose={handleClose}
      >
        <Alert severity="success">Anulowano zaproszenie do grupy</Alert>
      </Snackbar>
    </div>
  );
  const NOTIFYERROR = (
    <div>
      <Snackbar
        open={notSended}
        autoHideDuration={3000}
        style={staticStyles.snackbar}
        onClose={handleClose}
      >
        <Alert severity="error">Wystąpił błąd </Alert>
      </Snackbar>
    </div>
  );

  const role = useMemo(() => {
    return permissions && getRoleFromPrivilegesToken(permissions);
  }, [permissions]);

  return (
    <>
      <List
        component="div"
        title="SELECTRIN - Grupy"
        filters={<GroupFilter permissions={permissions} {...props} />}
        bulkActionButtons={false}
        exporter={false}
        actions={<ListActions permissions={permissions} {...props} />}
        {...props}
      >
        {isSmall ? (
          <Datagrid {...props} className={classes.Datagrid}>
            <ImageData source="group_name" label="" sortable={false} />
            <GroupName source="group_name" label="Nazwa grupy" />
            <BooleanField
              source="is_verified"
              label="Zweryfikowano"
              valueLabelTrue="Grupa jest zweryfikowana"
              valueLabelFalse="Grupa nie jest zweryfikowana"
            />
            {role !== "uzytkownik_2" && (
              <ButtonGroups permissions={permissions} {...props} />
            )}
            {role === "uzytkownik_2" && <ButtonIntrested {...props} />}
          </Datagrid>
        ) : (
          <Datagrid {...props} className={classes.Datagrid}>
            <ImageData source="group_name" label="" sortable={false} />
            <GroupName source="group_name" label="Nazwa grupy" />
            {role !== "uzytkownik_2" && (
              <BooleanField
                source="is_verified"
                label="Zweryfikowano"
                valueLabelTrue="Grupa jest zweryfikowana"
                valueLabelFalse="Grupa nie jest zweryfikowana"
              />
            )}
            {role !== "uzytkownik_2" && (
              <TextField source="group_author" label="Autor" />
            )}
            {role !== "uzytkownik_2" && (
              <TextField source="create_date" label="Data utworzenia" />
            )}
            {role !== "uzytkownik_2" && (
              <TextField source="users_qty" label="Ilość członków" />
            )}
            {role !== "uzytkownik_2" && (
              <TextField source="interested_qty" label="Zainteresowani grupą" />
            )}
            {role !== "uzytkownik_2" && (
              <ButtonGroups permissions={permissions} {...props} />
            )}
            {role === "uzytkownik_2" && <ButtonIntrested {...props} />}
          </Datagrid>
        )}
      </List>
      {NOTIFY}
      {NOTIFY2}
      {NOTIFYERROR}
    </>
  );
};

const ListActions = ({ permissions, ...props }) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const role = useMemo(() => {
    return permissions && getRoleFromPrivilegesToken(permissions);
  }, [permissions]);

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {role === "administrator" && (
        <Button href="/creategroup" color="primary" startIcon={<AddIcon />}>
          STWÓRZ
        </Button>
      )}
      {role === "uzytkownik_1" && (
        <Button href="/creategroup" color="primary" startIcon={<AddIcon />}>
          STWÓRZ
        </Button>
      )}
    </TopToolbar>
  );
};

const ImageData = (record: any) => {
  return (
    <Grid container direction="row">
      <img
        src={record.record.image_link}
        alt=""
        style={staticStyles.groupImage}
      />
    </Grid>
  );
};

const GroupName = (record: any) => {
  return (
    <Grid container direction="row">
      <Typography style={staticStyles.groupTitle}>
        {record.record.group_name}
      </Typography>
    </Grid>
  );
};

const GroupFilter = ({ permissions, ...props }) => {
  const role = useMemo(() => {
    return permissions && getRoleFromPrivilegesToken(permissions);
  }, [permissions]);

  return (
    <Filter {...props}>
      <TextInput label="Szukaj po: Nazwa" source="group_name" alwaysOn />
      {role !== "uzytkownik_2" && (
        <TextInput label="Szukaj po: Autor" source="group_author" alwaysOn />
      )}
      {role !== "uzytkownik_2" && (
        <BooleanInput
          source="is_verified"
          label="Szukaj po weryfikacji"
          alwaysOn
          onChange={(e) => props.setFilters({ is_verified: e ? true : null })}
        />
      )}
    </Filter>
  );
};

const ButtonGroups = ({ permissions, ...props }) => {
  const role = useMemo(
    () => getRoleFromPrivilegesToken(permissions),
    [permissions]
  );

  return (
    <Grid container direction="row">
      <GetThisGroup {...props} />
      {["administrator", "group_creator"].includes(role) && (
        <ChangePermissionInGroup {...props} />
      )}
      {[
        "administrator",
        "uzytkownik_1",
        "group_creator",
        "group_manager",
      ].includes(role) && <AddToGroup {...props} />}
      {(role === "administrator" ||
        (role === "uzytkownik_1" && props.record.can_change_privs)) && (
        <EditGroupButton {...props} />
      )}
      {(role === "administrator" ||
        (role === "uzytkownik_1" && props.record.can_change_privs)) && (
        <RemoveGroup {...props} />
      )}
    </Grid>
  );
};

const GetThisGroup = (record: any) => {
  const history = useHistory();

  const handleClickEdit = () => {
    history.push(`/getdashboards/${record.record.token}`);
  };
  return (
    <Tooltip title="Przejdź">
      <ArrowForwardIcon
        style={staticStyles.actionIcons}
        onClick={handleClickEdit}
      />
    </Tooltip>
  );
};

const ChangePermissionInGroup = (record: any) => {
  const handleClickEdit = () => {
    window.location.href = `/groupusers/${record.record.token}`;
  };
  return (
    <Tooltip title="Użytkownicy">
      <GroupOutlinedIcon
        style={staticStyles.actionIcons}
        onClick={handleClickEdit}
      />
    </Tooltip>
  );
};

const AddToGroup = (record: any) => {
  return (
    <AlertDialog
      changePrivs={record.record.can_change_privs}
      group={record.record.token}
    />
  );
};

const RemoveGroup = (record: any) => {
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [sended, setSended] = React.useState(false);
  const [notSended, setNotSended] = React.useState(false);
  const [openSecond, setOpenSecond] = React.useState(false);

  const handleClickRemove = () => {
    setUserName(record.record.input_name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function asyncSleepFunct() {
    await sleep(2000);
    setOpen(false);
    axios
      .delete(
        API_URL +
          `/removegroup?privileges=${localStorage.getItem(
            "privileges"
          )}&group_id=${record.record.token}`,
        {
          headers: {
            Authorization: `Bearer ${bcrypt.hashSync(
              `removegroup${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
              10
            )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
          },
        }
      )
      .then((response) => {
        if (response.data.code === 137) {
          setSended(true);
        } else {
          setNotSended(true);
        }
      })
      .catch(function (error) {
        setNotSended(true);
      });
  }
  const handleCloseAll = () => {
    setOpen(false);
    setOpenSecond(false);
    setSended(false);
    setNotSended(false);
    window.location.href = "/getgrouplist";
  };
  const DeleteButton = () => {
    setOpen(false);
    setOpenSecond(true);
    asyncSleepFunct();
  };
  const GetSpinner = () => {
    if (!sended && !notSended) {
      return (
        <>
          <Tooltip title="Usuń grupe">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>Przetwarzanie</DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "10em" }}
              >
                <Grid xs={12} item>
                  <ClipLoader size={"6em"} color={"#01C09E"} />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      );
    } else if (sended && !notSended) {
      return (
        <>
          <Tooltip title="Usuń grupe">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Grupa usunięta
              </DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "9em" }}
              >
                <Grid xs={12} item>
                  <Fade in={sended}>
                    <CheckCircleOutlineIcon
                      style={{ width: "6em", height: "6em", color: "#01C09E" }}
                    />
                  </Fade>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseAll}
                style={staticStyles.buttonDialogRight}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      return (
        <>
          <Tooltip title="Usuń grupe">
            <DeleteOutlinedIcon style={staticStyles.actionIcons} />
          </Tooltip>
          <Dialog
            open={openSecond}
            onClose={handleCloseAll}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Wystąpił nieoczekiwany błąd? Jesteś administratorem?
              </DialogContentText>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "20em", height: "9em" }}
              >
                <Grid xs={12} item>
                  <Fade in={notSended}>
                    <CancelOutlinedIcon
                      style={{ width: "6em", height: "6em", color: "#ff0000" }}
                    />
                  </Fade>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseAll}
                style={staticStyles.buttonDialogRight}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  };
  if (openSecond) {
    return <>{GetSpinner()}</>;
  } else {
    return (
      <>
        <Tooltip title="Usuń grupe">
          <DeleteOutlinedIcon
            style={staticStyles.actionIcons}
            onClick={handleClickRemove}
          />
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Usuwanie grupy"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz usunąć grupę {userName}? Usunięcie grupy jest
              permanentne i nie można będzie cofnąć operacji. Zostaną usunięte
              wszystkie oferty przypisane do tej grupy.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Nie
            </Button>
            <Button
              onClick={() => {
                DeleteButton();
              }}
              color="primary"
              autoFocus
            >
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};

const EditGroupButton = (record) => {
  const [token, setToken] = React.useState(record.record.token);
  const handleGroupEditButton = () => {
    setToken(record.record.token);
    initialState.action = true;
    return (window.location.href = `/editgroup/${token}`);
  };

  return (
    <Tooltip title="Edytuj grupę">
      <CreateOutlinedIcon
        style={staticStyles.actionIcons}
        onClick={handleGroupEditButton}
      />
    </Tooltip>
  );
};
