import $ from "jquery";
import React, { Component, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import axios, { AxiosResponse } from "axios";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ClipLoader from "react-spinners/ClipLoader";
import AlertDialog from "./dialog";
import { makeStyles } from "@material-ui/styles";
//import { withStyles } from '@material-ui/core/styles';
import { stringify } from "query-string";
import Select from "react-select";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GroupIcon from "@material-ui/icons/Group";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {
  VictoryPie,
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryStack,
  VictoryTooltip,
} from "victory";
import {
  DataGrid,
  ColDef,
  ValueFormatterParams,
  RowData,
} from "@material-ui/data-grid";
import { plPL } from "@material-ui/core/locale";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useMediaQuery } from "@material-ui/core";
import withMediaQuery from "./withMediaQuery";
import { format } from "date-fns";
import bcrypt from "bcryptjs";
import { Prompt } from "react-router";
import { API_URL } from "../config";
import {
  getRoleFromPrivilegesToken,
  getUserRole,
  getUserRoleName,
} from "../utils";

const drawerWidth = 240;

interface DataLabel {
  value: string;
  label: string;
}

const staticStyles = {
  actionIcons: {
    marginLeft: "0.5vw",
    marginRight: "0.5vw",
    cursor: "pointer",
  },
  groupImage: {
    marginTop: "3rem",
    width: "8rem",
    height: "8rem",
    borderRadius: "50%",
  },
  paper: {
    marginTop: "3vh",
    width: "11rem",
    height: "11rem",
  },
  paperLeftUpper: {
    marginTop: "3vh",
    width: "11rem",
    height: "11rem",
    marginLeft: "5.2rem",
  },
  paperTags: {
    width: "11rem",
    height: "11rem",
    margin: "0 auto",
  },
  paperLegend: {
    width: "11rem",
    height: "11rem",
    backgroundColor: "red",
  },
  iconUsers: {
    width: "3rem",
    height: "3rem",
    marginTop: "2rem",
    color: "#01C09E",
  },
  item: {
    padding: "10",
    align: "center",
    marginTop: "4rem",
  },
  itemGroups: {
    padding: "10",
    align: "center",
    marginTop: "4rem",
    marginBottom: "1rem",
  },
  itemTags: {
    padding: "10",
    textAlign: "center" as const,
    marginTop: "4rem",
    width: "100%",
    // marginLeft: "12rem"
  },
  secondItem: {
    padding: "10",
    textAlign: "center" as const,
    //marginTop: "1rem",
  },
  fullScreen: {
    width: "100vw",
    height: "100vh",
    spacing: "0",
    flex: "1",
    marginTop: "240",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  buttonPrimary: {
    width: "12rem",
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
  },
  buttonPrimaryAddGroup: {
    width: "12rem",
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    float: "inline-end" as "inline-end",
    marginTop: "1em",
    marginRight: "2em",
  },
  buttonPrimaryVerify: {
    width: "12rem",
    color: "#fff",
    backgroundColor: "#01C09E",
    fontWeight: "bold" as "bold",
    //marginLeft: "1em",
  },
  buttonSecondary: {
    width: "12rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
  },
  buttonSecondaryVerify: {
    width: "12rem",
    color: "#505050",
    backgroundColor: "#F4F2F5",
    fontWeight: "bold" as "bold",
    //marginLeft: "1em"
  },
  interestedQty: {
    color: "#9D9D9D",
    fontWeight: "normal" as "normal",
    fontSize: "xx-large",
    marginTop: "-6px",
  },
  interestedQtyInfo: {
    marginTop: "6px",
    color: "#505050",
  },
  BigNumberQty: {
    marginTop: "3rem",
    marginBottom: "0.5rem",
    fontWeight: "bold" as "bold",
  },
  titleChart: {
    fontWeight: "bold" as "bold",
    textAlign: "center" as const,
  },
  titleChartRight: {
    fontWeight: "bold" as "bold",
    marginTop: "4em",
    marginBottom: "1.8em",
  },
  titleChartLeftUpper: {
    fontWeight: "bold" as "bold",
    marginLeft: "4rem",
  },
  titleChartLeftLower: {
    fontWeight: "bold" as "bold",
    textAlign: "center" as const,
  },
  titleChartLeftLowerTypography: {
    fontWeight: "bold" as "bold",
    textAlign: "center" as const,
    marginBottom: "1rem",
  },
  titleChartDonutLegend: {
    marginLeft: "0.2vw",
    color: "#646464",
    fontWeight: "normal" as "normal",
  },
  menuOptions: {
    color: "#646464",
    inkBarStyle: {
      backgroundColor: "#01C09E",
    },
  },
  DonutChartTextStyle: {
    fill: "#01C09E",
    fontWeight: "bold" as "bold",
    fontSize: "3rem",
  },
  lineChartStyleX: {
    axis: { stroke: "#787878", strokeWidth: "0.5" },
    axisLabel: { fontSize: "10", fill: "#787878" },
    tick: { stroke: "#787878", strokeWidth: "0.5" },
    tickLabels: { fontSize: "10", fill: "#787878" },
  },
  lineChartStyleY: {
    grid: { stroke: "#787878", strokeWidth: "0.25" },
    axis: { stroke: "#787878", strokeWidth: "0.5" },
    axisLabel: { fontSize: "10", fill: "#787878" },
    tick: { stroke: "#787878", strokeWidth: "0.5" },
    tickLabels: { fontSize: "10", fill: "#787878" },
    data: { fill: "#009688", strokeWidth: "5" },
  },
  infoOffersNotFound: {
    width: "100%",
    textAlign: "center" as "center",
    marginTop: "2em",
    marginBottom: "2em",
    fontSize: "1.8em",
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
    fontStyle: "italic" as "italic",
    color: "#dedede",
  },
  infoGroupsNotFound: {
    width: "100%",
    textAlign: "center" as "center",
    marginTop: "4em",
    marginBottom: "2em",
    fontSize: "1.8em",
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
    fontStyle: "italic" as "italic",
    color: "#dedede",
  },
  paperOffersNotFound: {
    width: "100%",
    marginTop: "1em",
    height: "70vh",
  },
  paperGroupsNotFound: {
    width: "100%",
    marginTop: "1em",
    height: "87vh",
  },
  groupTitle: {
    padding: "10",
    align: "center",
    marginTop: "4rem",
    fontSize: "1em",
  },
  zaprosButton: {
    marginBottom: "20px",
  },
};

const styles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    marginLeft: 12,
  },
  toolbar: {
    paddingRight: 1,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
  },

  menuButton: {
    marginRight: "36",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "7",
    [theme.breakpoints.up("sm")]: {
      width: "9",
    },
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: "4",
    paddingBottom: "4",
  },
  paper: {
    padding: "2",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  spinner: {
    flex: 1,
    alignSelf: "center",
  },
  paginationDataGridFooter: {
    justifyContent: "flex-end",
  },
  grid: {
    marginBottom: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "15px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "15px",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type DashboardDataProps = {
  isLoading: boolean;
  isLoadingData: boolean;
  groupName: string;
  code: number;
  groups: undefined[];
  offerlist: any;
  perm: string;
  second_user_id: string;
  showNotify: boolean;
  showNotifyBeta: boolean;
  showNotifyError: boolean;
  setOpen: boolean;
  isSecondLoading: boolean;
  is_verified: false;
  styles: any;
  optionMenu: number;
  group_id: any;
  dashboardData: any;
  reload: boolean;
  defaultValUsers: any;
  firstUser: any;
  width: number;
};
interface PropsInter {
  match: any;
}

class DashboardData extends Component<DashboardDataProps & PropsInter> {
  state: DashboardDataProps;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingData: true,
      groupName: props.group_name,
      code: 0,
      groups: [],
      offerlist: [],
      perm: props.permission,
      second_user_id: "",
      showNotify: false,
      showNotifyBeta: false,
      showNotifyError: false,
      isSecondLoading: false,
      setOpen: false,
      is_verified: false,
      styles: styles,
      firstUser: localStorage.getItem("id"),
      optionMenu: 0,
      group_id: this.props.match.params.id,
      dashboardData: null,
      reload: false,
      defaultValUsers: null,
      width: window.innerWidth,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleChangeUserChange = this.handleChangeUserChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.resizeListener = this.resizeListener.bind(this);
  }

  GetDataFromApi(group_id: any, second_user_id: any) {
    var payload = {
      user_id: this.state.firstUser,
      group_id: group_id,
      second_user_id:
        group_id != null
          ? second_user_id != null
            ? second_user_id
            : this.state.firstUser
          : null,
    };
    var that = this;
    axios
      .get(API_URL + `/dashboard?${stringify(payload)}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `dashboard${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        var responses = response;
        if (responses.data.code === 121) {
          this.setState({
            dashboardData: responses.data.data,
            is_verified: responses.data.data.is_verified,
            code: responses.data.code,
            group_id: responses.data.data.group_token,
          });
        } else if (responses.data.code === 122) {
          this.setState({
            code: responses.data.code,
          });
        } else if (responses.data.code === 123) {
          this.setState({
            dashboardData: responses.data.data,
            is_verified: responses.data.data.is_verified,
            code: responses.data.code,
            group_id: responses.data.data.group_token,
          });
        }

        this.setState({ isLoading: false });
        this.setState({ isSecondLoading: false });
      })
      .catch(function (err) {
        that.setState({ isLoading: false, isSecondLoading: false, code: 122 });
      });
  }

  GetDataFromApiOffers(group_id2: any, second_user_id: any) {
    var payload = {
      user_id: this.state.firstUser,
      group_id: group_id2,
      second_user_id: second_user_id,
    };
    axios
      .get(API_URL + `/getofferlist?${stringify(payload)}`, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `getofferlist${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        var responses = response;
        if (responses.data.code === 127) {
          this.setState({ offerlist: responses.data.data });
        }
        this.setState({ isLoadingData: false });
      })
      .catch(function (error) {});
  }

  componentDidMount() {
    this.GetDataFromApi(this.state.group_id, null);
  }
  componentWillUnmount() {
    var elem = document.getElementsByClassName(
      "MuiTypography-root MuiTypography-body1"
    );
    var userRole = getUserRoleName() ?? "";
    elem[1].innerHTML = `${userRole}`;
  }

  handleDrawerOpen = () => {
    this.setState({ setOpen: true });
  };
  handleAddGroupNotExist = () => {
    window.location.href = "/creategroup";
  };

  handleDrawerClose = () => {
    this.setState({ setOpen: false });
  };
  handleChangeMenu = (event: any, newValue: number) => {
    this.setState({ optionMenu: newValue });
  };
  handleChangeGroup = (event: any, newValue: number) => {
    this.setState({
      isSecondLoading: true,
      group_id: event.value,
      defaultValUsers: null,
    });
    this.GetDataFromApi(event.value, null);
    this.GetDataFromApiOffers(event.value, null);
  };
  handleChangeUserChange = (event: any, newValue: number) => {
    this.setState({ isSecondLoading: true, defaultValUsers: event.value });
    this.GetDataFromApi(this.state.group_id, event.value);
    this.GetDataFromApiOffers(this.state.group_id, event.value);
  };
  getFixedSize(classes) {
    return clsx(classes.paper, classes.fixedHeight);
  }
  GetGroupList = () => {
    var arr: DataLabel[] = [];
    var defaultVal = {};
    var groups = this.state.dashboardData.groups;
    var groupName = this.state.dashboardData.group_name;
    if (groups.length <= 1) {
      return (
        <Typography style={staticStyles.groupTitle} variant="h5">
          {groupName}
        </Typography>
      );
    } else {
      groups.map((item) => {
        if (item.group_name == groupName) {
          defaultVal = { value: item.group_token, label: item.group_name };
          //this.state.group_id = item.group_token;
        }
        arr.push({ value: item.group_token, label: item.group_name });
      });
      return (
        <Select
          style={{ zIndex: 9999 }}
          options={arr}
          defaultValue={defaultVal}
          onChange={this.handleChangeGroup}
        />
      );
    }
  };
  handleVerifyGroup = (event) => {
    var that = this;
    var payload = {
      token: localStorage.getItem("privileges"),
      group_id: this.state.dashboardData.group_token,
    };

    axios
      .post(API_URL + `/groupverify`, payload, {
        headers: {
          Authorization: `Bearer ${bcrypt.hashSync(
            `groupverify${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
            10
          )}?sync${format(new Date(), "YYYY-MM-DDTHH:mm:ss")}`,
          "Content-Length": format(new Date(), "YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        var responses = response;
        if (responses.data.code === 115) {
          this.setState({ is_verified: true });
          this.setState({ showNotifyBeta: false });
          this.setState({ showNotify: true });
        } else if (responses.data.code === 120) {
          this.setState({ is_verified: false });
          this.setState({ showNotify: false });
          this.setState({ showNotifyBeta: true });
        }
      })
      .catch(function (error) {
        that.setState({ showNotifyError: true });
      });
  };

  GetUserList = () => {
    var arr: DataLabel[] = [];
    var defaultVal = {};
    var users = this.state.dashboardData.users;
    if ([0, 1].includes(this.state.dashboardData.users.length)) {
      return null;
    } else {
      users.map((item) => {
        if (item.user_token == this.state.defaultValUsers) {
          defaultVal = { value: item.user_token, label: item.name };
        }
        arr.push({ value: item.user_token, label: item.name });
      });
      if (Object.keys(defaultVal).length === 0) {
        defaultVal = { value: users[0].user_token, label: users[0].name };
      }
      return (
        <Select
          options={arr}
          value={defaultVal}
          onChange={this.handleChangeUserChange}
        />
      );
    }
  };
  ButtonsReturn() {
    const role = getUserRole();

    if (role === "administrator") {
      if (this.state.is_verified) {
        return (
          <Button
            onClick={this.handleVerifyGroup}
            style={staticStyles.buttonPrimaryVerify}
            variant="contained"
          >
            Odweryfikuj
          </Button>
        );
      } else {
        return (
          <Button
            onClick={this.handleVerifyGroup}
            style={staticStyles.buttonSecondaryVerify}
            variant="contained"
          >
            Zweryfikuj
          </Button>
        );
      }
    }
  }

  CheckPermission = () => {
    const role = this.state.perm && getRoleFromPrivilegesToken(this.state.perm);
    return role !== "administrator" ? this.ButtonsReturn() : null;
  };
  PreviewImage = () => {
    return (
      <img
        src={this.state.dashboardData.image_link}
        style={staticStyles.groupImage}
      />
    );
  };

  TitleBarInfo() {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid xs={7} md={6} lg={4} item>
            {this.PreviewImage()}
          </Grid>
          <Grid xs={7} md={8} lg={4} item>
            <Grid style={staticStyles.itemGroups}>{this.GetGroupList()}</Grid>
            <Grid style={staticStyles.secondItem}>{this.GetUserList()}</Grid>
          </Grid>
          <Grid xs={7} md={6} lg={4} item>
            <Grid style={staticStyles.item}>{this.CheckPermission()}</Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  RigthButtons() {
    return (
      <React.Fragment>
        <Grid container spacing={2} style={staticStyles.item}>
          <Grid xs={12} md={12} lg={6}>
            <div style={staticStyles.zaprosButton}>
              <Button
                style={staticStyles.buttonSecondary}
                variant="contained"
                id={this.state.group_id}
                onClick={() => {
                  window.location.href = `/createoffer/${this.state.group_id}`;
                }}
              >
                Dodaj ofertę
              </Button>
            </div>
          </Grid>
          <Grid xs={12} md={12} lg={6}>
            <div style={staticStyles.zaprosButton}>
              <AlertDialog
                changePrivs={this.state.dashboardData.can_change_privs}
                group={this.state.dashboardData.group_token}
              />
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  StatsOptionsMenu() {
    return (
      <div>
        <Tabs
          value={this.state.optionMenu}
          onChange={this.handleChangeMenu}
          TabIndicatorProps={{ style: { background: "#01C09E" } }}
        >
          <Tab label="Statystyki" />
          <Tab label="Oferty" />
        </Tabs>
      </div>
    );
  }
  GetLeftUpperSide() {
    return (
      <>
        <Grid xs={12} item style={staticStyles.item}>
          <Typography style={staticStyles.titleChartLeftUpper}>
            Ilość zainteresowanych grupą
          </Typography>
          <Paper style={staticStyles.paperLeftUpper} variant="outlined">
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <GroupIcon style={staticStyles.iconUsers} />
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Typography style={staticStyles.interestedQty}>
                {this.state.dashboardData.interested_qty}
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Typography style={staticStyles.interestedQtyInfo}>
                Zainteresowani
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </>
    );
  }
  GetRightUpperSide() {
    return (
      <>
        <Grid container style={staticStyles.itemTags} direction="row">
          <Typography style={staticStyles.titleChart}>
            Liczba ofert z danym typem tagu
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={6}>
          <Grid item xs={12} md={6}>
            <Paper style={staticStyles.paperTags} variant="outlined">
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography
                  style={{
                    marginTop: "3rem",
                    marginBottom: "0.5rem",
                    fontWeight: "bold" as "bold",
                    color:
                      this.state.dashboardData.colors.offer_by_tag.first_tag,
                  }}
                  variant="h3"
                >
                  {this.state.dashboardData.tags.all}
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography style={staticStyles.interestedQtyInfo}>
                  Złożone
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={staticStyles.paperTags} variant="outlined">
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography
                  style={{
                    marginTop: "3rem",
                    marginBottom: "0.5rem",
                    fontWeight: "bold" as "bold",
                    color:
                      this.state.dashboardData.colors.offer_by_tag.second_tag,
                  }}
                  variant="h3"
                >
                  {this.state.dashboardData.tags.verify}
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography style={staticStyles.interestedQtyInfo}>
                  Rozpatrywane
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={staticStyles.paperTags} variant="outlined">
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography
                  style={{
                    marginTop: "3rem",
                    marginBottom: "0.5rem",
                    fontWeight: "bold" as "bold",
                    color:
                      this.state.dashboardData.colors.offer_by_tag.third_tag,
                  }}
                  variant="h3"
                >
                  {this.state.dashboardData.tags.accepted}
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Typography style={staticStyles.interestedQtyInfo}>
                  Zaakceptowane
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
  GetLeftLowerSide() {
    return (
      <>
        <Grid xs={12} item style={staticStyles.item}>
          <Typography style={staticStyles.titleChartLeftLower}>
            Statusy ofert
          </Typography>
          <Grid xs={12} item>
            {" "}
            {/*style={staticStyles.item}>*/}
            <svg width={350} height={350}>
              <circle cx={175} cy={175} r={80} fill="#fafafa" />
              {/* <Typography style={{marginTop: "175", marginLeft: "175"}} name="interested_title" component="h7"  label="">0%</Typography> */}
              <text
                style={staticStyles.DonutChartTextStyle}
                textAnchor="middle"
                x="175"
                y="190"
              >
                {this.state.dashboardData.pie_chart[3].label}
              </text>
              <VictoryPie
                labelComponent={<VictoryTooltip />}
                standalone={false}
                width={350}
                height={350}
                innerRadius={85}
                data={this.state.dashboardData.pie_chart}
                colorScale={[
                  this.state.dashboardData.colors.donut_chart.fourth_val,
                  this.state.dashboardData.colors.donut_chart.third_val,
                  this.state.dashboardData.colors.donut_chart.second_val,
                  this.state.dashboardData.colors.donut_chart.first_val,
                ]}
                events={[
                  {
                    target: "data",
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: "data",
                            mutation: () => ({ style: { width: 20 } }),
                          },
                          {
                            target: "labels",
                            mutation: () => ({ active: true }),
                          },
                        ];
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: "data",
                            mutation: () => {},
                          },
                          {
                            target: "labels",
                            mutation: () => ({ active: false }),
                          },
                        ];
                      },
                    },
                  },
                ]}
              />
            </svg>
          </Grid>
          <Grid style={{ marginLeft: "6rem" }} container>
            <Grid xs={12} item>
              <Grid container>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.donut_chart.first_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Zaakceptowane
                </Typography>
              </Grid>
              <Typography
                style={staticStyles.titleChartLeftLowerTypography}
              ></Typography>
            </Grid>
            <Grid xs={12} item>
              <Grid container>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.donut_chart.second_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Rozpatrywane
                </Typography>
              </Grid>
              <Typography
                style={staticStyles.titleChartLeftLowerTypography}
              ></Typography>
            </Grid>
            <Grid xs={12} item>
              <Grid container>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.donut_chart.third_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Odrzucone
                </Typography>
              </Grid>
              <Typography
                style={staticStyles.titleChartLeftLowerTypography}
              ></Typography>
            </Grid>
            <Grid xs={12} item>
              <Grid container>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.donut_chart.fourth_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Anulowane
                </Typography>
              </Grid>
              <Typography
                style={staticStyles.titleChartLeftLowerTypography}
              ></Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  */}
      </>
    );
  }
  ReturnBarChartData() {
    var data = this.state.dashboardData.line_chart;
    const items: any[] = [];
    const keys: any[] = [];
    for (var key in data) {
      items.push(
        <VictoryBar
          data={data[key]}
          x="x"
          y="y"
          labelComponent={<VictoryTooltip style={{ fontSize: "10px" }} />}
          events={[
            {
              target: "data",
              eventHandlers: {
                onMouseOver: (props) => {
                  return [
                    {
                      target: "data",
                      mutation: () => ({ style: { width: 20 } }),
                    },
                    { target: "labels", mutation: () => ({ active: true }) },
                  ];
                },
                onMouseOut: () => {
                  return [
                    {
                      target: "data",
                      mutation: () => {},
                    },
                    { target: "labels", mutation: () => ({ active: false }) },
                  ];
                },
              },
            },
          ]}
        />
      );
      if (keys.length == 0) {
        for (var val in data[key]) {
          keys.push(data[key][val]["x"]);
        }
      }
    }
    return (
      <>
        <Grid item xs={12}>
          <VictoryChart domainPadding={20} width={350}>
            <VictoryAxis dependentAxis style={staticStyles.lineChartStyleY} />
            <VictoryAxis
              tickValues={keys}
              tickFormat={keys}
              style={staticStyles.lineChartStyleX}
            />
            <VictoryStack
              colorScale={[
                this.state.dashboardData.colors.line_chart.first_val,
                this.state.dashboardData.colors.line_chart.second_val,
                this.state.dashboardData.colors.line_chart.third_val,
                this.state.dashboardData.colors.line_chart.fourth_val,
              ]}
            >
              {items}
            </VictoryStack>
          </VictoryChart>

          <Grid style={{ marginLeft: "6rem", marginTop: "-3rem" }} container>
            <Grid container>
              <Grid item xs={12} md={3}>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.line_chart.first_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Zaakceptowane
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.line_chart.second_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Rozpatrywane
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.line_chart.third_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Odrzucone
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor:
                      this.state.dashboardData.colors.line_chart.fourth_val,
                  }}
                  elevation={0}
                  square
                />
                <Typography style={staticStyles.titleChartDonutLegend}>
                  Anulowane
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  GetRightLowerSide() {
    return (
      <>
        <Grid container style={staticStyles.itemTags} direction="row">
          <Typography style={staticStyles.titleChart}>
            Liczba ofert z danym typem tagu
          </Typography>
        </Grid>
        <Grid container direction="row" style={{ marginTop: "-7rem" }}>
          {this.ReturnBarChartData()}
        </Grid>
      </>
    );
  }
  StatsPanel() {
    if (this.state.code) {
      return (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Grid container direction="column">
                <Grid item>
                  <Grid justify="center" alignItems="center" container>
                    {this.GetLeftUpperSide()}
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    {this.GetLeftLowerSide()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container direction="column">
                <Grid item>
                  <Grid justify="center" alignItems="center" container>
                    {this.GetRightUpperSide()}
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    {this.GetRightLowerSide()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
  }
  resizeListener() {
    this.setState({ width: window.innerWidth });
  }

  ReturnOfferListResize() {}

  OfertsPanel() {
    const classes = this.state.styles;
    if (this.state.isLoadingData) {
      this.GetDataFromApiOffers(
        this.state.group_id,
        this.state.defaultValUsers
      );
    }
    while (this.state.isLoadingData || this.state.offerlist === null) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }
    if (this.state.offerlist) {
      window.addEventListener("resize", this.resizeListener);
      if (this.state.width < 1000) {
        const columns: ColDef[] = [
          {
            field: "offer_name",
            headerName: "Nazwa oferty",
            width: 350,
          },
          { field: "group_name", headerName: "Grupa", width: 200 },
          {
            field: "",
            headerName: "",
            width: 50,
            renderCell: (params: ValueFormatterParams) => {
              return (
                <Tooltip title="Przejdź">
                  <ArrowForwardIcon
                    style={staticStyles.actionIcons}
                    onClick={() => {
                      window.location.href = `/showoffer/${params.data.token}`;
                    }}
                  />
                </Tooltip>
              );
            },
          },
        ];
        const rows = this.state.offerlist.data;
        return (
          <div
            style={{
              minHeight: 400,
              width: "100%",
              marginTop: "50px",
              zIndex: 0,
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick={true}
              hideFooterRowCount={true}
              hideFooterSelectedRowCount={true}
            />{" "}
          </div>
        );
      } else {
        const columns: ColDef[] = [
          {
            field: "offer_name",
            headerName: "Nazwa oferty",
            width: 350,
          },
          { field: "status", headerName: "Status", width: 130 },
          { field: "group_name", headerName: "Grupa", width: 200 },
          { field: "modified_at", headerName: "Data aktualizacji", width: 200 },
          { field: "created_at", headerName: "Data utworzenia", width: 200 },
          {
            field: "",
            headerName: "",
            width: 50,
            renderCell: (params: ValueFormatterParams) => {
              return (
                <Tooltip title="Przejdź">
                  <ArrowForwardIcon
                    style={staticStyles.actionIcons}
                    onClick={() => {
                      window.location.href = `/showoffer/${params.data.token}`;
                    }}
                  />
                </Tooltip>
              );
            },
          },
        ];
        const rows = this.state.offerlist.data;
        return (
          <div
            style={{
              minHeight: 400,
              width: "100%",
              marginTop: "50px",
              zIndex: 0,
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              disableSelectionOnClick={true}
              hideFooterRowCount={true}
              hideFooterSelectedRowCount={true}
            />{" "}
          </div>
        );
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  StatsGlobalPanel() {
    const classes = this.state.styles;
    if (this.state.optionMenu === 0) {
      while (this.state.isSecondLoading) {
        return (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "70vh" }}
          >
            <Grid xs={12} item>
              <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
            </Grid>
          </Grid>
        );
      }
      return (
        <>
          <Grid container>{this.StatsOptionsMenu()}</Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid xs={12} md={6} item>
                  <Grid xs={12} md={12} item style={staticStyles.item}>
                    <Typography style={staticStyles.titleChartLeftUpper}>
                      Ilość zainteresowanych grupą
                    </Typography>
                    <Paper
                      style={staticStyles.paperLeftUpper}
                      variant="outlined"
                    >
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="row"
                      >
                        <GroupIcon style={staticStyles.iconUsers} />
                      </Grid>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Typography style={staticStyles.interestedQty}>
                          {this.state.dashboardData.interested_qty}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="row"
                      >
                        <Typography style={staticStyles.interestedQtyInfo}>
                          Zainteresowani
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid xs={12} md={6} item style={staticStyles.item}>
                    <Typography style={staticStyles.titleChartLeftLower}>
                      Statusy ofert
                    </Typography>
                    <Grid xs={12} md={6} item>
                      {" "}
                      {/*style={staticStyles.item}>*/}
                      <svg width={350} height={350}>
                        <circle cx={175} cy={175} r={80} fill="#fafafa" />
                        {/* <Typography style={{marginTop: "175", marginLeft: "175"}} name="interested_title" component="h7"  label="">0%</Typography> */}
                        <text
                          style={staticStyles.DonutChartTextStyle}
                          textAnchor="middle"
                          x="175"
                          y="190"
                        >
                          {this.state.dashboardData.pie_chart[3].label}
                        </text>
                        <VictoryPie
                          labelComponent={<VictoryTooltip />}
                          standalone={false}
                          width={350}
                          height={350}
                          innerRadius={85}
                          data={this.state.dashboardData.pie_chart}
                          colorScale={[
                            this.state.dashboardData.colors.donut_chart
                              .fourth_val,
                            this.state.dashboardData.colors.donut_chart
                              .third_val,
                            this.state.dashboardData.colors.donut_chart
                              .second_val,
                            this.state.dashboardData.colors.donut_chart
                              .first_val,
                          ]}
                          events={[
                            {
                              target: "data",
                              eventHandlers: {
                                onMouseOver: () => {
                                  return [
                                    {
                                      target: "data",
                                      mutation: () => ({
                                        style: { width: 20 },
                                      }),
                                    },
                                    {
                                      target: "labels",
                                      mutation: () => ({ active: true }),
                                    },
                                  ];
                                },
                                onMouseOut: () => {
                                  return [
                                    {
                                      target: "data",
                                      mutation: () => {},
                                    },
                                    {
                                      target: "labels",
                                      mutation: () => ({ active: false }),
                                    },
                                  ];
                                },
                              },
                            },
                          ]}
                        />
                      </svg>
                    </Grid>
                    <Grid style={{ marginLeft: "6rem" }} container>
                      <Grid xs={12} item>
                        <Grid container>
                          <Paper
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              backgroundColor:
                                this.state.dashboardData.colors.donut_chart
                                  .first_val,
                            }}
                            elevation={0}
                            square
                          />
                          <Typography
                            style={staticStyles.titleChartDonutLegend}
                          >
                            Zaakceptowane
                          </Typography>
                        </Grid>
                        <Typography
                          style={staticStyles.titleChartLeftLowerTypography}
                        ></Typography>
                      </Grid>
                      <Grid xs={12} item>
                        <Grid container>
                          <Paper
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              backgroundColor:
                                this.state.dashboardData.colors.donut_chart
                                  .second_val,
                            }}
                            elevation={0}
                            square
                          />
                          <Typography
                            style={staticStyles.titleChartDonutLegend}
                          >
                            Rozpatrywane
                          </Typography>
                        </Grid>
                        <Typography
                          style={staticStyles.titleChartLeftLowerTypography}
                        ></Typography>
                      </Grid>
                      <Grid xs={12} item>
                        <Grid container>
                          <Paper
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              backgroundColor:
                                this.state.dashboardData.colors.donut_chart
                                  .third_val,
                            }}
                            elevation={0}
                            square
                          />
                          <Typography
                            style={staticStyles.titleChartDonutLegend}
                          >
                            Odrzucone
                          </Typography>
                        </Grid>
                        <Typography
                          style={staticStyles.titleChartLeftLowerTypography}
                        ></Typography>
                      </Grid>
                      <Grid xs={12} item>
                        <Grid container>
                          <Paper
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              backgroundColor:
                                this.state.dashboardData.colors.donut_chart
                                  .fourth_val,
                            }}
                            elevation={0}
                            square
                          />
                          <Typography
                            style={staticStyles.titleChartDonutLegend}
                          >
                            Anulowane
                          </Typography>
                        </Grid>
                        <Typography
                          style={staticStyles.titleChartLeftLowerTypography}
                        ></Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justify="center" alignItems="center">
                <Grid justify="center" alignItems="center" container>
                  <Typography style={staticStyles.titleChartRight}>
                    Liczba ofert z danym typem tagu
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={4}>
                      <Paper style={staticStyles.paperTags} variant="outlined">
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography
                            style={{
                              marginTop: "3rem",
                              marginBottom: "0.5rem",
                              fontWeight: "bold" as "bold",
                              color:
                                this.state.dashboardData.colors.offer_by_tag
                                  .first_tag,
                            }}
                            variant="h3"
                          >
                            {this.state.dashboardData.tags.all}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography style={staticStyles.interestedQtyInfo}>
                            Złożone
                          </Typography>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Paper style={staticStyles.paperTags} variant="outlined">
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography
                            style={{
                              marginTop: "3rem",
                              marginBottom: "0.5rem",
                              fontWeight: "bold" as "bold",
                              color:
                                this.state.dashboardData.colors.offer_by_tag
                                  .second_tag,
                            }}
                            variant="h3"
                          >
                            {this.state.dashboardData.tags.verify}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography style={staticStyles.interestedQtyInfo}>
                            Rozpatrywane
                          </Typography>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Paper style={staticStyles.paperTags} variant="outlined">
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography
                            style={{
                              marginTop: "3rem",
                              marginBottom: "0.5rem",
                              fontWeight: "bold" as "bold",
                              color:
                                this.state.dashboardData.colors.offer_by_tag
                                  .third_tag,
                            }}
                            variant="h3"
                          >
                            {this.state.dashboardData.tags.accepted}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography style={staticStyles.interestedQtyInfo}>
                            Zaakceptowane
                          </Typography>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container xs={12} direction="column">
                  <Grid container style={staticStyles.itemTags} direction="row">
                    <Typography style={staticStyles.titleChartRight}>
                      Liczba ofert z danym typem tagu
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    style={{ marginTop: "-7rem" }}
                  >
                    {this.ReturnBarChartData()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid container xs={12}>
          <Grid container xs={12}>
            {this.StatsOptionsMenu()}
          </Grid>
          <Grid container xs={12}>
            <React.Fragment>{this.OfertsPanel()}</React.Fragment>
          </Grid>
        </Grid>
      );
    }
  }
  handleClose(event: any, reason: any) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showNotify: false });
    this.setState({ showNotifyBeta: false });
    this.setState({ showNotifyError: false });
  }

  render() {
    const { isLoading, styles } = this.state;
    const classes = styles;
    const NOTIFY = (
      <div>
        <Snackbar
          open={this.state.showNotify}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">Grupa oznazczona jako zweryfikowana</Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYBETA = (
      <div>
        <Snackbar
          open={this.state.showNotifyBeta}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="success">
            Grupa oznazczona jako nie zweryfikowana
          </Alert>
        </Snackbar>
      </div>
    );
    const NOTIFYERROR = (
      <div>
        <Snackbar
          open={this.state.showNotifyError}
          autoHideDuration={3000}
          //style={useState.snackbar}
          onClose={this.handleClose}
        >
          <Alert severity="error">
            Wystąpił nieoczekiwany błąd podczas zapisywania formularza
          </Alert>
        </Snackbar>
      </div>
    );
    if (isLoading) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }
    var elem = document.getElementsByClassName(
      "MuiTypography-root MuiTypography-body1"
    );
    var userRole = getUserRoleName();
    if (this.state.code === 121) {
      if (this.state.dashboardData.group_privileges) {
        elem[1].innerHTML = `${userRole} - ${this.state.dashboardData.group_privileges}`;
      } else {
        elem[1].innerHTML = `${userRole}`;
      }
      return (
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
              <Grid container spacing={3}>
                <Grid container>
                  <Grid item xs={12} md={6} lg={8}>
                    {this.TitleBarInfo()}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    {this.RigthButtons()}
                  </Grid>
                </Grid>
                <Grid container>{this.StatsGlobalPanel()}</Grid>
              </Grid>
            </Container>
          </main>
          {NOTIFY}
          {NOTIFYBETA}
          {NOTIFYERROR}
        </div>
      );
    } else if (this.state.code === 122) {
      elem[1].innerHTML = `${userRole}`;
      return (
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
              <Grid container spacing={3}>
                <Paper
                  elevation={0}
                  variant="outlined"
                  style={staticStyles.paperGroupsNotFound}
                >
                  <Button
                    style={staticStyles.buttonPrimaryAddGroup}
                    variant="contained"
                    onClick={this.handleAddGroupNotExist}
                  >
                    Stwórz grupę
                  </Button>
                  <Typography style={staticStyles.infoGroupsNotFound}>
                    Nie posiadasz oraz nie jesteś przypisany do żadnej grupy
                  </Typography>
                </Paper>
              </Grid>
            </Container>
          </main>
          {NOTIFY}
          {NOTIFYBETA}
          {NOTIFYERROR}
        </div>
      );
    } else if (this.state.code === 123) {
      if (this.state.dashboardData.group_privileges) {
        elem[1].innerHTML = `${userRole} - ${this.state.dashboardData.group_privileges}`;
      } else {
        elem[1].innerHTML = `${userRole}`;
      }
      return (
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid container xs={6} md={4} lg={8}>
                  {this.TitleBarInfo()}
                </Grid>
                {/* Recent Deposits */}
                <Grid container xs={4} md={4} lg={4}>
                  {this.RigthButtons()}
                </Grid>
                {/* Recent Orders */}
                <Paper
                  elevation={0}
                  variant="outlined"
                  style={staticStyles.paperOffersNotFound}
                >
                  <Typography style={staticStyles.infoOffersNotFound}>
                    Nie znaleziono ofert użytkownika
                  </Typography>
                </Paper>
              </Grid>
            </Container>
          </main>
          {NOTIFY}
          {NOTIFYBETA}
          {NOTIFYERROR}
        </div>
      );
    } else {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "70vh" }}
        >
          <Grid xs={12} item>
            <ClipLoader size={45} css={classes.spinner} color={"#01C09E"} />
          </Grid>
        </Grid>
      );
    }
    console.clear();
  }
}

export default DashboardData;
